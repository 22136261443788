:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 2000;
}

.alert-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 70px;
  width: 350px;
  border: 2px solid lightgrey;
}

.alert-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
  font-weight: 200;
  line-height: 1;
  height: 30px;
  color: white;
  width: 150px;
  border-radius: 4px;
  border-width: 1px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(239, 68, 68);
  z-index: 4;
}
.alert-button:hover {
  background-color: rgb(58, 223, 58);
  border: 2px solid rgb(39, 177, 39);
  color: white;
}

.alertYes-button {
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  color: #333;
  width: 200px;
  height: 30px;
  margin-bottom: 5px;
  padding-top: 2px;
  border-radius: 6px;
  border: 1px solid;
  border-color: grey;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(255, 128, 0);
  z-index: 4;
}

.alertNo-button {
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  color: #333;
  width: 200px;
  height: 30px;
  margin-bottom: 5px;
  padding-top: 2px;
  border-radius: 6px;
  border: 1px solid;
  border-color: grey;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(255, 128, 0);
  z-index: 4;
}

.alert-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  width: 350px;
  margin-bottom: 10px;
}

.alert-messageErrCode {
  display: flex;
  justify-content: center;
  width: 350px;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
}

.alert-content-icon {
  width: 20px;
  height: 20px;
  color: rgb(229, 64, 68);
}/*# sourceMappingURL=appAlert.css.map */