@import '../style/MainStyles.scss';

// Color Variables
$color-primary: #3b82f6;
$color-secondary: #22c55e;
$color-accent: #eab308;
$color-gray-light: #f3f4f6;
$color-gray-dark: #4b5563;
$color-red: #ef4444;

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

.cooking-timer {
  width:500px;
  max-width: 800px;
  height:auto;
  margin: 0 auto;
  padding: 5px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  @include gadgets-to(mobile){
    width:300px;
    max-width: 100%;
  }
  @include gadgets-to(tablet){
    width:500px;
    max-width: 100%;
  }

  &__header {
    @include flex-center;
    margin-bottom: 20px;
  }

  &__icon {
    color: $color-primary;
    margin-right: 10px;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    color: $color-gray-dark;
  }

  &__minutes-control {
    @include flex-center;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__minute-btn {
    @include flex-center;
    width: 50px;
    height: 50px;
    background-color: $color-gray-light;
    border-radius: 50%;
    cursor: pointer;
    color:black;
    font-size: 1.5rem;
    @include transition;

    &:hover {
      background-color: darken($color-gray-light, 5%);
    }
  }

  &__minute-display {
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-gray-dark;
  }

  &__display {
    font-size: 3.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px;
    color: $color-primary;

    &--complete {
      color: $color-red;
    }
  }

  &__bell {
    display: inline-block;
    margin-left: 15px;
    animation: bounce 1s infinite;
  }

  &__controls {
    @include flex-center;
    gap: 15px;
    margin-bottom: 20px;
  }

  &__btn {
    @include flex-center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    @include transition;

    &--start {
      background-color: $color-secondary;
      color: white;

      &:hover {
        background-color: darken($color-secondary, 10%);
      }

      &--disabled {
        background-color: $color-gray-light;
        cursor: not-allowed;
      }
    }

    &--pause {
      background-color: $color-accent;
      color: white;

      &:hover {
        background-color: darken($color-accent, 10%);
      }
    }

    &--reset {
      background-color: $color-gray-light;
      color: $color-gray-dark;

      &:hover {
        background-color: darken($color-gray-light, 5%);
      }
    }
  }

  &__presets {
    @include flex-center;
    gap: 10px;
  }

  &__preset-btn {
    background-color: lighten($color-primary, 40%);
    color: $color-primary;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 0.875rem;
    cursor: pointer;
    @include transition;

    &:hover {
      background-color: lighten($color-primary, 35%);
    }
  }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.cookintTimerCloseWrapper{
  display: flex;
  justify-content: flex-end;
  margin-right:6px;
  margin-top: 6px;
}