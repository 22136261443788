:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.terms-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  max-width: 800px;
  width: 85%; /* Ensures responsiveness */
  padding: 24px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: rgba(255, 255, 255, 0.965);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional for better visuals */
  border-radius: 8px; /* Optional for rounded corners */
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .terms-container {
    width: 50%;
    background: transparent;
  }
}
.terms-container .terms-title {
  font-size: 26px;
  font-weight: bold;
  font-family: var(--font-family);
  text-align: center;
  margin-bottom: 10px;
}
.terms-container .terms-version {
  font-size: 0.9rem;
  text-align: center;
  color: #555;
  margin-bottom: 20px;
}
.terms-container .terms-section {
  margin-bottom: 20px;
}
.terms-container .terms-section .terms-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #222;
}
.terms-container .terms-section .terms-text {
  font-size: 14px;
  font-family: var(--font-family);
  color: #444;
  white-space: pre-wrap;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .terms-container .terms-section .terms-text {
    font-size: 16px;
  }
}

.terms-close-icon {
  padding: 1px;
  cursor: pointer;
  color: #666;
  font-size: 1.5rem;
}

.terms-Wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: transparent;
}/*# sourceMappingURL=Terms.css.map */