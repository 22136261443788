@import '../style/MainStyles.scss';

    .terms-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // position: absolute;
        // top: 9.6%;
        // left:0%; 
        z-index: 1000;
      
        max-width: 800px;
        width:85%; /* Ensures responsiveness */
        padding: 24px;
        margin: 0 auto;
      
        font-family: Arial, sans-serif;
        line-height: 1.6;
        color: #333;
        background-color:rgba(255,255,255,0.965);
     //   border: 1px solid map-get($generalStyles,datalistBorderColor);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional for better visuals */
        border-radius: 8px; /* Optional for rounded corners */
      @include gadgets-to(tablet){
          width:50%;
          background:transparent;
      }
      
  
    .terms-title {
      font-size:26px;
      font-weight: bold;
      font-family:map-get($generalStyles,fontFamily);
      text-align: center;
      margin-bottom: 10px;
    }
  
    .terms-version {
      font-size: 0.9rem;
      text-align: center;
      color: #555;
      margin-bottom: 20px;
    }
  
    .terms-section {
      margin-bottom: 20px;
  
      .terms-header {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 10px;
        color: #222;
      }
  
      .terms-text {
        font-size:14px;
        font-family:map-get($generalStyles,fontFamily);
        color: #444;
        white-space: pre-wrap;

        @include gadgets-to(laptop){
         font-size: 16px;
        }
      }

    }
  }



  .terms-close-icon {
    // position: absolute;
    // top: 0;
    // right: 5px;
    padding: 1px;
    cursor: pointer;
    color: #666;
    font-size: 1.5rem;
  }
  .terms-Wrapper{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:flex-end;
    background-color: transparent;
  }