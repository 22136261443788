@import '../style/variabels.scss';
@import '../style/MainStyles.scss';

.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index:2000;
  }
  
  .alert-content {
  display:flex;
  flex-direction: column;
  align-items: center;
    background-color: white;
    padding:10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height:70px;
    width:350px;
    border:2px solid lightgrey;
  
    

  }

  .alert-button{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

   // display:inline-block;
    cursor:pointer;
    text-decoration: none;
    font-size:14px;
    font-family:map-get($generalStyles,fontFamilyAnders);
    font-weight:200;
    line-height: 1;
    height:30px;
    color:white;
    width:150px;
  border-radius:4px;
  border-width: 1px;
 // border-color: grey;
  font-family: $primary-font-family;
  background-color:rgb(239, 68, 68);
  z-index:4;
  // hover
    &:hover{
        background-color:rgb(58, 223, 58);
        border:2px solid rgb(39, 177, 39);
        color:white;
    }
  }
  .alertYes-button{
  //  display:inline-block;
    cursor:pointer;
    text-decoration: none;
    font-size:18px;
   // line-height: 2;
    color:#333;
    width:200px;
    height:30px;
    margin-bottom: 5px;
    padding-top: 2px;
  border-radius: 6px;
  border:1px solid;
  border-color:grey;
  font-family: $primary-font-family;
  background-color:$chocolateBorder;
  z-index:4;
  }
  .alertNo-button{
      //  display:inline-block;
      cursor:pointer;
      text-decoration: none;
      font-size:18px;
     // line-height: 2;
      color:#333;
      width:200px;
      height:30px;
      margin-bottom: 5px;
      padding-top:2px;
    border-radius: 6px;
    border:1px solid;
    border-color:grey;
    font-family: $primary-font-family;
    background-color:$chocolateBorder;
    z-index:4;

  }
  .alert-message{
     display:flex;
     justify-content: center;
     align-items: center;
     font-family:#{map-get($generalStyles,fontFamilyAnders)};
     font-size:14px;
     font-weight: 300;
     width:350px;
    margin-bottom: 10px;

  }
  .alert-messageErrCode{
    display:flex;
    justify-content: center;
    width:350px;
   
 
   margin-bottom: 10px;
  
   font-family: $primary-font-family;
   font-size:10px;
 }
 .alert-content-icon{
  width:20px;
  height:20px;
  color:map-get($generalStyles,pinkRed);
 }