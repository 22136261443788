.about-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 2rem;
  line-height: 1.6;

  &__header {
    text-align: center;
    margin-bottom: 2rem;
    background: linear-gradient(to right, #4a90e2, #50e3c2);
    background-size: 200% 200%;
    animation: moveGradient 6s infinite ease-in-out;
    padding: 2rem;
    border-radius: 8px;

    &__title {
      font-size: 3.5rem;
      font-weight: bold;
      color: #fff;
    }

    &__subtitle {
      font-size:2.2rem;
      color: #fdfdfd;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }

  &__section {
    flex: 1 1 60%;
    display: grid;
    gap: 1.5rem;

    &__card {
      background: #fff;
      padding: 1.5rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      &-text {
        font-size: 1rem;
        color: #666;
      }
    }
  }

  &__contact {
    flex: 1 1 30%;
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &-title {
      font-size: 1.8rem;
      margin-bottom: 1rem;
      color: #444;
    }

    &-list {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 0.5rem;
        font-size: 1rem;

        a {
          color: #0073e6;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @keyframes moveGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

  