@import '../style/MainStyles.scss';

.bsTotalArea {
display:flex;
flex-direction:column;
//justify-content: center;
align-items: center;
width:100%;
height:99vh;
//padding:2px;
//border:1px solid red;
border-radius: 8px;

}
.bsTopArea {
display:flex;
flex-direction:column;
justify-content: center;
align-items: center;
width:100%;
height:8vh;
box-shadow: inset 0 0 30px map-get($generalStyles, colorGold);
border-radius: 8px;
//padding-left:1vw;
@include gadgets-to(tablet) {
  height: 8vh;
}
}
.bsNameArea {
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
padding:10px;
width:100%;
font-size:30px;
font-family:map-get($generalStyles,fontFamilyLobster);

}
.bsMiddleArea {
display:flex;
flex-direction:column;
//justify-content: center;
align-items: center;
width:100%;
height:10vh;
// height:84vh;
// border:1px solid blue;
// border-radius: 8px;
@include gadgets-to(mobile) {
  @media (max-width:400px) {
    height:5vh;
  }
  @media (min-width:400px) {
    height:5vh;
  }
}
@include gadgets-to(tablet) {
  height:9vh;
}
@include gadgets-to(laptop) {
  height:6vh;
}
@include gadgets-to(desktop) {
  height:9vh;
}
@include gadgets-to(largeDesktop) {
  height:9vh;
}
}

// default.scss
.bsGridArea {
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Start with 2 columns for mobile
  grid-auto-flow: row; // Forces horizontal flow
  gap: 0.1rem;
  width: 99%;
  //padding: 0.5rem;
  justify-content:center; // Aligns items from start
  //height: calc(96vh - 1rem); // Adjusted height to account for bottom padding
  height:auto;
  max-height: calc(96vh - 1rem); // Same here for consistency
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 1rem; // Ensures the last row is visible at the bottom
  margin-top:0.5vh;
  z-index:1;
  &::-webkit-scrollbar {
    
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: map-get($generalStyles, colorGold);
  }

  @include gadgets-to(mobile) {
    width:100%;
    gap: 0.1rem;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (min-width: 400px) {
     margin-top:0.5vh;
    }
  }
  @include gadgets-to(tablet) {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.2rem;
    margin-top:0.1vh;
    width:100%;
    &::-webkit-scrollbar {
    display:none;
    }
  }
  @include gadgets-to(laptop) {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.2rem;
    margin-top:0.1vh;
    width:100%;
    &::-webkit-scrollbar {
    display:none;
    }
  }
  @include gadgets-to(desktop) {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.2rem;
    margin-top:0.1vh;
    width:100%;
    &::-webkit-scrollbar {
    display:none;
    }
  }
  @include gadgets-to(largeDesktop) {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.2rem;
    margin-top:0.1vh;
    width:100%;
    &::-webkit-scrollbar {
    display:none;
    }
  }
}


.bsGridItem {
  aspect-ratio: 1;
  width:100%;
 // max-width: 19vw;
 // grid-auto-rows: 1fr; // Ensures consistent row heights
 @media(min-width:1740px)and (max-width:1800px) {
  height:34vh;
}

 @include gadgets-to(mobile) {
  max-width:50vw;
}
@include gadgets-to(tablet){
  max-width:20vw;
}
@include gadgets-to(laptop){
  max-width:20vw;
}
@include gadgets-to(desktop){
  max-width:20vw;
}
@include gadgets-to(largeDesktop){
  max-width:20vw;
}
}

.bsGridImage {
  width:99%;
  height:20vh;
  object-fit: cover;
 // border-radius: 4px;
  cursor:pointer;
  //border:1px solid red;
  @include gadgets-to(tablet){
    width:99%;
  height:22vh;
  object-fit: cover;
 // border-radius: 4px;
  cursor:pointer;
  }
  @include gadgets-to(laptop){
    width:99%;
    width:99%;
    height:23vh;
    object-fit: cover;
   // border-radius: 4px;
    cursor:pointer;
}
@include gadgets-to(desktop){
  width:99%;
  height:24vh;
  object-fit: cover;
 // border-radius: 4px;
  cursor:pointer;
}
@include gadgets-to(largeDesktop){
  width:99%;
  height:29vh;
  object-fit: cover;
 // border-radius: 4px;
  cursor:pointer;
}
}

.bsGridFrame {
  position: relative;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  width:100%;
  height:28vh;
 // border-radius: 8px;
 //border:2px solid red;
  background-color: rgba(255, 255, 255, 0.8);
  //backdrop-filter: blur(4px);
  transition: background-color 0.5s;


  @include gadgets-to(mobile) {
    @media (max-width: 400px) {
      height:31vh;
    }
    @media (min-width: 400px) {
      height:29vh;
    }
  }
  @include gadgets-to(tablet) {
    height:30vh;
    @media(min-width:800px)and (max-width:1024px) {
      height:34vh;
    }
    @media(min-width:1100px){
      height:30.5vh;
    }
  }
  @include gadgets-to(laptop) {
    height:34vh;
  }
  @include gadgets-to(desktop) {
    height:36vh;

  }
  @include gadgets-to(largeDesktop) {
    height:42vh;
  }
}
.bsRecipeHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 // text-align:left; /* Center-align text inside the container */
  width: 100%;
  height: 3vh;
  font-size:12px;
  font-family:#{map-get($generalStyles,fontFamilyAnders)};
  font-weight:bold;
 // ellipsis
  white-space: nowrap;
  overflow: hidden;
  //margin-left:1vw;
  margin-top:0.5vh;
  
 
  @include gadgets-to(tablet) {
    font-size:12px;
    height:4vh;
    @media(max-width:1180px){
      font-size:14px;
    }
    @media(min-width:1181px){
      font-size:16px;
    }
  }
  @include gadgets-to(laptop) {
    font-size:16px;
  }
  @include gadgets-to(desktop) {
    font-size:20px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:24px;
  }
  
}
.bsRecipeHeaderChefName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 // text-align:left; /* Center-align text inside the container */
  width: 100%;
  height:4vh;
  font-size:8px;
  font-family:#{map-get($generalStyles,fontFamilyAnders)};
  font-weight:400;
 // ellipsis
  white-space: nowrap;
  overflow: hidden;
  //margin-left:1vw;
 // margin-top:0.5vh;
  
 @include gadgets-to(mobile) {
    font-size:8px;
    @media (min-width: 400px) {
      font-size: 10px;
    }
  }
  @include gadgets-to(tablet) {
    font-size:12px;
    @media(min-width:1070px){
      font-size:12px;
    }
    @media(min-width:1181px){
      font-size:16px;
    }
  }
  @include gadgets-to(laptop) {
    font-size:14px;
    margin-top:0.2vh;
  }
  @include gadgets-to(desktop) {
     font-size:18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:20px;
  }

}

.bsCategorySelectArea{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items: center;
  width:100%;
  height:3.5vh;

  @include gadgets-to(tablet) {
    width:50%;
    height:6.2vh;
  }

}
.bsCategorySelect{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:99%;
  height:30px;
  border-radius:4px;
  border-color: map-get($generalStyles,datalistBorderColor);
  outline:none;
  font-size:12px;
  font-family:#{map-get($generalStyles,fontFamilyAnders)};
  margin-top:1vh;
  margin:1px;
  background-color:white;
  @include gadgets-to(tablet) {
    font-size:16px;
  }
}
.bsLiveSpinnerContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(1);
  z-index:2005;
  @include gadgets-to(tablet){
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(1);
    z-index:2005;
  }
}
.bsLiveSearchArea {
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:100%;
  z-index:10;
  @include gadgets-to(tablet) {
    width: 100%;
    //margin-top:1vh;
    display:flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;
  }
  @include gadgets-to(mobile) {
    margin-top:0.5vh;
    margin-bottom:0.2vh;
    @media (min-width: 400px) {
      margin-top:0.5vh;
      margin-bottom:1.2vh;
      display: flex;
      flex-direction:row;
      justify-content:center;
      align-items: center;
      z-index:10;
      
    }

  }

}
.bsLiveSearch{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:98%;
  height:25px;
  border-radius:4px;
  border-color: map-get($generalStyles,datalistBorderColor);
  outline:none;
  font-size:12px;
  font-family:#{map-get($generalStyles,fontFamilyAnders)};
  //margin-top:1vh;
  outline:none;
  @include gadgets-to(tablet) {
    border:1px solid map-get($generalStyles,datalistBorderColor);
    width:97%;
    height:30px;
  }
}

.bsNameText {
  font-size: 20px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  margin-top: 1vh;
  @include gadgets-to(tablet) {
    font-size: 20px;
  }
  @include gadgets-to(laptop) {
    font-size: 20px;
  }
  @include gadgets-to(desktop) {
    font-size: 30px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 40px;
  }
}



.bsLiveLogo{
  // width:100%;
  // height:10vh;
  //border:1px solid red;
  margin-top:1vh;
  width:30%;
  @include gadgets-to(tablet) {
    width:10%;

}
}


.bsLiveLogoImage {
  position:absolute;
  top:0.6%;
  left:2%;

width:16%;
border-radius:50%;
//box-shadow: 0 0 10px #e6a925;



@include gadgets-to(mobile) {
  width:16%;

  @media (min-width:360px) and (max-width:370px) {
    top:1.3%;
    width:14%;
    border-radius:50%;
  }

  @media (min-width: 400px)and (max-width:411px) {
    top:1%;
    width:16%;
    border-radius:50%;
  }
  @media(min-width:412px) {
    top:1.2%;
    width:15.5%;
    left:2.4%;
    border-radius:50%;
  }
}
@include gadgets-to(tablet) {
  top:0.6%;
  width:5%;
  left:0.7%;
  @media(max-width:1070px) {
    top:1.1%;
    width:5.8%;
    left:0.7%;
  }
  @media(min-width:1070px) and (max-width:1181px) {
    top:1.1%;
    width:4.5%;
    left:0.7%;
  }
  @media(min-width:1182px) and (max-width:1441px) {
    top:0.9%;
    width:5.5%;
    left:0.7%;
  }
  
}@include gadgets-to(laptop) {
  top:1%;
  width:5.5%;
  left:0.7%;

  @media(min-width:1740px)and (max-width:1800px) {
    position:absolute;
    width:4%;
    top:0.4%;
    left:1%;
  }
}
@include gadgets-to(desktop) {
  top:1.1%;
  width:3.8%;
  left:0.7%;

}
@include gadgets-to(largeDesktop) {
  top:0.8%;
  width:3.6%;
  left:0.7%;

}
}

.bsLiveName{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  font-size:32px;
  font-family: #{map-get($generalStyles, fontFamilyLobster)};
  color: map-get($generalStyles, colorGold);
 // margin-top: 1vh;
  width:100%;
  height:7vh;
  //margin-left:11vw;
  @include gadgets-to(mobile) {
    font-size: 32px;
    @media (min-width: 400px) {
      font-size: 40px;
    }
  }
  @include gadgets-to(tablet) {
   width:20%;
   font-size:30px;
   align-items:flex-end;
  }
  @include gadgets-to(laptop) {
    width:20%;
    font-size:45px;
    align-items:flex-end;
  }
  @include gadgets-to(desktop) {
    width:20%;
    font-size:45px;
    align-items:flex-end;
  }
  @include gadgets-to(largeDesktop) {
    width:20%;
    font-size:45px;
    align-items:flex-end;
  }
  
}

.bsHideImage {
  position: absolute;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  transform: translate(100vw, -100vh) scale(0);
  opacity: 0;
  pointer-events: none;
}

.bsRecipeSocialArea {
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  width:98%;
  height: 5vh;
  font-size: 12px;
  @include gadgets-to(tablet) {
    font-size: 18px;
  }
}

.bsRecipeSocialButton {
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  width:70%;
  height: 3vh;
 // margin-left:1px;
  font-size: 12px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-weight: 300;
  white-space: nowrap;
  background-color:rgb(66, 153, 66);
  //hover
  &:hover {
    background-color:rgb(53, 204, 53);
    color:white;
    font-size:14px;
  }
  cursor: pointer;
  @include gadgets-to(tablet) {
    font-size: 18px;

  }
}





// Define keyframes for scaling animation
@keyframes expand {
  from {
    transform: scale(0.1); // Start small
    opacity: 0;
  }
  to {
    transform: scale(1); // Full size
    opacity: 1;
  }
}

// Apply the animation to a modal or popup container
.bsLiveModalTop {
  display:flex;
  flex-direction:column;
//  justify-content: center;
  align-items: center;
  width: 800px;  // Final width
  height: 400px; // Final height
  position: fixed;
  top: 20%;
  z-index: 1001; // Ensure the modal is on top
 // left: 2%;
  transform: translate(-50%, -50%); // Center the modal
  background: white;
  box-shadow: 12px 12px 8px rgba(0, 0, 0, 0.5);
  //border-radius: 8px;
  opacity: 1;
  animation: expand 0.5s ease-out forwards; // Smooth expand animation
  //border:1px solid map-get($generalStyles,datalistBorderColor);

@include gadgets-to(mobile) {
  width:100%;
  height:95vh;
  top:9.6%;
  // left:0;
  @media (min-width: 400px) {
   width:99%;
  height:95vh;
  top:9.5%;
  }
}

@include gadgets-to(tablet) {
  position:fixed;
  top:0%;
  width:100vw;
  height:100vh;
 // border:1px solid grey;
 // border-radius: 8px;
}

@include gadgets-to(laptop) {
  position:fixed;
  top:4%;
  width:90vw;
  height:95vh;
  border:1px solid grey;
  border-radius: 8px;

}
@include gadgets-to(desktop) {
  position:fixed;
  top:4%;
  width:90vw;
  height:95vh;
  border:1px solid grey;
  border-radius: 8px;

}
@include gadgets-to(largeDesktop) {
  position:fixed;
  top:4%;
  width:80vw;
  height:95vh;
  border:1px solid grey;
  border-radius: 8px;

}
}

.bsLiveModalCloseIconWrapper {
display:flex;
flex-direction:row;
justify-content: flex-end;
align-items: center;
width:100%;
height:3vh;
}

.bsLiveModalTopClose {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 24px;
  color:black;
 // background: map-get($generalStyles, colorGold);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:5px;
  cursor: pointer;
  @include gadgets-to(largeDesktop){
    width:40px;
    height:40px;
    font-size:30px;
    
  }
}

.bslivexvspinner-text{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width:20%;
  height:5vh;
  font-size: 16px;
  font-family: var(--font-family);
  background-color:white;
  border-radius:12px;
  border:3px solid map-get($generalStyles,colorGold);
  padding:3px;
  font-size:18px;
  font-family: #{map-get($generalStyles, fontFamilyLobster)};
   box-shadow:12px 12px 8px rgba(0, 0, 0, 0.5);
   z-index:2006;
@include gadgets-to(mobile) {
  width: 80%;
  height: 4vh;
  font-size: 12px;

}
@include gadgets-to(tablet) {
  width: 40%;
  height: 6vh;
  font-size: 14px;
  }
}



// .bsLiveloading-dots {
//   display: inline-flex;
//   justify-content:space-around;
//   align-items: center;
//   margin-left:3px;
//   width:40%;
  
//   span {
//     width: 8px;
//     height: 8px;
//     margin: 0 4px;
//     background-color: #333;
//     border-radius: 50%;
//     display: inline-block;
//     animation: loading2 1s infinite ease-in-out;
    
//     &:nth-child(1) {
//       animation-delay: 0s;
//     }
//     &:nth-child(2) {
//       animation-delay: 0.2s;
//     }
//     &:nth-child(3) {
//       animation-delay: 0.4s;
//     }
//   }
// }
.bsLiveloading-dots {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 3px;
  width: 40%;

  span {
    width: 16px;  // Increase width and height for star shape
    height: 16px;
    margin: 0 4px;
    background-color: #f8b400;  // Yellow color for the stars
    display: inline-block;
    animation: loading2 1s infinite ease-in-out;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);  // Star shape using clip-path
    transform: rotate(90deg);  // Rotate stars to appear upright

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes loading2 {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

// .bsLiveRecipeHeader {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   text-align: center; /* Center-align text inside the container */
//   width: 100%;
//   height: 4vh;
//   font-size: 16px;
//   font-family: #{map-get($generalStyles, fontFamilyAnders)};
//   @include gadgets-to(tablet) {
//     font-size: 18px;
//   }
// }

.bsLiveRecipeListWrapper {
 display:flex;
  flex-direction:column;
 //justify-content: center;
  align-items: center;
  margin-bottom:1rem;
  width:100%;
  height:80vh;
@include gadgets-to(tablet) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items: center;
  // margin-bottom:1rem;
  width:55%;
 // height:100vh;
 // margin-top:2vh;
 @media(min-width:834px)and (max-width:1180px){
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items: center;
  width:55%;
  margin-top:2vh;
 }
}
@include gadgets-to(laptop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items: center;
  width:55%;

}
@include gadgets-to(desktop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items: center;
  width:55%;
}
@include gadgets-to(largeDesktop) {
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items: center;
  width:50%;
}
}

.bsLiveRecipeHeaderWrapper{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  height:90vh;
  width:100%;

  @include gadgets-to(tablet) {
    display:flex;
    flex-direction:column;
   justify-content:flex-start;
   align-items: center;
    height:92vh;
    @media(min-width:1100px){
      height:84vh;
    
    }
  }
  @include gadgets-to(laptop) {
    display:flex;
    flex-direction:column;
   justify-content:flex-start;
   align-items: center;
    height:92vh;
    @media(min-width:1100px){
      height:84vh;
    
    }
  }
  @include gadgets-to(desktop) {
    display:flex;
    flex-direction:column;
   justify-content:flex-start;
   align-items: center;
    height:92vh;
    @media(min-width:1100px){
      height:84vh;
    
    }
  }
  @include gadgets-to(largeDesktop) {
    display:flex;
    flex-direction:column;
   justify-content:flex-start;
   align-items: center;
    height:92vh;
    @media(min-width:1100px){
      height:84vh;
    
    }
  }

}
.bsLiveRecipeHeader{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:100%;
  //gap:5px;
  height:5vh;
  font-size: 16px;
  margin-bottom:0.1vh;
 // margin-top:1vh;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size:16px;
  font-weight:bold;
 // padding-left:3vw;
@include gadgets-to(tablet){
  height:10vh;
  width:40vw;
  @media (min-width:1180px) {
    height:4vh;
    width:35vw;
    margin-bottom:1vh;
  }
}

}
.bsLiveRecipeListHeader{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:100%;
  //gap:5px;
  height:5vh;
  font-size: 16px;
  margin-bottom:0.1vh;
 // margin-top:1vh;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size:16px;
  font-weight:bold;
 // padding-left:3vw;
 @include gadgets-to(mobile) {
  @media (min-width:400px) {
    height:2.3vh;
    margin-top:5px;
  }
  @media (max-width:399px) {
    height:3.5vh;
    margin-top:5px;
  }

}
@include gadgets-to(tablet){
  height:3vh;
  width:50.1vw;
  @media(min-width:1100px) {
    width:52vw;
  }
}
@include gadgets-to(laptop){
 margin-top:0.5vh;
}
@include gadgets-to(desktop){
  margin-top:0.5vh;
}
@include gadgets-to(largeDesktop){
  margin-top:0.5vh;
}
}




.bsLiveIngredHeaderName{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:40vw;
  font-size: 11px;
  border-radius: 4px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:rgb(223, 225, 170);
  padding-left:3px;
  color:black;
  height:18px;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;

  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
  }
}
.bsLiveHeaderKgPrice{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:calc(15vw - 2px);
  margin-left:2px;
  font-size: 11px;
  border-radius: 4px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:rgb(223, 225, 170);
  color:black;
  height:18px;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
  }
}
.bsLiveHeaderPris{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:calc(12vw - 2px);
  margin-left:2px;
  font-size: 11px;
  border-radius: 4px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:rgb(223, 225, 170);
  color:black;
  height:18px;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
  }

}

.bsLiveHeaderMangd{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:calc(15vw - 2px);
  margin-left:2px;
  font-size: 11px;
  border-radius: 4px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:rgb(223, 225, 170);
  color:black;
  height:18px;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
  }

}
.bsLiveHeaderKalories{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:calc(13vw - 2px);
  margin-left:2px;
  font-size: 11px;
  border-radius: 4px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:rgb(223, 225, 170);
  color:black;
  height:18px;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
  }
}
.bsLiveIngredName{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:40vw;
  font-size: 12px;
  border-radius: 4px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:rgb(223, 225, 170);
  padding-left:3px;
  color:black;
  height:21px;
  @include gadgets-to(tablet) {
    font-size: 14px;
    height:26px;
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 20px;
    height:32px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    height:32px;
  }
}
.bsLiveIngredName1{
@extend .bsLiveIngredName;
background-color:rgb(199, 202, 106)
}
.bsLiveKgPrice{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:calc(15vw - 2px);
  margin-left:2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:rgb(223, 225, 170);
  color:black;
  height:21px;
  @include gadgets-to(tablet) {
    font-size: 14px;
    height:26px;

  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 16px;
    height:32px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
    height:32px;
  }
}
.bsLiveKgPrice1{
  @extend .bsLiveKgPrice;
  background-color:rgb(199, 202, 106)
}

.bsLiveMangd{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:calc(15vw - 2px);
  margin-left:2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color:rgb(223, 225, 170);
  color:black;
  height:21px;
  @include gadgets-to(tablet) {
    font-size: 14px;
    height:26px;
    
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 16px;
    height:32px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
    height:32px;
  }
}
.bsLiveMangd1{
  @extend .bsLiveMangd;
  background-color:rgb(199, 202, 106)
}
.bsLivePris{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:calc(12vw - 2px);
  margin-left:2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color: rgb(223, 225, 170);
  color:black;
  height:21px;
  @include gadgets-to(tablet) {
    font-size: 14px;
    height:26px;
    
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 16px;
    height:32px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
    height:32px;
  }
}
.bsLivePris1{
  @extend .bsLivePris;
  background-color:rgb(199, 202, 106)
}
.bsLiveKalories{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width:calc(13vw - 2px);
  margin-left:2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  background-color: rgb(223, 225, 170);
  color:black;
  height:21px;
  @include gadgets-to(tablet) {
    font-size: 14px;
    height:26px;
    
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 16px;
    height:32px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
    height:32px;
  }
}
.bsLiveKalories1{
  @extend .bsLiveKalories;
  background-color:rgb(199, 202, 106)
}

.bsLiveOrder{
  width:calc(50px - 2px);
  margin-left:2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color: map-get($generalStyles, colorGold);
  border:1px solid map-get($generalStyles,datalistBorderColor);
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
}

.bsLiveIngredWrapper{
  display:flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  width:100%;
  height:80vh;
  max-height:80vh;
  overflow-y:auto;
  //webkit scrollbar
  &::-webkit-scrollbar {
    display:none;
  }
@include gadgets-to(tablet) {
//   display:flex;
//   flex-direction:column;
//  //justify-content: center;
//  align-items: center;
  width:100%;
  height:80vh;
  max-height:80vh;
}

}
.bsLiveIngredRowWrapper{
  // display:flex;
  // flex-direction:column;
  //  justify-content: center;
  // align-items: center;
  width:100%;
 // height:3vh;
 @include gadgets-to(tablet) {
width:91%;
@media(min-width:1100px){
  width:100%;
}
}
@include gadgets-to(laptop) {
  width:100%;
}
@include gadgets-to(desktop) {
  width:100%;
}
@include gadgets-to(largeDesktop) {
  width:100%;
}
}
.bsLiveIngredRowWrapper1{
  display:none;
}

.bsLiveIngredRow{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height:3vh;
  cursor:pointer;
  @include gadgets-to(mobile) {
    height:3.5vh;
    margin-top:2px;
    @media (min-width: 400px) {
      height:3vh;
    }
    
  }
  @include gadgets-to(tablet) {
    height:3vh;
    width:100%;
    margin-top:3px;
    display:flex;
    flex-direction:row;
    justify-content:center;
   align-items:flex-end;
  @media(min-width:834px)and (max-width:1180px) {
    height:3.6vh;
  }

}
@include gadgets-to(laptop) {
  height:3vh;
}
@include gadgets-to(desktop) {
  height:4vh;
}
@include gadgets-to(largeDesktop) {
  height:3.6vh;
}
}

.bsLiveRecipeText{
  display:flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  //margin-top:1vh;
  width:96%;
  height:60vh;

}
.bsLiveRecipeTextInner{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:40vh;
  max-height:40vh;
  overflow-y:auto;
  border:none;
  outline:none;
  margin-bottom:5vh;
  //webkit scrollbar
  &::-webkit-scrollbar {
    display:none;
  }
}
.bsLiveRecipeTextInner1{
  // display:flex;
  // flex-direction:column;
  // justify-content: center;
  // align-items: center;
  width:100%;
  height:40vh;
  max-height:40vh;
  overflow-y:auto;
  z-index: 10;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  outline:none;
  border-radius: 4px;
  //webkit scrollbar
  &::-webkit-scrollbar {
    display:none;
  }
}
.bsLiveRecipeTextHeader{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:3.5vh;
  font-size:14px;
  font-weight:300;
  font-family:#{map-get($generalStyles,fontFamilyAnders)};
  @include gadgets-to(tablet) {
    font-size:18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:20px;
  }
}


.bsLiveblurred {
  filter: blur(5px); // Adjust the blur intensity as needed
  opacity: 0.5; // Optional: to add a bit of transparency for further obfuscation
}
.bsLiveActionAreaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

}
.bsLiveActionArea{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 12px;
  @include gadgets-to(tablet) {
    font-size: 18px;
  }
  @include gadgets-to(laptop) {
    font-size: 24px;
    height:2vh;
  }
}
.bsLiveActionAreaButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:70%;
  height: 5vh;
  border-radius:4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  cursor: pointer;
  font-size:16px;
  font-family:#{map-get($generalStyles,fontFamilyAnders)};
  margin-left:1vw;
  box-shadow: 7px 7px 6px 1px  #b4ab98;
  margin-bottom:1vh;
  background-color:lightgray;
  @include gadgets-to(tablet) {
    font-size: 18px;
  }
}
.bsLiveButtonIcon {
width:20px;
height: 30px;
color:green;
}

.bsLivePaymentTopWrapper {
  display: flex;
  flex-direction: column;
 // justify-content: center;
  align-items: center;
  width:96%;
  height:30vh;
  background-color: white;
 // border:1px solid red;
  border-radius: 4px;
  z-index: 20;
  // animation
  animation: expand 1s ease-out forwards; // Smooth expand animation
}
.bsLivePaymentTop{
  display: flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  width: 100%;
  height:10vh;
  margin-bottom:10vh;
  @include gadgets-to(mobile) {
    @media(max-width:370px){
      height:10vh;
    }
    height:20vh;
  }
@include gadgets-to(tablet) {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:50%;
  height:24vh;
 // margin-top:1vh;
}
@include gadgets-to(laptop) {
  width:80%;
  height:7vh;
  display: flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  margin-top:1vh;
}
@include gadgets-to(desktop) {
  width:60%;
  height:7vh;
  display: flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  margin-top:1vh;
}
@include gadgets-to(largeDesktop) {
  width:55%;
  height:4vh;
  display: flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  margin-top:1.5vh;
}
}
.bsLivePaymentTop1{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:50%;
  position:absolute;
  @include gadgets-to(mobile) {
    @media (min-width: 400px) {
      top:45%;
    left:0.9%;
    width:98%;

    }
    @media (max-width:370px) {
      top:52%;
      left:0.9%;
      width:98%;
      height:10vh;
   
  }
}
  @include gadgets-to(tablet) {
    top:62%;
    left:25%;
    height:15vh;
  }
  @include gadgets-to(laptop) {
    top:65%;
    left:25%;
    height:15vh;
  }
  @include gadgets-to(desktop) {
    top:60%;
    left:25%;
    height:20vh;
  }
  @include gadgets-to(largeDesktop) {
    top:54%;
    left:25%;
    height:20vh;
  }
}


 
.bsLivePaymentMessage{
  display: flex;
  flex-direction:column;
  justify-content:flex-end;
  align-items:center;
  width: 100%;
  height: 3.5vh;
  font-size:12px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:8px;
  margin-left:3vw;
  border-radius:4px;
  color:black;
  border:2px dotted map-get($generalStyles,colorGold);
  padding-bottom:8px;
  padding-left:1vw;
  // hover
  &:hover {
    background-color:lightgrey;
  }
  @include gadgets-to(mobile) {
     width:85%;
     margin-left:unset;
     margin-top:8px;
     font-size: 10px;
  }
  @include gadgets-to(tablet) {
    font-size: 14px;
    margin-left:unset;
  }
  @include gadgets-to(laptop) {
    width:70%;
    margin-left:unset;
  }
  @include gadgets-to(desktop) {
    width:70%;
    margin-left:unset;
  }
}
// .bsLiveClearMessage{
//   display: flex;
//   flex-direction:row;
//   justify-content:flex-start;
//   align-items: center;
//   width:80%;
//   font-size:14px;
//   font-family: #{map-get($generalStyles, fontFamilyAnders)};
// height:4vh;
// }
.bsLivePaymentRadio{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 12px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
  @include gadgets-to(tablet) {
    font-size: 12px;
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 22px;
  }
}

.bsLivePaymentRadio1{
  @extend .bsLivePaymentRadio;
  font-weight:bold;
}

.bsLivePaymentContactArea{
  display: flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  margin-top:1vh;
  @include gadgets-to(mobile) {
    margin-top:10vh;
    width:95%;
    @media (min-width: 400px) {
      margin-top:7vh;
    }
  }
  @include gadgets-to(tablet) {
    height:40vh;
  }
  @include gadgets-to(laptop) {
    height:40vh;
    width:80%;
  }
}

.bsLivePaymentContact{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:94%;
  height:22px;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
  @include gadgets-to(mobile) {
    height:27px;
  }
  @include gadgets-to(tablet) {
    width:100%;
    height:30px;
  }
  @include gadgets-to(laptop) {
    width:100%;
    height:30px;
  }
  @include gadgets-to(desktop) {
    width:100%;
    height:35px;
  }
  @include gadgets-to(largeDesktop) {
    width:100%;
    height:30px;
  }
}
.bsLivePaymentButton{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:90%;
  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-weight: bold;
  margin-top:1vh;
  color:white;
  background-color: #2c79df;
  @include gadgets-to(mobile) {
  //  margin-top:2vh;
    width:80%;
  }
@include gadgets-to(tablet) {
  font-size: 18px;
  width:80%;
}

  @include gadgets-to(laptop) {
    font-size: 18px;
    width:100%;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;
    width:100%;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 18px;
    width:100%;
  }

}
.bsLivePaymentButton1{
  @extend .bsLivePaymentButton;
  background-color: #2c79df;
  width:100%;
}
.bsLivePaymentTopWrapper{
  position:absolute;
  top:0%;
  left:0%;
  display: flex;
  flex-direction: column;
 // justify-content: center;
  align-items: center;
  width:100%;
  height:95vh;
  max-height:95vh;
  overflow-y:scroll;
  overflow-x:hidden;
  margin-bottom: 3vh;
  // wbkit scrollbar
  &::-webkit-scrollbar {
    display:none;
  }
  background-color: white;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  z-index: 20;
  // animation
  animation: expand 1s ease-out forwards; // Smooth expand animation
@include gadgets-to(tablet){
  height:95vh;
  position:absolute;
  top:0%;
  left:0%;
}
}

.bsLivePaymentCloseIconWrapper{
  display:flex;
  flex-direction:row;
  justify-content: flex-end;
  align-items: center;
  width:100%;
  height:1vh;
  margin-top:1vh;
  margin-right:1vh;
}


.bsLivePaymentCloseIcon2{
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 24px;
  color:black;
 // background:map-get($generalStyles, colorGold); 
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:3px;
  cursor: pointer;
  @include gadgets-to(largeDesktop) {
    width:30px;
    height: 30px;
    font-size: 32px;
  }
}

// .bsLivePaymentContactHeader{
//   display: flex;
//   flex-direction:column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 4vh;
//   font-size: 16px;
//   font-family: #{map-get($generalStyles, fontFamilyAnders)};
//   margin-top:1vh;
//   color:black;
//   margin-top:1vh;
// }
.bsLiveExtraText{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  font-weight:bold;
  white-space: nowrap;
  width:100%;
  color:black;
  @include gadgets-to(tablet) {
    font-size: 14px;
  }
  @include gadgets-to(laptop) {
    font-size: 20px;
  }
  @include gadgets-to(desktop) {
    font-size: 18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:24px;
  }
  
}
.bsLiveHr{
  width:25%;
  height:1px;
  background-color:map-get($generalStyles,datalistBorderColor);
  outline:none;
  margin-top:1vh;
  border:none;

}
.bsLivePaymentSelectCurrencyArea{
  display: flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  margin-top:1vh;
}
.bsLivePaymentSelectCurrency{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:70%;
  height:30px;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  background-color: white;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
@include gadgets-to(laptop) {
  width:50%;
}
@include gadgets-to(desktop) {
  width:60%;
}
@include gadgets-to(largeDesktop) {
  width:60%;

}
}

.bsLiveCreatorProfileInformation{
  display: flex;
  flex-direction: column;
  //justify-content: center;
 // align-items: center;
  width: 100%;
  height:24vh;
  @include gadgets-to(mobile) {
    height:24vh;
    @media(min-width:400px) {
      height:20vh;
    }
  }
  @include gadgets-to(tablet) {
    width:70%;
    height:20vh;
  }
  @include gadgets-to(laptop) {
    width:70%;
    height:25vh;
  }
  @include gadgets-to(desktop) {
    width:60%;
    height:30vh;
  }
  @include gadgets-to(largeDesktop) {
    width:60%;
    height:30vh;
  }

}
.bsLiveUserPresentationTopWrapper{
  display: flex;
  flex-direction: column;
 justify-content: center;
align-items: center;
 width:100%;
 @include gadgets-to(tablet) {
  height:20vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
align-items: center;
}
}

.bsLiveUserPresentationBox{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  @include gadgets-to(mobile) {
  width:90%;
  height:10vh;
  }
  @include gadgets-to(largeDesktop) {
    width:85%;
    height:17vh;
  }
}
.bsLiveUserPresentationInnerBox{
  display:flex;
  flex-direction:column;
//  justify-content: center;
  align-items: center;
  width:100%;
  height:10vh;
  @include gadgets-to(largeDesktop) {
    height:15vh;
  }
}

.bsLiveUserPresentationProChefArea{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: lightgrey;
  width:5vw;
  height:2vh;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size:7px;
  padding:1px;
  margin-left:1vw;
  @include gadgets-to(mobile) {
    width:25%;
  }
  @include gadgets-to(largeDesktop) {
    width:35%;
    height:3vh;
    font-size:16px;
    margin-top:5px;
  }
}
.bsLiveUserPresentationStarArea{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:21vw;
  height:3vh;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size:10px;
  padding:1px;
  margin-left:1vw;
  @include gadgets-to(mobile) {
    width:95%;
    @media(min-width:400px) {
      font-size:11px;
    }
  }
  @include gadgets-to(tablet) {
    font-size:12px;
  }
  @include gadgets-to(laptop) {
    font-size:16px;
  }
  @include gadgets-to(desktop) {
    font-size: 20px;

  }
  @include gadgets-to(largeDesktop) {
    font-size: 25px;
    height:6vh;
    width:31vw;
    margin-top:5px;
  }
}
.bsLiveUserPresentationStarIcon{
  width:13px;
  height:13px;
  color:map-get($generalStyles, colorGold);
  fill:map-get($generalStyles, colorGold);
  @include gadgets-to(mobile) {
    @media(min-width:400px) {
      width:15px;
      height:15px;
    }
  }
  @include gadgets-to(tablet) {
    width:12px;
    height:12px;
  }
  @include gadgets-to(desktop) {
    width:20px;
    height:20px;
  }
  @include gadgets-to(largeDesktop) {
    width:30px;
    height:30px;
  }
}

.bsLiveUserPresentationGlobalArea{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:21vw;
  height:2vh;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size:10px;
  padding:1px;
  margin-left:1vw;
  color:grey;
  @include gadgets-to(mobile) {
    width:95%;
    @media(min-width:400px) {
      font-size:11px;
    }
  }
  @include gadgets-to(tablet) {
    font-size:14px;
  }
  @include gadgets-to(laptop) {
    font-size:14px;
  }
  @include gadgets-to(desktop) {
    font-size: 20px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 22px;
    height:3vh;
    width:31vw;
    margin-top:1px;
  }
}
.bsLiveUserPresentationGlobalIcon{
  width:13px;
  height:13px;
  color:grey;
  @include gadgets-to(mobile) {
    @media(min-width:400px) {
      width:15px;
      height:15px;
    }
  }
  @include gadgets-to(desktop) {
    width:20px;
    height:20px;
  }
  @include gadgets-to(desktop) {
    width:20px;
    height:20px;
  }
  @include gadgets-to(largeDesktop) {
    width:30px;
    height:30px;
  }

}

.bsLiveUserPresentationLanguages{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:65vw;
  height:2vh;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size:10px;
  padding:1px;
  margin-left:1vw;
  margin-top:3px;
  color:grey;
  white-space: nowrap;
  @include gadgets-to(mobile) {
    @media(min-width:400px){
      width:63vw;
    }
  }
  @include gadgets-to(tablet) {
    width:21vw;
    font-size:14px;
  }
  @include gadgets-to(laptop) {
    width:21vw;
    font-size:14px;
  }
  @include gadgets-to(desktop) {
    width:21vw;
    font-size:20px;
  }
  @include gadgets-to(largeDesktop) {
    width:31vw;
    font-size:20px;
    margin-top:10px;
}
}
.bsLiveUserPresentationLangIcon{
  width:13px;
  height:13px;
  color:grey;
  @include gadgets-to(mobile) {
    @media(min-width:400px) {
      width:15px;
      height:15px;
    }
  }
  @include gadgets-to(tablet) {
    width:17px;
    height:17px;
  }
  @include gadgets-to(desktop) {
    width:21px;
    height:21px;
  }
  @include gadgets-to(largeDesktop) {
    width:32px;
    height:32px;
    margin-top:15px;
  }
}

.proChefIcon{
  width:10px;
  height:10px;
  color:black;
  @include gadgets-to(largeDesktop) {
    width:25px;
    height:25px;

  }
}

.bsLiveCreatorProfileName{
  display: flex;
  flex-direction:row;
  justify-content: center;
 // align-items: center;
  width:100%;
  height: 3vh;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  @include gadgets-to(mobile) {
    justify-content: flex-start;
  }
@include gadgets-to(tablet) {
  font-size: 16px;
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
   align-items:flex-end;
   margin-top:1vh;
   font-weight:bold;
  
}
@include gadgets-to(laptop) {
  font-size: 20px;
}
@include gadgets-to(desktop) {
  font-size: 24px;
}
@include gadgets-to(largeDesktop) {
  font-size: 40px;
}
}

.bsLiveCreatorProfileImage{
width:100px;
height:100px;
border-radius:50%;
margin-left:1vw;
padding:10px;

@include gadgets-to(mobile){
  @media(max-width:370px) {
    width:60px;
    height:80px;
    margin:0;
  }
  @media(min-width:400px){
    width:100px;
    height:100px;
    margin:0;
  }
  
}

@include gadgets-to(tablet){
  width:70px;
  height:60px;
  border:1px solid;
  padding:unset;
}
@include gadgets-to(laptop){
  width:110px;
  height:110px;
  margin:0;
}
@include gadgets-to(desktop){
  width:110px;
  height:110px;
  margin:0;
}
@include gadgets-to(largeDesktop){
  width:150px;
  height:140px;
  margin-left:unset;
}
}

.bsLiveCreatorInfoBox{
  display: flex;
  flex-direction:row;
  justify-content: center;
  //align-items: center;
  width: 100%;
  height: 18vh;
  margin-top:1vh;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
@include gadgets-to(mobile) {
  @media(max-width:370px) {
    height:14vh;
  }
  height:12vh;
  margin-top:unset;
}
 @include gadgets-to(tablet) {
  height:32vh;
  border-radius: 8px;
  margin:0;
  @media(min-width:834px)and (max-width:1025px) {
    height:22vh;
  }
@media(min-width:1026px)and (max-width:1179px) {
  height:32vh;
}
@media(min-width:1180px) {
  height:32vh;
}
}
@include gadgets-to(laptop) {
  width:88%;
//  margin-top:2vh;
  height:22vh;
  border-radius: 8px;
}
@include gadgets-to(desktop) {
  width:55%;
  height:20vh;
  border-radius: 8px;
}
@include gadgets-to(largeDesktop) {
  width:90%;
  //margin-top:2vh;
  height:20vh;
}
}
.bsLiveCreatorProfileText{
  display: flex;
  flex-direction:row;
 justify-content: center;
 //align-items: center;
  width:70%;
  height: 14vh;
  font-size: 12px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:grey;
  padding:5px;
border-radius: 8px;
margin-bottom:0.5vh;
@include gadgets-to(tablet) {
font-size: 14px;
height:8vh;
}
@media(min-width:834px)and (max-width:1180px) {
  font-size: 14px;
}
@include gadgets-to(laptop) {
  font-size:18px;
}
@include gadgets-to(desktop) {
  font-size:22px;
  width:100%;
}
@include gadgets-to(largeDesktop) {
  font-size:24px;
}
}
.bsLiveSeparator{
  width:100%;
  height:1px;

}


.bsLiveCheckoutButton{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:98%;
  height: 3vh;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  //margin-top:1vh;
  color:white;
  // hover
  &:hover {
   // background-color: map-get($generalStyles, colorGold);
    color: white;
    border:2px solid #5e97e0;
    font-size:18px;
  }
}

.bsLiveStripeCompleteBoxWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:99%;
  height: 80vh;
  background-color: white;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  //border-radius:8pxpx;
  z-index: 20;

}

.bsLiveStripeCompleteText{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 4vh;
  font-size:16px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
}
.bsLiveStripeCompleteIcon{
width:30px;
height:30px;
color:green;
}

.bsLiveStripeConfirmBoxWrapper{
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  background-color: white;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
}
.bsLiveCustomerInfoBox{
  display: flex;
  flex-direction: column;
 // justify-content: center;
  align-items: center;
  width:93%;
  height:14vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 6px;
  margin-top:1vh;
 //box-shadow:inset 0 0 10px #e6a925,
 // 2px 2px 8px 1px #dbd6ca;
  padding-bottom:6px;
  padding-top:3px;

  @include gadgets-to(mobile) {
    height:12vh;
    @media (min-width: 400px) {
      height:12vh;
    }
  }

@include gadgets-to(tablet) {

  width:38%;
  margin-top:1vh;
  margin-bottom:5px;
  @media(max-width:1025px){
    width:41%;
  }
    @media(min-width:1430px){
      width:31%;
  
  }
}

@include gadgets-to(laptop) {
margin-top:unset;
width:35%;
}
@include gadgets-to(desktop) {
  margin-top:unset;
  width:38.5%;
}
@include gadgets-to(largeDesktop) {
  margin-top:10px;
  width:31%;

}
}
.bsLiveCustomerInfoHeaderWrapper{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:100%;

}
.bsLiveInfoTextWrapper{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width:80%;
}
.bsLiveCustomerInfoTextHeader{
  display: flex;
  flex-direction:row;
 // justify-content: center;
 // align-items: center;
  width:100%;
  font-size: 18px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-weight:bold;
  //margin-top:1vh;
  color:black;
  margin-left:2vw;
  @include gadgets-to(mobile) {
    font-size: 20px;
    @media (max-width: 400px) {
      font-size: 16px;
    }
  }
}
.bsLiveCustomerInfoText{
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  align-items: center;
  width:90%;
  height:3.5vh;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-weight:bold;
  //margin-top:1vh;
  color:black;
  @include gadgets-to(mobile) {
     height:3.5vh;
    @media (max-width: 400px) {
      height:3vh;
    }
  }
}
.bsLiveCustomerInfoText2{
  display: flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items: center;
  width:90%;
  height:3.5vh;
  font-size: 20px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-weight:bold;
  //margin-top:1vh;
  color:black;
  @include gadgets-to(mobile) {
    height:3.5vh;
   @media (max-width: 400px) {
     height:3vh;
   }
}
}
.bsLiveCustomerInfoText3{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:20%;
  height:3vh;
  font-size:16px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-weight:bold;
  //margin-top:1vh;
  color:black;
  border-radius:12px;
  background-color: map-get($generalStyles,datalistBorderColor);
  @include gadgets-to(mobile) {
    height:3.5vh;
   @media (max-width: 400px) {
     height:3vh;
   }

}
}

.bsLivePaymentHr{
  width:90%;
  height:1px;
  background-color:map-get($generalStyles,datalistBorderColor);
  outline:none;
  margin-top:1vh;
  border:none;
}
.bsLivePaymentHr1{
  width:60%;
  height:1px;
  background-color:map-get($generalStyles,datalistBorderColor);
  outline:none;
  margin-top:1vh;
  border:none;
  @include gadgets-to(mobile) {
    width:85%;
  }
  @include gadgets-to(tablet) {
    width:55%;
  } 

}




.bsLiveLoginButtonChoiceArea{
  display: flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  width: 100%;
  height:5vh;
  margin-top:1vh;
  @include gadgets-to(mobile) {
    @media(max-width:370px){
      height:5vh;
      
    }


    @media (min-width: 400px) {
      height:8vh;
    }
  }
  @include gadgets-to(tablet) {
    height:8vh;
  }
  @include gadgets-to(laptop) {
    width:100%;
    height:8vh;
  }
  @include gadgets-to(desktop) {
    width:82%;
    height:8vh;
  }
  @include gadgets-to(largeDesktop) {
    width:82%;
    height:8vh;
}
}

.bsLiveLoginButtonChoice{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 3vh;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
  background-color: white;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
}









.bsLiveTabContainer{
  width:100%;
  height:72vh;
  margin: auto;
  font-family: Arial, sans-serif;
  //position: relative;
  background-color:white;
  margin-top:0.5vh;
  @include gadgets-to(tablet) {
    width:90%;
    height:20vh;
  }
  @include gadgets-to(desktop) {
    width:90%;
    height:18vh;
  }

}


.bsliveTabs {
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: -1px; /* For overlapping effect */

}

.bsliveTab {
  flex: 1;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-bottom: none;
  transition: background-color 0.3s;
  position: relative;
  top: 1px;
}

.bsliveTab:first-child {
  //border-top-left-radius: 8px;
}

.bsliveTab:last-child {
//  border-top-right-radius: 8px;
}

.bsliveTab.bsliveActive {
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom: none;
  font-weight: bold;
  color: #333;
  z-index: 2; /* Bring active tab to the front */
  @include gadgets-to(largeDesktop) {
    font-size: 20px;
  }
}

.bsLiveTabContent{
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  min-height: 100px;
  height:58vh;
  box-sizing: border-box;
  position: relative;
  top: -5px; /* Moves content up to overlap with the tabs */
  @include gadgets-to(tablet) {
    height:42vh;
  }
  @include gadgets-to(laptop) {
    height:39vh;
  }
  @include gadgets-to(desktop) {
    height:39vh;
  }
  @include gadgets-to(largeDesktop) {
    height:39vh;
  }
}
.bsLiveTabContentSmall{
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  min-height: 100px;
  height:20vh;
  box-sizing: border-box;
  position: relative;
  top: -5px; /* Moves content up to overlap with the tabs */
  @include gadgets-to(tablet) {
    height:20vh;
  }
}

.bsLiveSelectAreaWrapper{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  gap:5px;
  width: 100%;
  //height:10vh;
  margin-top:1vh;
}

.bsLiveUserLoginWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

}
.bsLiveUserLoginEmail{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:96%;
  height: 3vh;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
  @include gadgets-to(mobile) {
    height:3.5vh;
    @media (min-width: 400px) {
      height:2.7vh;
      width:90%;
    }
  }
  @include gadgets-to(laptop) {
    width:80%;

  }
  @include gadgets-to(desktop) {
    width:80%;
  }
  @include gadgets-to(largeDesktop) {
     width:80%;
  }
}
.bsLiveUserLoginPass{
@extend .bsLiveUserLoginEmail;
}

.bsLiveLoginButtonUser{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 98%;
//  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:white;
  background-color: #2c79df;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin:unset;
  padding:3px;
  margin-top:5px;
  // hover
  &:hover {
    background-color: #5e97e0;
    color: white;
    border:2px solid #5e97e0;
    font-size:18px;
  }
  @include gadgets-to(mobile) {
    width:98%;
    @media (min-width: 400px) {
      width:92%;
      height:35px;
    }
  }
  @include gadgets-to(tablet) {
    width:98%;
    height:30px;
  }
  @include gadgets-to(laptop) {
    width:81%;
    height:38px;
  }
  @include gadgets-to(desktop) {
    width:81%;
    height:40px;
    font-size:20px;
  }
  @include gadgets-to(largeDesktop) {
    width:81%;
    height:40px;
    font-size: 20px;
  }
}

.bsLiveUnlockButtonAreaWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

}
.bsLiveUnlockButton{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:white;
  background-color: #2c79df;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  cursor:pointer;
  // hover
  &:hover {
    background-color: #5e97e0;
    color: white;
    border:2px solid #5e97e0;
    font-size:18px;
  }
  @include gadgets-to(mobile) {
    width:98%;
    @media (min-width: 400px) {
      width:92%;
      height:38px;
      background-color: black;
      color:white;
    }
    @media (max-width:370px) {
      width:92%;
      height:38px;
      background-color: black;
      color:white;
    }
  }
  @include gadgets-to(tablet) {
    width:60%;
    height:35px;
    background-color: black;
    color:white;

  }
  @include gadgets-to(laptop) {
    width:80%;
    height:38px;
    background-color: black;
    color:white;
  }
  @include gadgets-to(desktop) {
    width:60%;
    height:40px;
    background-color: black;
    color:white;
    font-size:18px;
  }
  @include gadgets-to(largeDesktop) {
    width:60%;
    height:40px;
    font-size: 20px;
    background-color: black;
    color:white;
  }
}




.bsLiveCreditInfo{
  display: flex;
  flex-direction:column;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  height:22vh;
  font-size:14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-weight: 200;
  margin-top:1vh;
  color:black;
}
.bsLiveWalletIconContainer{
  position: relative;
  display: inline-block;
  @include gadgets-to(mobile){
    margin-top:5px;
  }
  @include gadgets-to(tablet){
    margin-top:1vh;
  }
}
// .bsLiveLoginWalletIcon{
//   width:60px;
//   height:60px;
//   color:green;
//   @include gadgets-to(tablet){
//     width:80px;
//     height:80px;
//   }
// }
// .bsLiveCreditBadge {
//   position: absolute;
//   top:26px;
//   right: -30px;
//   background-color: #10b981; /* Green background */
//   color: white;
//   padding: 4px 8px;
//   font-size: 12px;
//   font-weight: bold;
//   border-radius: 50%;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
//   @include gadgets-to(mobile){
//     top:20px;
//     right: -40px;
//     padding:10px 14px;
//   }
//   @include gadgets-to(tablet){
//   top: 31px;
//   right: -40px;
//   padding:10px 14px;
// }
// }

// .bsLiveClearRecipeIcon{
// width:18px;
// height:18px;
// color:green;
// }

.bsLivePaymentHeader{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:100%;
  height: 4vh;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
  @include gadgets-to(tablet) {
    font-size: 12px;
    height:5vh;
    margin-left:2vw;
    margin-top:unset;
    border:2px dotted lightgrey;
    border-radius: 6px;

  }
  @include gadgets-to(laptop) {
    font-size: 18px;
  }
  @include gadgets-to(desktop) {
    font-size: 24px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 24px;
  }
}

.bsLiveLogoutButton{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:white;
  background-color: #2c79df;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  // hover
  &:hover {
    background-color: #5e97e0;
    color: white;
    border:2px solid #5e97e0;
    font-size:18px;
  }
}

.bsLiveIngredExpanded{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:50%;
  height:10vh;
  max-height:10vh;
  overflow-y:auto;
   animation: expand 2s ease-out forwards; // Smooth expand animation
}


.bsLiveextraInfo {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease, opacity 1.5s ease;
  padding: 0 1px;
  opacity: 0;
}

.bsLiveextraInfo.expanded {
  max-height: 380px; /* Largest height (tablet) to allow smooth transition */
  opacity: 1;
  
  @include gadgets-to(mobile) {
    max-height: 180px; /* Adjust for mobile */
    @media (min-width: 400px) {
      max-height: 130px; /* Special case for small tablets/large phones */
    }
  }

  @include gadgets-to(tablet) {
    max-height: 380px; /* Adjust for tablet */
  }
}

.bsLiveextraInfo.collapsed {
  max-height: 0;
  opacity: 0;
}

.bsLiveTabLoginArea{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  background-color: white;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  z-index: 20;
  // animation
  animation: expand 1s ease-out forwards; // Smooth expand animation
margin-top:1vh;
}

.bsLiveTabLogoutWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  background-color: white;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  z-index: 20;
  // animation
  animation: expand 1s ease-out forwards; // Smooth expand animation
  }
  .bsLiveTabLogoutButton{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    width: 98%;
    height: 5vh;
    font-size: 14px;
    border-radius: 4px;
    outline:none;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    font-family: #{map-get($generalStyles, fontFamilyAnders)};
    margin-top:1vh;
    color:white;
    background-color: #2c79df;
    box-shadow: 2px 2px 4px 1px #e6dfcf;
    // hover
    &:hover {
      background-color: #5e97e0;
      color: white;
      border:2px solid #5e97e0;
      font-size:18px;
    }
  }

  .bsLiveTabLoginOnlyArea{
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    position:absolute;
    top: 20%;
    left: 25%;
    transform: translate(-50%, -50%);
    width:100%;
    height: 15vh;
    background-color: white;
    border-radius: 4px;
    z-index:200;
    animation: expand 1s ease-out forwards; // Smooth expand animation
    margin-top:1vh;
@include gadgets-to(mobile) {
  width:98%;
  top:9.3%;
  left:0;
  @media (min-width: 400px) {
    width:100%;
    top:8.7%;
    left:0;
  }
}
@include gadgets-to(tablet) {
  width:35%;
  top:9%;
  left:32%;

  box-shadow: 4px 4px 12px 1px #b1afab;
}
@include gadgets-to(laptop) {
  width:35%;
  top:9%;
  left:32%;
  box-shadow: 4px 4px 12px 1px #b1afab;
  }
  @include gadgets-to(desktop) {
    width:35%;
    top:9%;
    left:32%;
    box-shadow: 4px 4px 12px 1px #b1afab;
    }
    @include gadgets-to(largeDesktop) {
      width:35%;
      top:9%;
      left:32%;
      box-shadow: 4px 4px 12px 1px #b1afab;
      }
  }

  .bsLiveLoginOnlyTabContent{
   // border: 1px solid #ccc;
    padding: 10px;
    background-color:white;
    border-radius:4px;
    min-height: 100px;
    height:24vh;
    display:flex;
    flex-direction:column;
    align-items: center;
    z-index:1002;
    top: -5px; /* Moves content up to overlap with the tabs */
@include gadgets-to(mobile) {
  height:24vh;
  padding:3px;
  width:100%;
  @media (min-width: 400px) {
    height:16vh;
    width:100%;
   // padding:4px;
  }
}
@include gadgets-to(tablet) {
box-shadow: 4px 4px 12px 1px #b1afab;

}
@include gadgets-to(laptop) {
  box-shadow: 4px 4px 12px 1px #b1afab;
  height:19vh;
}
  @include gadgets-to(desktop) {
    box-shadow: 4px 4px 12px 1px #b1afab;
    }
    @include gadgets-to(largeDesktop) {
      box-shadow: 4px 4px 12px 1px #b1afab;
      }

}
.bsLiveLoginOnlyTabContent1{
  @extend .bsLiveLoginOnlyTabContent;
  height:15vh;
}
  .bsLiveTabOnlyContainer{
    width:100%;
    height:12vh;
    margin: auto;
    font-family: Arial, sans-serif;
    //position: relative;
    background-color:white;
    border-radius: 4px 4px;
  @include gadgets-to(mobile) {
    width:100%;
    height:20vh;
    padding:2px;
  }
  @include gadgets-to(tablet) {
    @media (min-width:1437px) {
      width:100%;
      height:20vh;
    }
    width:100%;
    height:20vh;
  }
  }
  .bsLiveLoginFormOnly{
border:none;
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items: center;
@include gadgets-to(mobile) {
  width:90%;
  @media (min-width: 400px) {
    width:100%;

  }
}
@include gadgets-to(tablet) {
  width:90%;
}
@include gadgets-to(largeDesktop) {
width:90%;
  }
}

  .bsliveOnlyTabs {
    display: flex;
    position: relative;
    width:100%;
    border-radius: 4px; 
    z-index: 1;
    margin-bottom: -1px; /* For overlapping effect */
  
  }
  
  .bsLiveLoginOnlyActive{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:100%;
      background-color: #fff;
     // border: 1px solid #ccc;
      border-bottom: none;
      font-weight: bold;
      color: #333;
      z-index: 2; /* Bring active tab to the front */
      padding-top:1vh;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      font-size: 16px;
      font-weight:300;
      @include gadgets-to(mobile) {
        font-size: 18px;
        font-weight:bold;
        @media (min-width: 400px) {
          font-size: 18px;
          font-weight:bold;
          width:100%;
        }
      }
      @include gadgets-to(laptop) {
        font-size: 22px;
        font-weight:bold;
        width:100%;
      }
      @include gadgets-to(desktop) {
        font-size: 24px;
        font-weight:bold;
        width:100%;
      }
      @include gadgets-to(largeDesktop) {
        font-size: 26px;
        font-weight:bold;
        width:100%;
      }

    }

    .bsLiveCloseLoginOnlyIcon{
      position: absolute;
      top: 0;
      right: 0;
      width: 18px;
      height: 18px;
      font-size: 24px;
      color: black;
     // background:map-get($generalStyles,colorGold);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:3px;
      cursor: pointer;
      @include gadgets-to(laptop){
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
      @include gadgets-to(largeDesktop){
        width: 26px;
        height: 26px;
        font-size: 24px;
      }
    }

    .bsLiveTabLogoutOnlyArea{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width:99%;
      height: 15vh;
      background-color: white;
      border-radius: 4px;
      z-index: 20;
      animation: expand 1s ease-out forwards; // Smooth expand animation
    margin-top:1vh;
@include gadgets-to(tablet) {
  width:50%;
  box-shadow: 4px 4px 12px 1px #b1afab;
}
@include gadgets-to(laptop){
  width:50%;
  box-shadow: 4px 4px 12px 1px #b1afab;
}
@include gadgets-to(desktop){
  width:50%;
  box-shadow: 4px 4px 12px 1px #b1afab;
}
@include gadgets-to(largeDesktop){
  width:30%;
  box-shadow: 4px 4px 12px 1px #b1afab;
}
    }

    .bsLiveLogouUserOnlyWrapper{
      display:flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      width:100%;
      height:10vh;
      border:1px solid map-get($generalStyles,datalistBorderColor);
     
    }

    .bsLiveLogoutUserOnlyButton{
      display: flex;
      flex-direction:row;
      justify-content:center;
      align-items: center;
      width: 95%;
      height: 5vh;
      font-size: 14px;
      border-radius: 4px;
      outline:none;
      border:1px solid map-get($generalStyles,datalistBorderColor);
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
    //  margin-top:1vh;
      color:white;
      background-color: #2c79df;
      box-shadow: 2px 2px 4px 1px #e6dfcf;
      // hover
      &:hover {
        background-color: #5e97e0;
        color: white;
        border:2px solid #5e97e0;
        font-size:18px;
      }
    }


    .bsLivePaymentCloseIcon3{
      position: absolute;
      top: 0;
      right: 0;

      width:15px;
      height:15px;
      font-size: 24px;
      color: black;
     // background:map-get($generalStyles, colorGold);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:3px;
      margin-left:3vw;
      cursor: pointer;
      @include gadgets-to(largeDesktop){
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .bsLiveLogoutOnlyButtonText{
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      font-weight: 300;
      color:white;
    }

    
    .bsLiveUserAccountArea{
      display: flex;
      flex-direction: column;
     // justify-content: center;
      align-items: center;
      width:100%;
      height: 55vh;
      background-color: white;
      border-radius: 4px;
      z-index: 20;
      animation: expand 1s ease-out forwards; // Smooth expand animation
    margin-top:1vh;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    z-index: 100;
    @include gadgets-to(tablet) {
      width:50%;
      box-shadow: 4px 4px 12px 1px #b1afab;
    }
    @include gadgets-to(laptop) {
      width:60%;
      box-shadow: 4px 4px 12px 1px #b1afab;

    }
    @include gadgets-to(desktop) {
      width:50%;
      box-shadow: 4px 4px 12px 1px #b1afab;
    }
    @include gadgets-to(largeDesktop) {
      width:50%;
      box-shadow: 4px 4px 12px 1px #b1afab;
    }
    }
    .bsLIveUserAccountHeader{
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width:100%;
      height: 3vh;
      font-size: 16px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      z-index:100;
      @include gadgets-to(laptop) {
        font-size: 18px;
      }
      @include gadgets-to(desktop) {
        font-size: 20px;
      }
      @include gadgets-to(largeDesktop) {
        font-size: 22px;
      }
    }

    .bsLiveTabUserContainer{
      width:100%;
      height:22vh;
      margin: auto;
      font-family: Arial, sans-serif;
      //position: relative;
      background-color:white;
      z-index: 101;

    }
    
    .bsliveUserTabs {
      display: flex;
      position: relative;
      z-index: 1;
      margin-bottom: -1px; /* For overlapping effect */
      font-size: 12px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      width:100%;
      z-index:100;
      @include gadgets-to(laptop) {
        font-size: 16px;
      }
      @include gadgets-to(desktop) {
        font-size: 18px;
      }
      @include gadgets-to(largeDesktop) {
        font-size: 20px;
      }
    }

    .bsliveUserTab {
      flex: 1;
      padding: 5px 10px;
      cursor: pointer;
      text-align: center;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      border-bottom: none;
      transition: background-color 0.3s;
      position: relative;
      top: 1px;
    }
    
    .bsliveUserTab:first-child {
      border-top-left-radius: 8px;
    }
    
    .bsliveUserTab:last-child {
      border-top-right-radius: 8px;
    }
    
    .bsliveUserTab.bsliveUserActive {
      background-color: #fff;
      border: 1px solid #ccc;
      border-bottom: none;
      font-weight: bold;
      color: #333;
      z-index: 2; /* Bring active tab to the front */
    }

    .bsLiveUserTabContent{
      border: 1px solid #ccc;
      padding:6px;
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      min-height: 100px;
      height:85vh;
      box-sizing: border-box;
      position: relative;
      top:2px; /* Moves content up to overlap with the tabs */
      width:100%;
      z-index: 100;
      @include gadgets-to(tablet) {
       box-shadow: 4px 4px 12px 1px #b1afab;
       height:75vh;
      }
      @include gadgets-to(laptop) {
        box-shadow: 4px 4px 12px 1px #b1afab;
        height:75vh;
      }
      @include gadgets-to(desktop) {
        box-shadow: 4px 4px 12px 1px #b1afab;
        height:75vh;
      }
      @include gadgets-to(largeDesktop) {
        box-shadow: 4px 4px 12px 1px #b1afab;
        height:75vh;
      }
    }
    .bsLiveUserAccountWrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      z-index:100;
    
    }

    .bsLiveUserAccountInfo{
      display: flex;
      flex-direction:column;
     // justify-content: center;
      align-items: center;
      width:99%;
      height:60vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
     border:1px solid map-get($generalStyles,datalistBorderColor);
     border-radius: 4px;
      box-shadow: 1px 1px 4px 1px #e6dfcf;
    }
    .bsLiveUserAccountInfoText{
      display: flex;
      flex-direction:row;
      justify-content:flex-start;
      align-items: center;
      width:100%;
      height: 3vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      margin-left:3vw;
      @include gadgets-to(laptop) {
        font-size: 18px;
      }
      @include gadgets-to(desktop) {
        font-size: 16px;
      }
      @include gadgets-to(largeDesktop) {
        font-size: 18px;
      }
    }
    .bsLiveUserAccountUnlockedRow
    {
      display: flex;
      flex-direction:row;
      justify-content:flex-start;
      align-items: center;
      width:100%;
      height: 3vh;

      @include gadgets-to(mobile) {
        height:4vh;
        margin-bottom:3px;
      }
    
      }
    .bsLiveUserAccountUnlocked{
      display: flex;
      flex-direction:column;
     // justify-content: center;
      align-items: center;
      width: 98%;
      height: 80vh;

    }
    // .bsLiveUserAccountUnlockedid{
    //   display: flex;
    //   flex-direction:row;
    //   justify-content: center;
    //   align-items: center;
    //   width:20%;
    //   height: 4vh;
    //   font-size: 12px;
    //   font-family: #{map-get($generalStyles, fontFamilyAnders)};
    //   margin-top:1vh;
    //   color:black;
    //   background-color: rgb(211, 214, 109);
    //   border-radius: 4px;
    // }
    // .bsLiveUserAccountUnlockedid1{
    //   display: flex;
    //   flex-direction:row;
    //   justify-content: center;
    //   align-items: center;
    //   width:20%;
    //   height: 4vh;
    //   font-size: 12px;
    //   font-family: #{map-get($generalStyles, fontFamilyAnders)};
    //   margin-top:1vh;
    //   color:black;
    //   background-color:rgb(223, 226, 119);
    //   border-radius:4px;
    // }

    .bsLiveUserAccountUnlockedText{
      margin-left:2px;
      border-radius: 4px;
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width:70%;
      height: 4vh;
      font-size: 11px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      background-color:map-get($generalStyles,modern_background-white);

    }
    // .bsLiveUserAccountUnlockedText1{
    //   margin-left:2px;
    //   border-radius: 4px;
    //   display: flex;
    //   flex-direction:row;
    //   justify-content: center;
    //   align-items: center;
    //   width:70%;
    //   height: 4vh;
    //   font-size: 11px;
    //   font-family: #{map-get($generalStyles, fontFamilyAnders)};
    //   margin-top:1vh;
    //   color:black;
    //   background-color: rgb(223, 226, 119);
    //   box-shadow: inset 0 0 10px rgb(129, 129, 115);
    // }
    
    .bsLiveUserAccountUnlockedBenaemn{
      margin-left:2px;
      border-radius: 4px;
      display: flex;
      flex-direction:row;
      justify-content:space-between;
      align-items: center;
      width:100%;
      height: 4vh;
      font-size: 11px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      background-color:map-get($generalStyles,modern_background-white);
      padding-left:1vw;
      // overflow: hidden;
      white-space: nowrap;
      // text-overflow: ellipsis;
      // line-height: 4vh;
      // display: inline-block;
      // vertical-align: middle;
      border:1px solid map-get($generalStyles,modern_border-light);
      // hover
      &:hover {
        background-color:map-get($generalStyles,modern_background-subtle);
    
      }
      @include gadgets-to(laptop){
        font-size: 16px;
      }
      @include gadgets-to(desktop){
        font-size: 16px;
      }
      @include gadgets-to(largeDesktop){
        font-size: 18px;
      }
    }
  .bsLiveAccountUnlockedText{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items: center;
    width:100%;
    height: 4vh;
    font-size: 11px;
    font-family: #{map-get($generalStyles, fontFamilyAnders)};
    color:black;
    margin-right:3vw;
    @include gadgets-to(laptop){
      font-size: 17px;
    }
    @include gadgets-to(desktop){
      font-size: 16px;
    }
    @include gadgets-to(largeDesktop){
      font-size: 18px;
    }
  }
  .bsLiveCalendarIcon{
    width: 12px;
    height: 12px;
    color:map-get($generalStyles,modern_iconGrey);
    margin-right:1vw;
    margin-bottom:1px;
    cursor: pointer;
    // hover
    &:hover {
      color: #5e97e0;
      animation: bounceRotate 3s ease-in-out infinite;
      animation-iteration-count: 2;
    }
    @include gadgets-to(tablet){
      width: 14px;
      height: 14px;
    }
    @include gadgets-to(laptop){
      width: 16px;
      height: 16px;
    }
    @include gadgets-to(desktop){
      width: 16px;
      height: 16px;
    }
    @include gadgets-to(largeDesktop){
      width: 18px;
      height: 18px;
    }
  }

    .bsLiveUserAccountHeaderWrapper{
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .bsLiveUserAccountUnlockedList{
      display: flex;
      flex-direction:column;
     // justify-content: center;
      align-items: center;
      width: 100%;
      height: 50vh;
      max-height: 50vh;
      overflow-y: auto;
      //webkit scrollbar
      &::-webkit-scrollbar {
        display:none;
      }

      @include gadgets-to(tablet) {
        height:70vh;
        max-height:unset;
      }
      @include gadgets-to(laptop) {
        height:70vh;
        max-height:unset;
      }
      @include gadgets-to(desktop) {
        height:70vh;
        max-height:unset;
      }
      @include gadgets-to(largeDesktop) {
        height:70vh;
        max-height:unset;
      }

  
     
 
    }

    .bsLiveUnlockedEnded{
      margin-left:2px;
      border-radius: 4px;
      display: flex;
      flex-direction:row;
      justify-content:center;
      align-items: center;
      width:50%;
      height: 3vh;
      font-size: 11px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
     // margin-top:1vh;
      color:red;
      background-color:white;
     // padding-left:1vw;
    
  
    }
    // .bsLiveUnlockedEnded1{
    //   margin-left:2px;
    //   border-radius: 4px;
    //   display: flex;
    //   flex-direction:row;
    //   justify-content:center;
    //   align-items: center;
    //   width:41%;
    //   height: 4vh;
    //   font-size: 11px;
    //   font-family: #{map-get($generalStyles, fontFamilyAnders)};
    //   margin-top:1vh;
    //   color:white;
    //   background-color:rgb(230, 10, 10);
    //   padding-left:1vw;
    //   box-shadow: inset 0 0 10px rgb(183, 189, 65);
    
    // }

    .bsLiveImageAreaWrapper{
      position:relative;
      display: flex;
      flex-direction: column;
     // justify-content: center;
      align-items: center;
      width: 100%;
      height:auto;
      background-color: white;
   //   border:1px solid map-get($generalStyles,datalistBorderColor);
      border-radius: 4px;
      z-index: 10;
      animation: expand 1s ease-out forwards; // Smooth expand animation
   // margin-top:1vh;
   @include gadgets-to(tablet) {
    height:48vh;
    width:35vw;
    @media(min-width:1100px){
      width:35vw;
    }
    }
    @include gadgets-to(laptop) {
      height:48vh;
      width:35vw;
      @media(min-width:1100px){
        width:35vw;
      }
      }
      @include gadgets-to(desktop) {
        height:48vh;
        width:31vw;
        @media(min-width:1100px){
          width:31vw;
        }
        }
        @include gadgets-to(largeDesktop) {
          height:48vh;
          width:35vw;
          @media(min-width:1100px){
            width:35vw;
          }
          }
  }

    .bsLiveImageArea{
      position: relative;
      display: flex;
      flex-direction: column;
     // justify-content: center;
      align-items: center;
      width:100%;
      height: 40vh;
      background-color: white;
    //  border:1px solid map-get($generalStyles,datalistBorderColor);
      border-radius: 4px;
      @include gadgets-to(tablet) {
       height:50vh;
      }
      @include gadgets-to(laptop) {
        height:50vh;
      }
      @include gadgets-to(desktop) {
        height:50vh;
      }
      @include gadgets-to(largeDesktop) {
        height:50vh;
      }
    }
    .bsLiveShowChosenImage{
        width:100%;
        height:40vh;
       // border-radius:4px;
@include gadgets-to(tablet) {
  height:45vh;
  @media(min-width:1100px)and (max-width:1180px){
    height:48vh;
    width:90%;
  }
}
@include gadgets-to(laptop) {
  height:45vh;
 }
 @include gadgets-to(desktop) {
  height:45vh;
  }
  @include gadgets-to(largeDesktop) {
    height:45vh;
    width:85%;
    }
    }

    .bsLiveRecipeWrapper{
      display: flex;
      flex-direction: column;
    //  justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vh;
      max-height:80vh;
      overflow-y:scroll;
      z-index:10;
      //webkit scrollbar
      &::-webkit-scrollbar {
      display:none
      }

      @include gadgets-to(tablet) {
        display: flex;
        flex-direction:row;
      //  justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        max-height:100vh;
        overflow-y:scroll;
        margin-top:1vh;
        //webkit scrollbar
        &::-webkit-scrollbar {
        display:none
        }
        @media(min-width:1100px){
          width:95%;
        }
  
    }
    @include gadgets-to(laptop) {
      display: flex;
      flex-direction:row;
    //  justify-content: center;
      align-items: center;
      width:95%;
      height: 100vh;
      max-height:100vh;
      overflow-y:scroll;
      margin-top:1vh;
      //webkit scrollbar
      &::-webkit-scrollbar {
      display:none
      }
  
  }
  @include gadgets-to(desktop) {
    display: flex;
    flex-direction:row;
  //  justify-content: center;
    align-items: center;
    width: 90%;
    height: 100vh;
    max-height:100vh;
    overflow-y:scroll;
    margin-top:1vh;
    //webkit scrollbar
    &::-webkit-scrollbar {
    display:none
    }
}
@include gadgets-to(largeDesktop) {
  display: flex;
  flex-direction:row;
//  justify-content: center;
  align-items: center;
  width:95%;
  height: 100vh;
  max-height:100vh;
  overflow-y:scroll;
  margin-top:1vh;
  //webkit scrollbar
  &::-webkit-scrollbar {
  display:none
  }
}
    }


    .bsLiveSocialAreaIconsWrapper{
      // display: flex;
      // flex-direction: row;
      // justify-content:space-evenly;
      // align-items: center;
      // width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width:96%; /* or a fixed width if needed */
        max-width: 100%; /* Ensure it doesn't exceed its container */
        overflow: hidden; /* Prevent content from spilling outside */
        box-sizing: border-box; /* Include padding and border in the width */
    }

    .bsLiveSocialHeartIcon{
     // flex:1;
      width: 13px;
      height: 13px;
    //  color:grey;
      stroke:grey;
      fill:none;
      margin-left:1vw;
      cursor: pointer;
      // hover
      &:hover {
        animation: heartBeat 3s infinite;
        animation-iteration-count: 2;

      }
      @include gadgets-to(mobile) {
        width: 13px;
        height: 13px;
        @media (min-width:400px) {
          width:17px;
          height:17px;
        }
      }
      @include gadgets-to(tablet) {
         width:18px;
          height:18px;
          @media(min-width:1181px)and (max-width:1400px){
            width: 22px;
            height: 22px;
          }
      }
      @include gadgets-to(laptop) {
        width: 22px;
        height: 22px;
      }
      @include gadgets-to(desktop) {
        width: 26px;
        height: 26px;
      }
      @include gadgets-to(largeDesktop) {
        width: 28px;
        height: 28px;
      }
    }
    .bsLiveSocialShareContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:70%;
      @include gadgets-to(tablet){
        @media(min-width:1181px)and (max-width:1400px){
          width:60%;
        }
      }
    }
    
    .bsLiveSocialShareIcon{
      // flex:1;
      // width: 20px;
      // height: 20px;
      //color: #2c79df;
      color:#4b5563;
      width:13px;
      height:13px;
    //  font-size: 0.175rem;

      margin-left:1vw;
      cursor: pointer;
      // hover
      &:hover {
        color: #5e97e0;
        animation: bounceRotate 3s ease-in-out infinite;
        animation-iteration-count: 2;
      }
      @include gadgets-to(tablet){

        @media(min-width:1181px)and (max-width:1400px){
          width: 15px;
          height: 15px;
          margin:0;
        }
      }
      @include gadgets-to(laptop) {
        width: 17px;
        height: 17px;
      }
      @include gadgets-to(desktop) {
        width: 22px;
        height: 22px;
      }
      @include gadgets-to(largeDesktop) {
        width: 22px;
        height: 22px;
      }
    }

    .bsLiveShareOnMedia{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 10vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:rgb(255, 238, 83);
      z-index: 50;
    }


// 360 anime
    @keyframes rotate360 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }







// bounce anime
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-10px);
      }
      60% {
        transform: translateY(-5px);
      }
    }

// heartbeat anime

    @keyframes heartBeat {
      0%, 20%, 40%, 60%, 80%, 100% {
        transform: scale(1);
      }
      10%, 30%, 50%, 70%, 90% {
        transform: scale(1.2);
      }
    }

    // bounce rotate anime
    @keyframes bounceRotate {
      0%, 20%, 50%, 80%, 100% {
        transform: rotate(0deg) translateY(0);
      }
      40% {
        transform: rotate(180deg) translateY(-10px);
      }
      60% {
        transform: rotate(270deg) translateY(-5px);
      }
      100% {
        transform: rotate(360deg) translateY(0);
      }
    }
    



    .socialArea {
      display: flex;
      gap: 10px;
      z-index:200;
      cursor:pointer;
    
      .likeIcon, .shareIcon {
        cursor: pointer;
        font-size: 1.5rem;
      }
    
      .likeIcon {
        color: red;
        font-size: 1.5rem;
        cursor: pointer;
        &:hover {
          color: darkred;
      
        }
      }
      .socialAreaShareLink{
        font-family: map-get($generalStyles,fontFamilyAnders);
        font-size:10px;

      }
    
      .shareIcon {
        &:hover {
          color: #007bff;
        }
      }
    
      .shareOptions {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 18px;
        padding:20px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        top:20%; // Adjust this for your layout
        right: 0;
        z-index: 60;
    
        a {
          color: #333;
          font-size: 1.8rem;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 5px;
    
          &:hover {
            color: #007bff;
          }
        }
    
        .closeIcon {
          align-self: flex-end;
          cursor: pointer;
          font-size: 1.2rem;
          color: #333;
          &:hover {
            color: #ff0000;
          }
        }
      }
    }
    
    .bsLiveLikeContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:60%;

    }
    .bsLiveLikeCount{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      font-weight: 300;
      height:3vh;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      //margin-top:1vh;
      color:map-get($generalStyles,colorStylishGrey);
   @include gadgets-to(mobile) {
      font-size: 11px;
      @media (min-width:400px) {
        font-size: 13px;
    }
  }
  @include gadgets-to(tablet) {
    font-size: 12px;
    @media(min-width:1181px)and (max-width:1400px){
      font-size: 16px;
    }
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 20px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 24px;
  }

}

    .bsLiveSeenContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:100%;

    }
    .bsLiveSeenCount{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      height:3vh;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      //margin-top:1vh;
      color:map-get($generalStyles,colorStylishGrey);
   @include gadgets-to(mobile) {
      font-size: 11px;
      @media (min-width:400px) {
        font-size: 13px;
    }
  }
  @include gadgets-to(tablet) {
    font-size: 12px;
    @media(min-width:1181px)and (max-width:1400px){
      font-size: 16px;
    }
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size: 20px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 24px;
  }
}
    .bsLiveSocialSeenIcon{
      width: 15px;
      height: 15px;
      color:grey;
      margin-left:1vw;
      cursor: pointer;
      // hover
      &:hover {
        animation: heartBeat 3s infinite;
        animation-iteration-count: 2;
  
      }
      @include gadgets-to(mobile) {
        width: 15px;
        height: 15px;
        @media (min-width:400px) {
          width: 18px;
          height: 18px;
      }
    }
    @include gadgets-to(tablet) {
      width: 18px;
      height: 18px;
      @media(min-width:1181px)and (max-width:1400px){
        width: 22px;
        height: 22px;
      }
    }
    @include gadgets-to(laptop) {
      width: 26px;
      height: 26px;
    }
    @include gadgets-to(desktop) {
      width: 28px;
      height: 28px;
    }
    @include gadgets-to(largeDesktop) {
      width: 32px;
      height: 32px;
    }
  }

    .bsLiveDiffContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:40%;

    }
    .bsLiveDiffCount{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height:3vh;
      font-size:10px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      //margin-top:1vh;
      color:map-get($generalStyles,colorStylishGrey);
    }
    .bsLiveDiffIcon{
      width: 15px;
      height: 15px;
      color:grey;
      margin-left:1vw;
      cursor: pointer;
      // hover
      &:hover {
        animation: heartBeat 3s infinite;
        animation-iteration-count: 2;
  
      }
    }

    .bsLiveTimeContainer{
      display: flex;
      flex-direction: row;
      justify-content:flex-start;
      align-items: center;
      width:90%;

    }
    .bsLiveTimeCount{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height:3vh;
      font-size:10px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      //margin-top:1vh;
      color:map-get($generalStyles,colorStylishGrey);
    @include gadgets-to(mobile) {
      font-size: 10px;
      @media (min-width:400px) {
        font-size: 13px;
    }
  }
  @include gadgets-to(tablet) {
    @media(min-width:1181px)and (max-width:1400px){
      font-size: 16px;
    }
  }
  @include gadgets-to(laptop) {
    font-size: 16px;
  }
  @include gadgets-to(desktop) {
    font-size:22px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 22px;
  }
}
    .bsLiveTimeIcon{
      width: 13px;
      height: 13px;
      color:grey;
      margin-left:1vw;
      cursor: pointer;
      // hover
      &:hover {
        animation: heartBeat 3s infinite;
        animation-iteration-count: 2;
  
      }
      @include gadgets-to(mobile) {
        width: 13px;
        height: 13px;
      }
      @media (min-width:400px) {
        width: 18px;
        height: 18px;
      }
      @include gadgets-to(tablet) {

        @media(min-width:1181px)and (max-width:1400px){
          width: 20px;
          height: 20px;
        }
      }
      @include gadgets-to(laptop) {
        width: 20px;
        height: 20px;
      }
      @include gadgets-to(desktop) {
        width: 24px;
        height: 24px;
      }
      @include gadgets-to(largeDesktop) {
        width: 30px;
        height: 30px;
      }
    }






    .bsChefHat {
      @include gadgets-to(mobile) {
      position: absolute;
      top: 5px;
      right: 10px;
      font-size:14px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
      padding:1px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      cursor: pointer; /* Change cursor to pointer */
   
      @media (min-width:400px) {
        top: 7px;
        right: 15px;
      }
  }
  @include gadgets-to(tablet) {
    position: absolute;
    top: 25px;
    right: 12px;
    font-size:12px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding:1px; /* Add space around the emoji */
   // z-index: 10; /* Ensure it stays on top */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
    @media (min-width:400px) {
      top: 7px;
      right: 15px;
    }
}
@include gadgets-to(laptop) {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size:20px; /* Emoji size */
  border: 2px solid white; /* White border for contrast */
  border-radius: 50%; /* Circular shape */
  padding:1px; /* Add space around the emoji */
  // z-index: 10; /* Ensure it stays on top */
  display: flex; /* Center the emoji */
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
  cursor: pointer; /* Change cursor to pointer */
}
@include gadgets-to(desktop) {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size:24px; /* Emoji size */
  border: 2px solid white; /* White border for contrast */
  border-radius: 50%; /* Circular shape */
  padding:1px; /* Add space around the emoji */
  // z-index: 10; /* Ensure it stays on top */
  display: flex; /* Center the emoji */
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
  cursor: pointer; /* Change cursor to pointer */
}
@include gadgets-to(largeDesktop) {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size:29px; /* Emoji size */
  border: 2px solid white; /* White border for contrast */
  border-radius: 50%; /* Circular shape */
  padding:4px; /* Add space around the emoji */
  // z-index: 10; /* Ensure it stays on top */
  display: flex; /* Center the emoji */
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
  cursor: pointer; /* Change cursor to pointer */
}

    }
    
    /* Background color variations */
    .chef-bg-green {
      background-color: rgba(139, 204, 79, 0.8); /* Green for easy difficulty */
  
    }
    
    .chef-bg-orange {
      background-color: rgba(255, 165, 0, 0.8); /* Orange for medium difficulty */
    }
    
    .chef-bg-red {
      background-color: rgba(255, 0, 0, 0.8); /* Red for hard difficulty */
    }
    
    .chef-default {
      background-color: rgba(128, 128, 128, 0.5); /* Gray for unknown difficulty */
    }
    



    .bsLiveBookmark {
      position: absolute;
      top: 8px;
      left: 25px;
      font-size:14px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
      padding:8px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      cursor: pointer; /* Change cursor to pointer */
    
    @include gadgets-to(mobile) {
      position: absolute;
      top: 5px;
      left: 10px;
      font-size:14px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
      padding:4px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      cursor: pointer; /* Change cursor to pointer */
      @media (min-width:400px) {
        top: 7px;
        left: 15px;
      }
    }
    @include gadgets-to(tablet) {
      position: absolute;
      top: 5px;
      left: 20px;
      font-size:14px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
      padding:4px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      cursor: pointer; /* Change cursor to pointer */
      @media (min-width:400px) {
        top: 7px;
        left: 15px;
      }
    }
    @include gadgets-to(laptop) {
      position: absolute;
      top: 5px;
      left: 10px;
     // font-size:44px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
      padding:4px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      cursor: pointer; /* Change cursor to pointer */
      @media (min-width:400px) {
        top: 7px;
        left: 15px;
      }
    
    }
    @include gadgets-to(desktop) {
      position: absolute;
      top: 5px;
      left: 10px;
     // font-size:44px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
      padding:4px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      cursor: pointer; /* Change cursor to pointer */
      @media (min-width:400px) {
        top: 7px;
        left: 15px;
      }
    
    }
    @include gadgets-to(largeDesktop) {
      position: absolute;
      top: 15px;
      left: 15px;
     // font-size:44px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
      padding:10px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      cursor: pointer; /* Change cursor to pointer */
      @media (min-width:400px) {
        top: 7px;
        left: 15px;
      }
    
    }
  }

    
    /* Background color variations */
    .mark-green {
      background-color: rgba(139, 204, 79, 0.8); /* Green for easy difficulty */
  
    }
    .bsLiveBookmarkIcon{
      width: 14px;
      height: 14px;
      fill:map-get($generalStyles,colorGold); 
      stroke:white;
    //  margin-left:1vw;
      cursor: pointer;
      @include gadgets-to(laptop) {
        width: 20px;
        height: 20px;
        fill:map-get($generalStyles,colorGold); 
        stroke:white;
      //  margin-left:1vw;
        cursor: pointer;

    }
    @include gadgets-to(desktop){
      width: 24px;
      height: 24px;
      fill:map-get($generalStyles,colorGold); 
      stroke:white;
    }
    @include gadgets-to(largeDesktop){
      width: 26px;
      height: 26px;
      fill:map-get($generalStyles,colorGold); 
      stroke:white;
    }
    }
  

.bsLiveChefImageWrapper{
  position:absolute;
  top:1%;
  left:1%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;;
  //align-items:flex-end;
  margin-left:2vw;
  width:92%;
  height:14vh;
  margin-top:5vh;

  @include gadgets-to(tablet) {
 //   position:absolute;
    top:1%;
    left:6%;
    width:90%;
    height:19vh;
    padding:0;
    margin:0;
    cursor: pointer;
    @media(min-width:800px){
      width:90%;
      height:16vh;
      margin-top:5vh;
    }
}
@include gadgets-to(laptop) {
  position:absolute;
  top:9%;
  left:6%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;;
  //align-items:flex-end;
  margin-left:2vw;
  width:90%;
  height:19vh;
  padding:0;
  margin:0;
  cursor: pointer;
}
@include gadgets-to(desktop) {
  position:absolute;
  top:12%;
  left:6%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;;
  //align-items:flex-end;
  margin-left:2vw;
  width:90%;
  height:19vh;
  padding:0;
  margin:0;
  cursor: pointer;
}
@include gadgets-to(largeDesktop) {
  position:absolute;
  top:23%;
  left:6%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;;
  //align-items:flex-end;
  margin-left:2vw;
  width:90%;
  height:19vh;
  padding:0;
  margin:0;
  cursor:pointer;

}
}


    .bsLiveChefImageLink{
      @include gadgets-to(mobile) {
      position: absolute;
      // top:90px;
      // left:9px;
      font-size:14px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
     // padding:1px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
     // flex-direction: column;
     margin-left:1vw;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      cursor: pointer; /* Change cursor to pointer */
      z-index:10;
    }
    @include gadgets-to(tablet) {
      position: absolute;
      // top:90px;
      // left:9px;
      font-size:14px; /* Emoji size */
      border: 2px solid white; /* White border for contrast */
      border-radius: 50%; /* Circular shape */
     // padding:1px; /* Add space around the emoji */
     // z-index: 10; /* Ensure it stays on top */
      display: flex; /* Center the emoji */
     // flex-direction: column;
   //  margin-left:1vw;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
      
      cursor: pointer; /* Change cursor to pointer */
      
      

    }
  }

    .bsLiveChefImage{
      width:30px;
      height:30px;
      border-radius: 50%;
      z-index:10;
      @include gadgets-to(laptop) {
        width:30px;
        height:30px;
        border-radius: 50%;
        border:2px solid white;
  }
  @include gadgets-to(desktop) {
    width:35px;
    height:35px;
    border-radius: 50%;
    border:2px solid white;
}
@include gadgets-to(largeDesktop) {
  width:45px;
  height:45px;
  border-radius: 50%;
  border:2px solid white;
}
    }

  
    
    /* Background color variations */
    .mark-green {
      background-color: rgba(139, 204, 79, 0.8); /* Green for easy difficulty */
  
    }

// .bsLiveAvailableLang{
//   @include gadgets-to(mobile) {
//   position: absolute;
//   top:108px;
//   right:12px;
//   width:30vw;
//   height:3vw;
//   font-size:14px;
//   border-radius:6px;
//   display: flex;
//   justify-content:flex-end;
//   align-items: center;
//   cursor: pointer;
//    padding:2px;
//   @media (min-width:400px) {
//     top:144px;
//   left:5px;
//   }
// }
// }

.bsLiveAvailableLangText{
  font-size: 11px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-weight: 300;
  color:black;
  cursor: pointer;
  @include gadgets-to(tablet){
    font-size: 12px;
    @media(min-width:1181px)and (max-width:1400px){
      font-size: 14px;
    }
  }
  @include gadgets-to(laptop){
    font-size: 16px;
  }
  @include gadgets-to(desktop){
    font-size: 20px;
  }
  @include gadgets-to(largeDesktop){
    font-size: 22px;
  }

}

.bsLiveAvailableLangIcon{
  width: 14px;
  height: 14px;
  fill:map-get($generalStyles,colorGold);
  stroke:white;
  margin-right:1vw;
  cursor: pointer;
  @include gadgets-to(mobile) {
    width: 14px;
    height: 14px;
    @media (min-width:400px) {
      width: 16px;
      height: 16px;
  }
}
@include gadgets-to(laptop) {
  width: 14px;
  height: 14px;
  fill:map-get($generalStyles,colorGold);
  stroke:white;
  margin-right:1vw;
  cursor: pointer;
}
@include gadgets-to(desktop) {
  width: 24px;
  height: 24px;
  fill:map-get($generalStyles,colorGold);
  stroke:white;
  margin-right:1vw;
  cursor: pointer;
}
@include gadgets-to(largeDesktop) {
  width:30px;
  height: 30px;
  fill:map-get($generalStyles,colorGold);
  stroke:white;
  margin-right:1vw;
  cursor: pointer;
}
}


.bsLiveTranslateLangAreaWrapper{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:94%;
  @include gadgets-to(mobile) {
    width:94%;
    @media (min-width:400px) {
      width:93%;
  }
}
@include gadgets-to(tablet) {
  width:94%;
  @media(min-width:834px)and (max-width:1180px){
    width:90%;
  }

}
@include gadgets-to(laptop) {
  width:94%;

}
@include gadgets-to(desktop) {
  width:94%;

}
@include gadgets-to(largeDesktop) {
  width:94%;

}
}
.bsRecipeLang{
display:flex;
flex-direction:row;
justify-content:flex-start;
align-items:center;
width:100%;

}
.bsLiveTranslateLang{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
  margin-left:7vw;
  @include gadgets-to(mobile) {
    margin-left:7vw;
    @media (min-width:400px) {
      margin-left:10vw;
  }
}
@include gadgets-to(tablet) {
margin:0;
justify-content:space-evenly;
}
}






    .bsLiveTimeWrapper{
      width:90%;
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:center;
      @include gadgets-to(mobile) {
        width:70%;
        @media (min-width:400px) {
          width:70%;
      }
    }

    }

    .bsLiveOtherContainerWrapper{
      width:99%;
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:center;

    }
    
    .bsLiveNutrientWrapper{
      display: flex;
      flex-direction: column;
     // justify-content: center;
      align-items: center;
      width: 100%;
      height:15vh;
  
    }
    .bsLiveNutrientHeaderWrapper{
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 3vh;
      font-size: 16px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      //margin-top:1vh;
      color:black;
    }
    .bsLiveNutrientHeaderNutrient{
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width:60%;
      height: 2.5vh;
     // border:1px solid grey;
      font-size: 11px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      border-radius: 4px;
      background-color:rgb(211, 214, 109)
    }

.bsLiveNutrientHeaderAmount{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:calc(20% - 2px);
  margin-left:2px;
  height: 2.5vh;
  //border:1px solid grey;
  font-size: 11px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
  border-radius: 4px;
  background-color:rgb(211, 214, 109)
}
.bsLiveNutrientHeaderUnit{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:calc(20% - 2px);
  margin-left:2px;
  height: 2.5vh;
  //border:1px solid grey;
  font-size: 12px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
  border-radius: 4px;
  background-color:rgb(211, 214, 109)
}






    .bsLiveNutrientName{
      display: flex;
      flex-direction:row;
      justify-content:flex-start;
      align-items: center;
      width:60%;
      height: 3vh;
      font-size: 12px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      white-space: nowrap;
      background-color:rgb(238, 208, 37);
      border-radius: 4px;
      padding-left:5px;
    }
    .bsLiveNutrientAmount{
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width:20%;
      height: 3vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      border-radius: 4px;
    }
    .bsLiveNutrientUnit{
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width:20%;
      height: 3vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      border-radius: 4px;
    }
    .bsLiveNutrientRow{
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 3vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:0.4vh;
      color:black;
    }

    .bsLiveNutrientPieChart{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vh;
      max-height:80vh;
      overflow-y:scroll;
      //webkit scrollbar
      &::-webkit-scrollbar {
      display:none
      }
  
    }

    .bsLivePieChartWrapper{
      display: flex;
      flex-direction: column;
   //   justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vh;
      max-height:80vh;
      overflow-y:scroll;
      //webkit scrollbar
      &::-webkit-scrollbar {
      width:5px;
      }
  
    }

    .bsLiveNutrientChangeValWrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:99%;
      height: 6vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
    }
    .bsLiveNutrientChangeVal{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 3vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      margin-bottom:5px;
    }
    .bsLiveNutrientChangeValPlus{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:40px;
      height:40px;
      border-radius: 50%;
    //  border:1px solid black;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      box-shadow: 4px 4px 4px 1px #e6dfcf;
      background-color:rgb(211, 214, 109);
      outline:none;
    }
    .bsLiveNutrientChangeValMinus{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:40px;
      height:40px;
      border-radius: 50%;
    //  border:1px solid black;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
      margin-left:5px;
      background-color: rgb(211, 214, 109);
      box-shadow: 4px 4px 4px 1px #e6dfcf;
      outline:none;
    }

    .bsLiveChangeIcon{
      width: 25px;
      height: 25px;
      fill:map-get($generalStyles,colorGold); 
      stroke:darkgrey;
    //  margin-left:1vw;
      cursor: pointer;
    }
.bsLiveUpdateButtonWrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width:30%;
}

    .bsLiveUpdateButton{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:90%;
      height: 4vh;
      border-radius: 4px;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
     // margin-top:1vh;
      color:white;
      background-color: map-get($generalStyles,pinkRed);
      box-shadow: 2px 2px 4px 1px #e6dfcf;
      margin-left:1px;
      // hover
      &:hover {
        background-color: #5e97e0;
        color: white;
        border:2px solid #5e97e0;
        font-size:18px;
      }
    }
    .bsLiveLockedRecipeWrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height:3vh;
  
    }
    .bsLiveRecipeSavedInfoText{
      display: flex;
      flex-direction:row;
      justify-content:center;
      align-items: center;
      width:100%;
      height: 2vh;
      font-size: 10px;
      font-weight:bold;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
     // margin-top:1vh;
      color:black;
    }
    .bsLiveRecipeSavedInfoText1{
      display: flex;
      flex-direction:row;
      justify-content:center;
      align-items:flex-end;
      width:100%;
      height: 5vh;
      font-size: 18px;
      font-weight:bold;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
    //  margin-top:1vh;
    //  margin-bottom:1.5vh;
      color:black;
      @include gadgets-to(tablet) {
       height:1vh;
       align-items:flex-start;
      }
      @include gadgets-to(laptop) {
        height:1vh;
        align-items:flex-start;
       }
       @include gadgets-to(desktop) {
        height:1vh;
        align-items:flex-start;
       }
       @include gadgets-to(largeDesktop) {
        font-size: 24px;
        height:1vh;
        align-items:flex-start;
       }
    }
    .bsLiveLockedRecipeInfoText{
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: center;
      width:100%;
    }
    .bsLiveLockedRecipe{
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      width:100%;

    }

    .bsLiveAddIngredientHeader{
      display: flex;
      flex-direction:column;
      justify-content:center;
      align-items: center;
      width:95%;
      height: 4vh;
      font-size: 13px;
      font-weight:400;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      color:black;
      background-color:rgb(211, 214, 109);
      box-shadow: 2px 2px 4px 1px #e6dfcf;
      border-radius: 4px;
      cursor:pointer;
      @include gadgets-to(tablet) {
         width:100%;
    }
    @include gadgets-to(laptop) {
      width:100%;
      font-size: 16px;
    }
    @include gadgets-to(desktop) {
      width:100%;
      font-size: 20px;
    }
    @include gadgets-to(largeDesktop) {
      width:100%;
      font-size:20px;
    }
    }
  
    // .bsLiveAddIngredientSelect{
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: center;
    //   align-items: center;
    //   width:100%;
    //   height:25px;
    //   font-size: 13px;
    //   font-weight:400;
    //   font-family: #{map-get($generalStyles, fontFamilyAnders)};
    // //  margin-top:1vh;
    //   color:black;
    //   //margin-right:3px;
    //   border:1px solid map-get($generalStyles,datalistBorderColor);
    //   border-radius: 4px;
    //   background-color: white;
    // }
    .bsLiveAddIngredientSaveButtonWrapper{
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      width:100%;
      height: 5vh;
  
    }

    .bsLiveAddIngredientSaveButton{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:86%;
      height: 4vh;
      border-radius: 4px;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:white;
      background-color: map-get($generalStyles,pinkRed);
      box-shadow: 2px 2px 4px 1px #e6dfcf;
      // hover
      &:hover {
        background-color: #5e97e0;
        color: white;
        border:2px solid #5e97e0;
        font-size:18px;
      }
    }
    .bsLiveAddIngredientSaveButton1{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:98%;
      height: 4vh;
      border-radius: 4px;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:white;
      background-color:green;
      box-shadow: 2px 2px 4px 1px #e6dfcf;
      //onclick
      &:active {
        background-color: #5e97e0;
        color: white;
        border:2px solid #5e97e0;
        font-size:18px;
      }
   
    }
    .bsLiveCheckBoxWrapper {
 display:flex;
  flex-direction:row;
  justify-content:space-evenly;
  width:90%;
    }
    .bsLiveCheckBox{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width:10%;
      height: 2vh;
      font-size: 14px;
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      margin-top:1vh;
      color:black;
    }
    .bsLiveRadioText{
      font-family: #{map-get($generalStyles, fontFamilyAnders)};
      font-size: 14px;
      @include gadgets-to(mobile) {
        font-size: 12px;
      }
      @include gadgets-to(desktop) {
        font-size: 20px;
      }
    }

.bsLiveTopRightMessage{
  position: absolute;
  top: 9.5%;
  right:3%;
  background-color:white;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  color: #fff; /* White text for contrast */
  padding: 5px 10px; /* Padding for readability */
  border-radius: 5px; /* Optional rounded corners */
  font-size: 14px; /* Adjust font size as needed */
  width:100px; /* Adjust width as needed */
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
  z-index: 10;
  @include gadgets-to(tablet){
  top:5.5%;
  right:2.6%;
}
@include gadgets-to(laptop){
  top:5.5%;
  right:2.6%;
}
@include gadgets-to(desktop){
  top:5.5%;
  right:2.6%;
}
@include gadgets-to(largeDesktop){
  top:5.5%;
  right:2.6%;
}
}

.bsLiveRecipeSavedUnlockText{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:100%;
  height: 2vh;
  font-size: 10px;
  font-weight:bold;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
 // margin-top:1vh;
  color:black;
}

.bsLiveGotoPaymentButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:90%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:white;
  background-color: #5e97e0;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  // hover
  &:hover {
    background-color: #5e97e0;
    color: white;
    border:2px solid #5e97e0;
    font-size:18px;
  }
}
.bsLiveNoCreditsText{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:100%;
  height: 2vh;
  font-size: 12px;
  font-weight:bold;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
 // margin-top:1vh;
  color:red;
}

.bsLiveCompletePageIcon{
  width: 35px;
  height: 35px;
  fill:map-get($generalStyles,colorGold); 
  stroke:green;
}


.bsLiveAddMoreCreditsButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:92%;
  height: 5vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:white;
 background-color:black;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  // hover
  // &:hover {
  //   background-color: #5e97e0;
  //   color: white;
  //   border:2px solid #5e97e0;
  //   font-size:18px;
  // }
  // animate to show button slowly
  animation: expand 1s ease-out forwards; // Smooth expand animation
@include gadgets-to(mobile) {
  @media (min-width:400px){
    height:38px;  
    color:white;

  }
}
  @include gadgets-to(tablet) {
 width:60%;
 height:35px;
 margin-top:unset;
 margin-top:5px;
}
@include gadgets-to(laptop) {
  width:80%;
  height:38px;
  margin-top:unset;
  margin-top:5px;

}
@include gadgets-to(desktop) {
  width:60%;
  height:40px;
  margin-top:unset;
  font-size: 16px;
  margin-top:5px;

}
@include gadgets-to(largeDesktop) {
  width:60%;
  height:38px;
  margin-top:unset;
  margin-top:5px;

}
}


.bsLiveStripeAccountSetupWrappper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:80%;
  height: 80vh;
  background-color: white;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
 // border-radius:20%;
  z-index: 20;
  animation: expandToFull 0.8s ease-in-out forwards; /* Trigger animation */

}
@keyframes expandToFull {
  0% {
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(0); /* Start small and centered */
    width: 50px;
    height: 50px;
  }
  100% {
    top: 0;
    left: 0;
    transform: translateX(0) scale(1); /* Full size */
    width: 100%;
    height: 100%;
  }
}
.bsLiveAccountSetupText{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:90%;
  height: 4vh;
  font-size:14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
}
.bsLiveAccountSetupIcon{
  width: 35px;
  height: 35px;
  fill:map-get($generalStyles,colorGold); 
  stroke:green;
}

.bsLiveAccountSetupIcon{
  width:30px;
  height:30px;
  color:green;
  }

  .bsLiveJoinBakesoftWrapper{
    display: flex;
    flex-direction: column;
   // justify-content: center;
    align-items: center;
    width:99%;
    height: 80vh;
    background-color: white;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    border-radius:8px;
    z-index: 20;
    animation: expandToFull 0.8s ease-in-out forwards; /* Trigger animation */
  }
  .bsLiveJoinBakesoftHeader{
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 4vh;
    font-size:12px;
    font-weight:bold;
    font-family: #{map-get($generalStyles, fontFamilyAnders)};
    margin-top:1vh;
    color:black;
  }

.bsLiveJoinBakesoftText{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:80%;
  height: 4vh;
  font-size:12px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
}

.bsliveJoinDiscountText{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:80%;
  height: 4vh;
  font-size:12px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
}




.bsLiveRecipeTextWrapper {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height:100vh;
  overflow-y:scroll;
  overflow-x:hidden;

}

.bsLiveRecipeTextInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 100vh;
  padding: 5px 10px;
  @include gadgets-to(tablet) {
    width:100%;
    max-height: 100vh;
    overflow-y: scroll;
 }
}
.bsLiveRecipeTextRow {
  display: flex;
  align-items: center; /* Align items horizontally */
  margin-bottom: 15px; /* Spacing between rows */
  width: 100%;
  position: relative; /* Needed for absolute positioning of the line */

  /* Dotted vertical line pseudo-element */
  &::before {
    content: "";
    position: absolute;
    top: -17px; /* Start slightly above the row */
    left: 4px; /* Center align the dots */
    width: 2px; /* Adjust thickness of the line */
    height: calc(25% + 15px); /* Extend the line below the row */
    background: repeating-linear-gradient(
      to bottom,
      lightgrey 0,
      lightgrey 2px,
      transparent 4px
    ); /* Creates the dotted effect with round dots */
    border-radius: 50%; /* Make the dots round */
    z-index: -1; /* Place behind the content */
  }

  &:first-of-type::before {
    content: none; /* Remove dots above the first row */
  }

  .big-dot {
    width: 10px;
    height: 10px;
    background: map-get($generalStyles, datalistBorderColor); /* Dot color */
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    margin-right: 12px;
    position: relative; /* Keep relative for hover effect */
    z-index: 1; /* Ensure dot appears above the dotted line */

    &:hover {
      transform: scale(1.2); /* Dot grows on hover */
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .content {
    font-size:14px;
    font-family: #{map-get($generalStyles, fontFamilyAnders)};
    font-weight: 300;
    line-height: 1.6; /* Improves readability */
    word-wrap: break-word; /* Ensure long words wrap */
    flex: 1; /* Allow text to take the remaining width */

    /* Blur effect for specific rows */
    &.blurred {
      filter: blur(4px); /* Apply blur effect */
      color: rgba(0, 0, 0, 0.5); /* Slightly dim the color */
    }
    @include gadgets-to(laptop) {
      font-size: 16px;
    }
    @include gadgets-to(desktop) {
      font-size: 20px;
    }
    @include gadgets-to(largeDesktop) {
      font-size: 20px;
    }
  }


}


/* Snowfall container */
.bsLivesnowfall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:10vh;
  overflow: hidden;
  pointer-events: none; /* Allow clicks through the snowfall */
  z-index: 10; /* Ensure snowfall appears on top */
}

/* Snowflake styling */
.bsLivesnowflake {
  position: absolute;
  top: -10px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  animation: bsLivesnowfall 5s linear infinite;
  opacity: 0.8;
}

/* Snowflake falling animation */
@keyframes bsLivesnowfall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  } 
}

.bsLiveCallbackArea{
  position:absolute;
  top:20%;
  left:1px;
  display: flex;
  flex-direction: column;
  width:100%;
  height:35vh;
  background-color: white;
  border-radius:40px;
  z-index: 20;
  // animation
  animation: expand 1s ease-out forwards; // Smooth expand animation
margin-top:1vh;
}

.bsLiveSearchButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:20%;
  height:27px;
  border-radius: 4px;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
 // margin-top:1vh;
  color:white;
  margin-left:3px;
  background-color:map-get($generalStyles,colorGold);
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  cursor: pointer;
  //z-index:1;
  // hover
  &:hover {
    background-color:map-get($generalStyles,colorGold);

  }
  &:active {
    background-color: #5e97e0;
  }
}

.bsLiveSearchInput{
  width: 100%;
  padding: 6px 40px 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
 // transition: all 0.3s ease;
 // margin-bottom: 3px;
  &:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
}

.bsLiveSearchIcon {
  color: white;
  width: 20px;
  height: 20px;
 // pointer-events: none;
//z-index:1;
}
.bsLiveUserReceiptRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:95%;
  height: 4vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  margin-top:3px;

}
.bsLiveUserReceipeText{
  display: flex;
  flex-direction:row;
  justify-content:space-around;
  align-items: center;
  width:100%;
  height: 4vh;
  font-size: 14px;
  font-weight:300;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
 // margin-top:1vh;
  color:black;
  @include gadgets-to(tablet) {
    font-size: 16px;
  }
  @include gadgets-to(laptop) {
    font-size: 18px;
  }
  @include gadgets-to(desktop) {
    font-size: 16px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 20px;
  }
}
.bsLiveReceiptIcon{
  width: 18px;
  height: 18px;
  fill:map-get($generalStyles,colorGold); 
  stroke:green;
  @include gadgets-to(laptop) {
    width: 22px;
    height: 22px;
  }
  @include gadgets-to(desktop) {
    width: 22px;
    height: 22px;
  }
  @include gadgets-to(largeDesktop) {
    width: 28px;
    height: 28px;
  }
}
.bsLiveUserReceipt{
  display: flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  width:100%;
  height:65vh;
  max-height:65vh;
  overflow-y:scroll;
  //webkit scrollbar
  &::-webkit-scrollbar {
  display:none
  }
 // margin-top:1vh;
}
.bsLiveRecipeLink{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:10%;
  height: 4vh;
}

.bsLiveUserNoData {
  text-align: center;
  color: #888;
  margin-top: 20px;
  font-style: italic;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size:16px;
  font-weight: 300;
}

.bsLivecloseShareIcon{
  width: 30px;
  height:30px;
  fill:map-get($generalStyles,colorGold); 
  stroke:white;
  cursor: pointer;
  // hover
  &:hover {
    color: #ff0000;
  }
}
.bsLiveTotalAmountFooterWrapper{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:98%;
  height: 5vh;
  @include gadgets-to(tablet) {
   width:91%;
   @media(min-width:1100px){
    width:100%;
   }
}
@include gadgets-to(laptop){
  width:100%;
}
@include gadgets-to(desktop){
  width:100%;
}
@include gadgets-to(largeDesktop){
  width:100%;
}
}

.bsLiveTotalAmountFooter{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:100%;
  height: 2.5vh;
  margin-top:0.5vh;
  margin-bottom:0.1vh;
  background-color: rgb(199, 202, 106);
  border-radius: 4px;
}

.bsLiveTotalAmountFooterText{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:41vw;
  height: 3vh;
  font-size: 12px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  @include gadgets-to(tablet) {
    font-size:16px;
  }
  @include gadgets-to(laptop) {
  font-size:18px;
  width:30vw;
  }
  @include gadgets-to(desktop) {
    font-size:18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:18px;
 
}
}
.bsLiveTotalAmountFooterTotalPrice{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:calc(15vw - 2px);
  margin-left:2px;
  height: 3vh;
  font-size: 12px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  @include gadgets-to(laptop) {
  font-size: 18px;
  width:25vw;
  }
  @include gadgets-to(desktop) {
    font-size:18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:18px;
 
}
}

.bsLiveTotalAmountFooterPrice{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:calc(12vw - 2px);
  margin-left:2px;
  height: 3vh;
  font-size:10px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  border-radius: 4px;
  @include gadgets-to(mobile) {

    @media (min-width:400px) {
      font-size:12px;
    }
  }
  @include gadgets-to(tablet) {
    font-size:16px;
  }
  @include gadgets-to(laptop) {
    font-size:16px;
    width:10vw;
  }
  @include gadgets-to(desktop) {
    font-size:18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:18px;

}
}


.bsLiveTotalAmountFooterQuantity{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:calc(15vw - 2px);
  margin-left:2px;
  height: 3vh;
  font-size: 10px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  border-radius: 4px;
  @include gadgets-to(mobile) {

    @media (min-width:400px) {
      font-size:12px;
    }
  }
  @include gadgets-to(tablet) {
    font-size:16px;
  }
  @include gadgets-to(laptop) {
    font-size:16px;
    width:15vw;
    align-items: center;
  }
  @include gadgets-to(desktop) {
    font-size:18px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:18px;
}
}

.bsLiveTotalAmountFooterKalories{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:calc(13vw - 2px);
  margin-left:2px;
  height: 3vh;
  font-size: 10px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  @include gadgets-to(mobile) {

    @media (min-width:400px) {
      font-size:12px;
    }
  }
  @include gadgets-to(tablet) {
    font-size:16px;
  }
  @include gadgets-to(laptop) {
    font-size:16px;
  }
  @include gadgets-to(desktop) {
    font-size:16px;
  }
  @include gadgets-to(largeDesktop) {
    font-size:18px;
}
}

.bsLiveNutrientChartArea{
  display:flex;
  flex-direction:row;
  justify-content: center;
 // align-items: center;
  position: absolute;
  top: 350px;
  right: 10px;
  background-color:white;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  color: #fff; /* White text for contrast */
 // padding: 5px 10px; /* Padding for readability */
  border-radius: 5px; /* Optional rounded corners */
  font-size: 14px; /* Adjust font size as needed */
  width:70vw; /* Adjust width as needed */
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
  height:15vh;
  zoom: 0.7;

  @include gadgets-to(mobile) {
    top: 350px;
    right: 10px;

    @media (min-width:400px) {
      top:468px;
      right: 15px;
    }

  }

}
.bsLiveNutrientChartTopNutrients{
  display: flex;
  flex-direction:column;
  justify-content: center;
 // align-items: center;
  width:60%;
  height: 15vh;
  white-space: nowrap;
  background-color: white;
}
.bsLiveNutrientTopNutrient{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:100%;

}
.bsLiveNutrientChartTopNutrientName{
  display: flex;
  flex-direction:row;
 // justify-content: center;
  align-items: center;
  width:100%;
  height: 2vh;
  font-size: 12px;
  font-weight: 200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  margin-left:2vw;

  @include gadgets-to(mobile) {
    font-size: 12px;
    margin-left:1vw;
    @media (min-width:400px) {
      font-size: 16px;
      margin-left:2vw;
    }
  }
}

.bsLiveCloseNutrientChart{
width:20px;
height:20px;
stroke:black;
color:black;
}

.bsLiveIngredientChangeNameWrapper{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
  width:99%;
  margin-top:3vh;
  height:4vh;

}
.bsLiveIngredientChangeName{
  display: flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items: center;
  width:100%;
  height:4vh;
  font-size: 12px;
  font-weight: 200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  white-space: nowrap;
  cursor: pointer;

}
.bsLiveIngredientChangeInput{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:75%;
  height: 3.7vh;
  font-size: 12px;
  font-weight: 200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  background-color:white;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
}
// .bsLiveUserLocallySavedRecipeWrapper{
//   display: flex;
//   flex-direction: column;
//  // justify-content: center;
//   align-items: center;
//   width:100%;
//   height: 80vh;

// }
.bsLiveUserSavedRecipesWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;


}
.bsLiveUserSavedRecipeRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:95%;
  height:auto;
 // min-height:4vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  margin-top:3px;
  @include gadgets-to(tablet) {
    width:80%;
  }
  @include gadgets-to(laptop) {
    width:80%;
  }
  @include gadgets-to(desktop) {
    width:80%;
  }
  @include gadgets-to(largeDesktop) {
    width:60%;
  }

}
.bsLiveUserLocallySavedRecipeText{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:50%;
  height: 3vh;
  font-size: 12px;
  font-weight:bold;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
}
.bsLiveUserLocallySavedRecipeTextId{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:20%;
  height: 3vh;
  font-size: 12px;
  font-weight:bold;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  white-space: nowrap;
  //ellipsis
  overflow: hidden;
  text-overflow: ellipsis;

}
.bsLiveDeleteIngredientButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:20%;
  height:4vh;
  border-radius: 4px;
  font-size: 14px;
  font-weight:300;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
 // margin-top:1vh;
  color:white;
  margin-left:1px;
  background-color:map-get($generalStyles,pinkRed);
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  cursor: pointer;
  //z-index:1;
  // hover
  &:hover {
    background-color:red;

  }
  &:active {
    background-color: #5e97e0;
  }
}

.bsLiveUserLocallySavedDeleteButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:30%;
  height:27px;
  border-radius: 4px;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
 // margin-top:1vh;
  color:white;
  margin-left:1px;
  background-color:red;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  cursor: pointer;
  //z-index:1;
  // hover
  &:hover {
    background-color:red;

  }
  &:active {
    background-color: #5e97e0;
  }
}




.bsLiveIngredInfo {
  display: flex;
  flex-direction: column;
 // justify-content: center;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease, opacity 1.5s ease;
  padding: 0 1px;
  opacity: 0;
  width:100%;
}

.bsLiveIngredInfo.expanded {
  max-height:180px; /* Adjust based on your content height */
  opacity: 1;
  @include gadgets-to(mobile) {
    max-height:180px;
    @media (min-width:400px) {
      max-height:120px;
    }
  }
}

.bsLiveIngredInfo.collapsed {
  max-height: 0;
  opacity: 0;
}

.bsLiveAddIngredientWrapper{
  display: flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items: center;
  width:90%;
  height:20vh;
  white-space: nowrap;
  background-color:white;
  margin-top:0.5vh;
  @include gadgets-to(mobile) {
    height:20vh;
    @media (min-width:400px) {
      height:auto;
    }
  }
  @include gadgets-to(tablet) {
width:91%;
@media (min-width:1100px) {
  width:100%;
  }

}
}

.bsLiveAddIngredientBox{
  display: flex;
  flex-direction:column;
   justify-content: center;
  align-items: center;
  width:94%;
  height: 15vh;
  font-size: 12px;
  font-weight: 200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  background-color:white;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-top:0.5vh;
}

.bsLiveAddIngredientInput{
  display: flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  width:65%;
  height: 3.7vh;
  font-size: 12;//px;
  font-weight: 200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  background-color:white;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-top:1vh;
}

.bsLiveAddIngredientButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:66%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:white;
  background-color: map-get($generalStyles,pinkRed);
  box-shadow: 4px 4px 4px 1px #e6dfcf;
  cursor: pointer;
  // hover
  &:hover {
    background-color: #5e97e0;
    color: white;
    border:2px solid #5e97e0;
    font-size:18px;
  }
}

.bsLiveSignupCloseIconWrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width:100%;
  height: 4vh;
  margin-top:0.1vh;
  margin-bottom:1vh;
  
  z-index:1;
}
.bsLiveCloseSignupIcon{
  width: 20px;
  height:20px;
  fill:white;
  //stroke:red;
  cursor: pointer;
  z-index: 1;
  padding:10px;
  @include gadgets-to(laptop) {
    width: 30px;
    height: 30px;
    margin:1vw;
  }

}
.bsLiveSignupDefaultWrapper{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height:20vh;
}
.bsLiveSignupDefaultRow{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 4vh;
  font-size: 12px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;

}
.bsLiveTabContainerSignWrapper{

  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
@include gadgets-to(tablet){
 background:unset;
  background:transparent;
  position:fixed;
  top:12%;
  left:0%;
  z-index:9999;

}
@include gadgets-to(laptop){
  background:unset;
  background:transparent;
  position:fixed;
  top:12%;
  left:0%;
  z-index:9999;
}
@include gadgets-to(desktop){
  background:unset;
  background:transparent;
  position:fixed;
  top:12%;
  left:0%;
  z-index:9999;
}
@include gadgets-to(largeDesktop){
  background:unset;
  background:transparent;
  position:fixed;
  top:12%;
  left:0%;
  z-index:9999;
}
}
.bsLiveTabContainerSign{
  background-image: url('../assets/images/busychefsmall.jpg');
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */

  position:absolute;
  top:9%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  width:100%;
  height:92vh;
  margin: auto;
  font-family: Arial, sans-serif;
  //position: relative;
  background-color:white;
  margin-top:0.5vh;
  z-index:20;
  @include gadgets-to(tablet){
    width:45%;
    box-shadow: 2px 2px 4px 1px #e6dfcf;
    background-color:white;
    position:unset;
    height:75vh;
    border-radius: 8px;
  }
  @include gadgets-to(laptop){
    width:40%;
    box-shadow: 4px 4px 8px 2px #918e89;
    background-color:white;
    position:unset;
    height:70vh;
    border-radius: 8px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
  }
  @include gadgets-to(desktop){
    width:35%;
    box-shadow: 4px 4px 8px 2px #918e89;
    background-color:white;
    position:unset;
    height:70vh;
    border-radius: 8px;
  }
  @include gadgets-to(largeDesktop){
    width:30%;
    box-shadow: 4px 4px 8px 2px #918e89;
    background-color:white;
    position:unset;
    height:70vh;
    border-radius: 8px;
  }
}


.bsLiveTabContentSign{
 // border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
   //justify-content: center;
  align-items: center;
  // padding: 10px;
  // background-color: #fff;
  // border-radius: 0 0 8px 8px;
 // min-height: 100px;
  height:90vh;
  // box-sizing: border-box;
  // position: relative;
 // top: -5px; /* Moves content up to overlap with the tabs */
  // animation: flipIn 3s ease-out forwards;
  // opacity: 0; /* Hidden initially */
  // transform-origin: center;
  width:100%;
}

.bsliveTabSign {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:95%;
  height:7vh;
  cursor: pointer;
  text-align: center;
 // background-color:#{map-get($generalStyles,colorGold)};
 // transition: background-color 0.3s;
 // border:1px solid map-get($generalStyles,datalistBorderColor);
  @include gadgets-to(mobile) {
    @media(max-width:370px){
      height:10.5vh;
    }
    @media(min-width:371px){
      height:7.5vh;
    }
    

  }
  @include gadgets-to(tablet) {
    @media(max-width:1025px){
      height:11.5vh;
    }
    height:10vh;
  }
  @include gadgets-to(laptop) {
    height:10vh;
  }
  @include gadgets-to(desktop) {
    height:10vh;
  }
  @include gadgets-to(largeDesktop) {
    height:10vh;
  }
}
.bsliveSignHeader{
  font-family:#{map-get($generalStyles, fontFamilyAnders)};
  font-size: 14px;
  font-weight:400;
  color:white;
  @include gadgets-to(mobile) {
    font-size: 14px;
  }
  @include gadgets-to(tablet) {
    font-size: 16px;
  }
  @include gadgets-to(laptop) {
    font-size: 17px;
  }
  @include gadgets-to(desktop) {
    font-size: 20px;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 20px;
  }
}


.bsliveTabSign .bsliveActiveSign {
//  background-color:map-get($generalStyles,colorGold);
 // border: 1px solid #ccc;
  border-bottom: none;
  font-weight:400;
  font-size: 18px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:white;
  z-index: 2; /* Bring active tab to the front */
  @include gadgets-to(mobile) {
    font-family:#{map-get($generalStyles, fontFamilyAnders)};
    font-size: 20px;
    font-weight:500;
    color:white;
  }
  @include gadgets-to(tablet) {
    font-family:#{map-get($generalStyles, fontFamilyAnders)};
    font-size: 24px;
    font-weight:500;
  }
  @include gadgets-to(laptop) {
    font-family:#{map-get($generalStyles, fontFamilyAnders)};
    font-size: 26px;
    font-weight:400;
  }
  @include gadgets-to(desktop) {
    font-family:#{map-get($generalStyles, fontFamilyAnders)};
    font-size: 28px;
    font-weight:400;
  }
  @include gadgets-to(largeDesktop) {
    font-family:#{map-get($generalStyles, fontFamilyAnders)};
    font-size: 30px;
    font-weight:400;
  }
}

.bsLiveSignupDefaultWrapper2{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
 // height:20vh;
 margin-top:1vh;
 
}
.bsLiveSignupDefaultRowSign{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 6vh;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:white;
  font-weight:bold;
  margin-bottom:1vh;
  @include gadgets-to(tablet) {
    font-size: 16px;
  }
  @include gadgets-to(laptop) {
    font-size: 18px;
  }
  @include gadgets-to(desktop) {
    font-size: 16px;
  }

}

.bsLivePaymentMessageSign{
  display: flex;
  flex-direction:column;
  justify-content:flex-start;
 // align-items:center;
  width:95%;
 // height: 10vh;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
 // margin-left:4vw;
  color:black;
}


@keyframes flipIn {
  0% {
    transform: rotateY(90deg);
    opacity: 0;
  }
  50% {
    transform: rotateY(-10deg);
    opacity: 0.5;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

.bsLivePaymentContactSign{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:80%;
  height:32px;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
//  box-shadow: 1px 1px 4px 1px #e6dfcf;
@include gadgets-to(tablet) {
  height:40px;
  }
@include gadgets-to(laptop) {
  height:40px;
  }
@include gadgets-to(desktop) {
  height:45px;
  }
@include gadgets-to(largeDesktop) {
height:40px;
}
}
.bsLivePaymentSelectCurrencySign{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:40%;
  height:30px;
  font-size: 14px;
  border-radius: 4px;
  outline:none;
  background-color: white;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:black;
//  box-shadow: 1px 1px 4px 1px #e6dfcf;
}

.bsLiveTranslateMenuWrapper{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:9vh;
  @include gadgets-to(tablet) {
    height:5vh;
  }
  @include gadgets-to(laptop) {
    height:5vh;
  }
  @include gadgets-to(desktop) {
    height:5vh;
  }
  @include gadgets-to(largeDesktop) {
    height:5vh;
  }
}
.bsLiveTranslateMenuText{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 6vh;
  font-size: 14px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  margin-top:0.5vh;
  @include gadgets-to(tablet) {
    font-size: 16px;
    height:2vh;
  }
  @include gadgets-to(laptop) {
    font-size: 18px;
    height:2vh;
  }
  @include gadgets-to(desktop) {
    font-size: 16px;
    height:2vh;
  }
  @include gadgets-to(largeDesktop) {
    font-size: 20px;
    height:2vh;
  }
}
.bsLiveTranslationInfoText{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 4vh;
  font-size: 11px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  margin-top:0.5vh;
 // margin-bottom:0.5vh;
}
.bsLiveTranslateSelect{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:70%;
  height: 4vh;
  font-size: 12px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  background-color:white;
 // box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-top:0.5vh;
  @include gadgets-to(tablet) {
    height:3vh;
    width:20%;
    margin-top:unset;
    margin-right:3px;
}
@include gadgets-to(laptop) {
  height:3vh;
  width:20%;
  margin-top:unset;
  margin-right:3px;
}
@include gadgets-to(desktop) {
  height:3vh;
  width:20%;
  margin-top:unset;
  margin-right:3px;
}
@include gadgets-to(largeDesktop) {
  height:3vh;
  width:20%;
  margin-top:unset;
  margin-right:3px;
}
}


.bsLiveTranslateButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:70%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top:1vh;
  color:white;
  background-color: map-get($generalStyles,babyBlue);
 // box-shadow: 2px 2px 4px 1px #e6dfcf;
  // hover
  cursor:pointer;
  &:hover {
    background-color: #5e97e0;

  }
  @include gadgets-to(tablet) {
    height:3vh;
    width:20%;
    margin-top:unset;
}
@include gadgets-to(laptop) {
  height:3vh;
  width:20%;
  margin-top:unset;
}
@include gadgets-to(desktop) {
  height:3vh;
  width:20%;
  margin-top:unset;
}
@include gadgets-to(largeDesktop) {
  height:3vh;
  width:20%;
  margin-top:unset;
}
}

.bsLiveTranslateArea{
  display: flex;
  flex-direction:column;
 // justify-content: center;
  align-items: center;
  width:100%;
  height:10vh;
  background-color: white;
  //border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:8px;
  @include gadgets-to(tablet) {
    height:5vh;
    flex-direction:row;
    justify-content: center;
    align-items: center;
  }
  @include gadgets-to(laptop) {
    height:5vh;
    flex-direction:row;
    justify-content: center;
    align-items: center;
  }
  @include gadgets-to(desktop) {
    height:5vh;
    flex-direction:row;
    justify-content: center;
    align-items: center;
  }
  @include gadgets-to(largeDesktop) {
    height:5vh;
    flex-direction:row;
    justify-content: center;
    align-items: center;
  }
}

.bsLiveTranslateIcon{
  width: 30px;
  height:30px;
  fill:map-get($generalStyles,colorGold); 
  stroke:white;
  
}

.bsLiveLanguageSelectorWrapper{
  position: absolute;
  top:30%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  z-index:1900;

}
.bsLiveTermsWrapper{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  margin-top:3vh;
  background: transparent;
}
.bsLiveUserAccountChangeLangWrapper{
  display: flex;
  flex-direction:column;
  //justify-content: center;
  align-items: center;
  width:95%;
  height: 15vh;
  margin-top:3vh;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius:4px;
  @include gadgets-to(tablet) {
    width:80%;
  }
  @include gadgets-to(laptop) {
    width:80%;
  }
  @include gadgets-to(desktop) {
    width:80%;
  }
  @include gadgets-to(largeDesktop) {
    width:60%;
  }
}
.bsLiveUserAccountChangeLangHeader{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 4vh;
  font-size: 14px;
  font-weight:bold;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
}
.bsLiveUserAccountChangeLangSelect{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width:70%;
  height: 4vh;
  font-size: 12px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  border:1px solid map-get($generalStyles,datalistBorderColor);
  border-radius: 4px;
  background-color:white;
//  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-top:0.5vh;
  @include gadgets-to(largeDesktop) {
    height:3vh;
}
}

.bsLiveUserAccountChangeLangSubmitButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 69.5%;
  height: 3vh;
  border-radius: 4px;
  font-size: 12px;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  margin-top: 0.5vh;
  color: white;
  background-color: map-get($generalStyles, babyBlue);

  &:hover {
    background-color: #5e97e0;
    color: white;
    border: 2px solid #5e97e0;
    font-size: 14px;
  }

  &.disabled {
    background-color: #839ec0;
    color: white;
    border: 2px solid #839ec0;
    font-size: 12px;
    pointer-events: none; /* Optional: Disable interactions */
    @include gadgets-to(tablet) {
      font-size: 14px;
    }
    @include gadgets-to(laptop) {
      font-size: 16px;
    }
    @include gadgets-to(desktop) {
      font-size: 16px;
    }
    @include gadgets-to(largeDesktop) {
      font-size: 18px;
    }

  
  }
}
.bsLiveMenuWrapper{
  display: flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items: center;
  width:100%;
  height:100vh;
  background-color: white;

}
.bsLiveMenuItems{
  display: flex;
  flex-direction:column;
  //justify-content: center;
  align-items:flex-start;
  width:100%;
  height: 60vh;
  font-size: 14px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  margin-top:0.5vh;
}
.bsLiveMenuItem{
  display: flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
  width:100%;
  height: 6vh;
  font-size: 14px;
  font-weight:400;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  margin-top:0.5vh;
}
.bsLIveMenuIcon{
  width: 34px;
  height:34px;
  fill:map-get($generalStyles,colorGold); 
  stroke:black;
  cursor: pointer;
  margin-right:1vw;
  @include gadgets-to(largeDesktop) {
    width: 40px;
    height: 40px;
  }
}
.bsLiveChristmasTree{
  position: absolute;
  top:2%;
  right:2%;
  align-items: center;
  justify-content: center;
  min-height:7vh;
  background-color: transparent;

  @include gadgets-to(tablet) {
    top:2%;
    right:2%;
    @media (min-width:1181px) {
      top:1.5%;
      right:2%;
    }
  }

}

.bsLiveCoockingTimerWrapper{

    position: fixed; /* Or absolute if relative to a specific parent */
    top: 50%; /* Vertically center */
    left: 50%; /* Horizontally center */
    transform: translate(-50%, -50%); /* Adjust to truly center the component */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   // width:40vw; /* Set the appropriate width for your design */
  //  height:80vh; /* Set the appropriate height for your design */
    z-index: 9999; /* Ensure it's above other elements */
    background-color: rgba(255, 255, 255, 0.9); /* Optional: Add a semi-transparent background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow for emphasis */
    border-radius: 8px; /* Optional: Add rounded corners */
  }

  .bsLiveUserAccountBSignupWrapper{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width:100%;
    height:20vh;
  }

  .bsLiveSignupButton{
    width:70%;
    background-color: #ff9800;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow:8px 8px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top:2vh;
    &:hover {
      background-color: #f57c00;
    }
  }
  .bsLiveChartWrapper{
    display: flex;
    flex-direction:column;
   // justify-content: center;
    align-items: center;
    width:100%;
    height:35vh;
    @include gadgets-to(laptop) {
      width:100%;
      height:40vh;
  }
}

  .bsLiveToggleListButtonWrapper{
    display: flex;
    flex-direction:column;
    justify-content:center;
    //align-items:center;
    width:100%;
    height:3vh;
    margin:0;
    padding:0;
  }
.bsLiveToggleIngredListIcon{
  font-size:30px;
  fill:white;
  stroke:map-get($generalStyles,babyGreen);
  cursor: pointer;
 // margin-bottom:0.3vh;

}
.bsLiveCoockieWarningWrapper{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:20vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-top:0.5vh;
}

.bsLiveTabInputContentWrapper{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 39vh;
  @include gadgets-to(mobile) {
    @media(max-width:370px) {
      height:50vh;
    }
    @media(min-width:400px) {
      height:45vh;
    }
    height:30vh;
  }
}

.bsLiveLoginForgotPasswordWrapper{
  display: flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items: center;
  width:100%;
}
.bsLiveLoginForgotPasswordText{
  display: flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items: center;
  width:100%;
  height: 2vh;
  font-size: 12px;
  font-weight:200;
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  color:black;
  cursor: pointer;
  margin-right:1vw;
  margin-top:5px;

  @include gadgets-to(mobile) {
    margin-right:1.7vw;
    @media (min-width:400px) {
      margin-right:4.5vw;
    }
  }
  @include gadgets-to(tablet) {
    margin-right:0.3vw;
  }
  @include gadgets-to(laptop) {
    margin-right:3.4vw;
  }
  @include gadgets-to(desktop) {
    margin-right:3.4vw;
  }
  @include gadgets-to(largeDesktop) {
    margin-right:3.1vw;
  }
}