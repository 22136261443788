.live-icon {
    position: relative;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    svg {
      position: absolute;
      width: 100%;
      height: 100%;
  
      circle {
        fill: none;
        stroke: #8B9B3E;
        stroke-width: 4;
      }
    }
  
    span {
      position: relative;
      color: #8B9B3E;
      font-weight: 600;
      font-size: 9px;
    }
  }
  
  
  