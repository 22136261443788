@import '../style/MainStyles.scss';

.nutrient-pie-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  
    h1 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 10px;
      text-align: center;
      @include gadgets-to(laptop){
        font-size: 2rem;
      }
    }
  
    .recharts-wrapper {
      max-width: 100%;
      display: flex;
      justify-content: center;
  
      svg {
        max-width: 100%;
        height: auto;
      }
    }
  
    .recharts-legend-wrapper {
      margin-top: 10px;
      font-size: 0.9rem;
      text-align: center;
      @include gadgets-to(laptop){
        font-size: 1.1rem;
      }
  
      .recharts-legend-item {
        display: flex;
        align-items: center;
        margin: 0 5px;
        @include gadgets-to(laptop){
          margin: 0 2px;
        }
  
        .recharts-surface {
          margin-right: 5px;
        }
  
        span {
          font-size: 0.8rem;
          color: #555;
          @include gadgets-to(laptop){
            font-size: 1rem;
          }
        }
      }
    }
  }
  