@import '../style/MainStyles.scss';
.bakesoft-signup {
position:absolute;
top:0;
left:0;
    margin:0;
    padding:0;
    min-height:100vh;
    width:100%;
//background: linear-gradient(to bottom right, #fce4ec, #e1bee7);
 //background: linear-gradient(to bottom right, gold, white);
// background: linear-gradient(to bottom right, rgba(252, 228, 236, 0.3), rgba(225, 190, 231, 0.3));
 background:transparent;

 //padding-left:2px;
    font-family: 'Arial', sans-serif;
    z-index:1800;
    background-color:rgba(255,255,255,1);
  
    header {
      text-align: center;
      margin-bottom: 2rem;
  
      h1 {
        font-size: 2.5rem;
        color: #4a148c;
        margin-bottom: 0.5rem;
      }
  
      p {
        font-size: 1.2rem;
        color: #6a1b9a;
      }
@include gadgets-to(mobile) {
    width:100%;
}
@include gadgets-to(tablet) {
    padding-left:unset;
}


    }
  
    .main-content {
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include gadgets-to(mobile) {
    width:95%;
        }
        @include gadgets-to(tablet) {
    width:100%;
        }
        @include gadgets-to(laptop) {
    width:100%;
        }
        @include gadgets-to(desktop) {
    width:100%;
        }
        @include gadgets-to(largeDesktop) {
    width:100%;
        }

    }
  
    .discover-recipes, .recipe-creators {
      background-color: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(10px);
      border-radius: 10px;
      padding: 2rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
      h2 {
        font-size: 1.8rem;
        color: #4a148c;
        margin-bottom: 1rem;
        text-align: center;
      }
  
      > p {
        text-align: center;
        margin-bottom: 1.5rem;
        color: #6a1b9a;
      }
    }
  
    .features-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 1.5rem;
      margin-bottom: 1.5rem;
    }
  
    .feature-card {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.5);
  
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
      .icon {
        width: 24px;
        height: 24px;
      }
  
      h3 {
        font-size: 1.1rem;
        color: #4a148c;
        margin-bottom: 0.5rem;
      }
  
      p {
        font-size: 0.9rem;
        color: #6a1b9a;
      }
    }
  
    .cta-button {
      display: block;
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
      padding: 0.75rem 1.5rem;
      background-color: #6a1b9a;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #4a148c;
      }
    }
  
    .recipe-creators {
      ul {
        list-style-type: disc;
        padding-left: 1.5rem;
        margin-bottom: 1.5rem;
        color: #6a1b9a;
      }
    }
  
    footer {
      text-align: center;
      margin-top: 2rem;
      color: #6a1b9a;
    }
  }
  
  @media (max-width: 768px) {
    .bakesoft-signup {
    //  padding: 1rem;
  
      header {
        h1 {
          font-size: 2rem;
        }
  
        p {
          font-size: 1rem;
        }
      }
  
      .discover-recipes, .recipe-creators {
        padding: 1.5rem;
      }
  
      .features-grid {
        grid-template-columns: 1fr;
      }
    }
  }

  .bakesoftSignupCloseIcon {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items:center;
//     // top: 20px;;
//     // right: 20px;
 padding: 1rem;
//     cursor: pointer;

  }
  
  