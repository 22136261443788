@import './MainStyles.scss';

.payment-success-container {
    display: flex;
    justify-content: center;
  //  align-items: center;
    min-height:70vh;
     background-color:transparent;
     margin-top:12vh;
    margin-bottom:20vh;
  }
  
  .payment-success-card {
    width: 100%;
    max-width: 400px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
    padding:1rem;
    text-align: center;
    @include gadgets-to(tablet){
       width:490px;
       max-width:unset;
       height:50vh;
    }
  }
  
  .payment-success-icon-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .payment-success-check-icon {
    width: 4rem;
    height: 4rem;
    color: white;
    background-color: #10b981;
    border-radius: 50%;
    padding: 0.75rem;
    stroke-width: 3;
  }
  
  .payment-success-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #10b981;
    margin-bottom: 1.5rem;
  }
  
  .payment-success-message-box {
    background-color: #ecfdf5;
    color: #047857;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }
  
  .payment-success-login-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    min-width:280px;
  }
  
  .payment-success-login-section p {
    color: #6b7280;
  }
  
  .payment-success-login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #10b981;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-success-login-button:hover {
    background-color: #059669;
  }
  
  .payment-success-footer {
    border-top: 1px solid #e5e7eb;
    padding-top: 1rem;
    margin-top: 1rem;
  }
  
  .payment-success-footer p {
    color: #6b7280;
    font-style: italic;
    font-size: 0.875rem;
  }
  