:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.smart-search {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  height: 4vh;
  z-index: 9;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 768px) {
  .smart-search {
    width: 100%;
    max-width: 32rem;
    margin: 0 auto;
    height: 4vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) and (max-width: 1438px) {
  .smart-search {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.search-container {
  position: relative;
  height: 4vh;
  z-index: 9;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .search-container {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .search-container {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .search-container {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .search-container {
    width: 100%;
  }
}

.search-input {
  width: 87%;
  height: 30px;
  padding-left: 0.5rem;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .search-input {
    height: 20px;
    width: 94%;
    margin-left: 2px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .search-input {
    height: 30px;
    width: 95%;
    margin-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 768px) {
  .search-input {
    height: 30px;
    width: 94%;
    padding-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) and (max-width: 1439px) {
  .search-input {
    height: 35px;
    width: 94%;
    padding-left: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .search-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .search-input {
    width: 97%;
    height: 36px;
    padding-left: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .search-input {
    height: 45px;
    width: 97%;
    padding-left: 1rem;
  }
}
@media only screen and (min-width:2303px) {
  .search-input {
    height: 45px;
    width: 97%;
    padding-left: 1rem;
  }
}
.search-input .search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  color: #9ca3af;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .search-input .search-icon {
    left: 1rem;
    top: 10%;
  }
}
.search-input .search-icon.loading {
  animation: spin 1s linear infinite;
}
.search-input .suggestions-container {
  position: absolute;
  width: 100%;
  margin-top: 0.5rem;
  background: white;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid #e5e7eb;
  z-index: 1000;
}
.search-input .loading-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: #6b7280;
}
.search-input .loading-message .loading-icon {
  margin-right: 0.5rem;
  animation: spin 1s linear infinite;
}
.search-input .no-results {
  padding: 1rem;
  text-align: center;
  color: #6b7280;
}
.search-input .suggestion-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}
.search-input .suggestion-item:hover {
  background-color: #f9fafb;
}
.search-input .suggestion-item .icon {
  width: 1rem;
  height: 1rem;
  color: #6b7280;
}
.search-input .suggestion-content {
  width: 100%;
  margin-left: 0.75rem;
}
.search-input .suggestion-text {
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}
.search-input .suggestion-subtext {
  font-size: 0.75rem;
  color: #6b7280;
}
.search-input .search-params {
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
}
.search-input .search-params .params-title {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.search-input .search-params .params-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.search-input .search-params .timestamp {
  font-size: 0.875rem;
  color: #6b7280;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.suggestions-container {
  position: absolute;
  width: 100%;
  margin-top: 0.5rem;
  background: white;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid #e5e7eb;
  z-index: 1;
}

.suggestion-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}
.suggestion-item:hover {
  background-color: #f9fafb;
}
.suggestion-item .icon {
  width: 1rem;
  height: 1rem;
  color: #6b7280;
}

.bslogoinLiveSearch {
  width: 10%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}/*# sourceMappingURL=SmartSearch.css.map */