@import '../style/MainStyles.scss';

.chef-profile {
  height: 30vh;
  width:90%;
  display: flex;
  align-items: center;
  padding: 3% 5%;
  background: linear-gradient(to bottom, #fff3e0, #ffffff);
  margin-top: 5px;
  @include gadgets-to(mobile){
    @media(max-width:370px){
        padding:0 2%;
        width:95%;
        border-radius: 8px;
      }
    @media(min-width:400px){
      padding:0 2%;
      width:95%;
      border-radius: 8px;
    }
  }
  @include gadgets-to(tablet){
  flex-direction: column;
  padding: 0 5%;
    }
    @include gadgets-to(laptop){
        flex-direction: column;
      padding: 0 10%;
    }
    @include gadgets-to(desktop){
        flex-direction:column;
      //padding: 0 15%;
     width:90%;
    }
    @include gadgets-to(largeDesktop){
        flex-direction:column;
      padding: 0 20%;
    }

  

  .profile-image {
    height: 70%;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    margin-right: 2rem;
    @include gadgets-to(mobile){
      @media(max-width:370px){
        height:30%;
      }
    }
    @include gadgets-to(tablet){
   height:40%;
      }
      @include gadgets-to(laptop){
    height:43%;
        }
        @include gadgets-to(desktop){
    height:45%;
        }
        @include gadgets-to(largeDesktop){
    height:50%;
        }
    

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .profile-content {
    flex: 1;
    max-width: 550px;
    display: flex;
    flex-direction: column;

    h2 {
      font-family: #{map-get($generalStyles, fontFamilyAnders)}; // Ensure $generalStyles is defined
      font-weight: 400;
      font-size: clamp(1.2rem, 2vh, 2rem);
      margin-bottom: 0.5rem;
      color: #1a1a1a;
      @include gadgets-to(mobile){
        @media(max-width:370px){
          font-size: clamp(1rem, 2vh, 2rem);
        }
        @include gadgets-to(largeDesktop){
         margin-bottom:1rem;
        }
      }
    }

    .rating {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.3rem;

      span {
        color: #4b5563;
        font-size: 0.875rem;
      }
    }

    p {
      font-family: #{map-get($generalStyles, fontFamilyAnders)}; // Ensure $generalStyles is defined
      font-size: clamp(0.8rem, 1vh, 1.2rem);
      line-height: 1.5;
      color: #4b5563;
      margin: 0;
      margin-bottom: 0.5rem;

      @include gadgets-to(mobile){
        @media (max-width:370px) {
        margin-bottom:0.5rem;
        font-size: clamp(0.6rem, 1vh, 1.2rem);
        }
        @media (min-width:400px) {
        margin-bottom:unset;
 
      }
    }
    @include gadgets-to(desktop){
      margin-bottom:0.5rem;
       font-size: clamp(1rem, 1vh, 1.2rem);
    }
    }

    .stars {
      display: flex;
      gap: 0.25rem;
    }

    .star-filled {
      fill: #fbbf24;
      color: #fbbf24;
    }

    .star-empty {
      color: #d1d5db;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    justify-content: center;

    .profile-image {
      height: 35%;
      margin: 0 0 1rem 0;

      @include gadgets-to(mobile); // Ensure the mixin gadgets-to is defined
       margin:unset;
    }

    .rating {
      justify-content: center;
    }
  }
}

.chefRatingCloseButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1px;
}
.chefRatingCloseIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color:black;
  @include gadgets-to(mobile){
    @media(max-width:370px){
      width: 15px;
      height: 15px;
    }
  }

}