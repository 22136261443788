@import '../style/MainStyles.scss';

// Container Styling
.chef-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0; /* Start with zero height */
  width: 0; /* Start with zero width */
  overflow: hidden;
  background-color: #f9f9f9;
  text-align: center;
  z-index: 2000;
  animation: expandToPeak 2s forwards ease-in-out; /* Expand and stop at peak */
  cursor: pointer;
}

// Chef Image Wrapper
.chef-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

// Chef Image
.chef-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  z-index: 2000;
}

// Modern Styled Text on Top of Image
.animated-text {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size: 2.5rem;
  font-weight: 100;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.6);
  width: 350px;
  height: 350px;
  padding: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
  opacity: 1;
  z-index: 2001;
  @include gadgets-to(tablet){
    @media(max-width:1025px){
    width:400px;
    height:400px;
    }
    @media(min-width:1026px){
      width:450px;
      height:450px;
      }
      

  }
  @include gadgets-to(laptop){
    width:400px;
    height:400px;
      
    }
  @include gadgets-to(desktop){
    width:400px;
    height:400px;
      
    }
    @include gadgets-to(largeDesktop){
      width:500px;
      height:500px;
        
      }
}
.animated-text1{
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size: 1.5rem;
  font-weight: 100;
  @include gadgets-to(mobile){
    @media(max-width:370px){
      font-size: 1rem;
    }
      @media(min-width:371px){
        font-size: 1rem;
      }
    
  }
}
.animated-text2{
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size: 1rem;
  font-weight: 100;
}
.animated-text3{
  font-family: #{map-get($generalStyles, fontFamilyAnders)};
  font-size: 1.5rem;
  font-weight: 100;
}
// Expand and Stop Animation
@keyframes expandToPeak {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  100% {
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    opacity: 1;
  }
}

// Vanish Animation triggered on click
@keyframes vanishOnClick {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-120%, -120%) scale(0.5); /* Move to top-left */
  }
}

// Apply this class when vanish animation should trigger
.vanish {
  animation: vanishOnClick 2s forwards ease-in-out; /* Animate vanish */
}
