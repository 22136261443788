
@import '../style/MainStyles.scss';

$menu-width:280px;
$transition-duration: 0.3s;

// Hamburger Button
.menu-toggle {
display: flex;
flex-direction:column;
align-items: center;
justify-content:center;
  font-size:20px;
  background: none;
  border:none;
  cursor: pointer;
  color:map-get($generalStyles,colorGold);
  height:3vh;
  width:10%;
  @include gadgets-to(mobile){
    @media (max-width:400px) {
      height:4vh;
      position:absolute;
      top:9.6%;
      left:10px;
    }
    @media (min-width:400px) {
      height:4vh;
      //border:1px solid red;
      font-size: 24px;
      padding-top:5px;
      position:absolute;
      top:10.4%;
      left:10px;
    }
  }
  @include gadgets-to(tablet){
    @media (max-width:1025px) {
      height:5vh;
      font-size:30px;
      position:absolute;
      top:9%;
      left:10px;
    }
     @media (min-width:1180px)and (max-width:1438px) {
      position:absolute;
      top:9%;
      left:10px;

      height:5vh;
      font-size:30px;
      width:60px;
  }
}@include gadgets-to(laptop){
  position:absolute;
  top:9%;
  left:10px;

  height:5vh;
  font-size:24px;
  width:60px;

}
@include gadgets-to(desktop){
  position:absolute;
  top:9%;
  left:10px;

  height:5vh;
  font-size:24px;
  width:60px;

}
@include gadgets-to(largeDesktop){
  position:absolute;
  top:9%;
  left:10px;

  height:5vh;
  font-size:28px;
  width:60px;

}
}

// Slide-In Menu
.slide-menu {
  position: fixed;
  top: 0;
  right: -350px; // Hidden by default
  width: $menu-width;
  height: 100%;
  background:white;
  color:black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2); // Shadow on the left edge
  z-index: 1011;
  transition: transform $transition-duration ease;
  cursor:pointer;
  @include gadgets-to(largeDesktop){
    width:300px;
  }

  // Menu appears when `.open` is added
  &.open {
    transform: translateX(-350px);
  }

  .close-btn {
    align-self: flex-end; // Close button at the top-right corner
    font-size: 1.5rem;
    background: none;
    border: none;
    color: #ecf0f1;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .menu-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
      text-decoration: none;
      color: #ecf0f1;
      font-size: 1.2rem;
      transition: color 0.2s ease;

      &:hover {
        color: #1abc9c;
      }
    }
  }
}

// Backdrop (darkens the rest of the screen when menu is open)
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1010;
}
.bsLiveMenuTop{
    width:15%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:5vh;
    @include gadgets-to(tablet){
        width:1px;
        height:6vh;
        justify-content:center;
        align-items: flex-start;
      
    }
    @include gadgets-to(laptop){
        width:1px;
        height:6vh;
        justify-content:center;
        align-items: flex-start;
      
    }
    @include gadgets-to(desktop){
        width:1px;
        height:6vh;
        justify-content:center;
        align-items: flex-start;
      
    }
    @include gadgets-to(largeDesktop){
        width:1px;
        height:6vh;
        justify-content:center;
        align-items: flex-start;
      
    }
}

