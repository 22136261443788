@import '../style/MainStyles.scss';
.bakesoft-join {
    position:absolute;
    top:9.8%;
    left:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   // padding: 20px;
   z-index:1000;
   width:100%;
   @include gadgets-to(tablet){
    top:0.1%;
   }
   @include gadgets-to(laptop){
    top:0.1%;
    }
    @include gadgets-to(desktop){
      top:0.1%;
    }
    @include gadgets-to(largeDesktop){
      top:0.1%;
    }

  
    &__card {
      width: 100%;
      max-width: 400px;
      height:100vh;
      overflow: hidden;
      background: linear-gradient(to bottom right, #fff3e0, #fff8e1);
    //  border-radius: 8px;
      box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.1);
   
      @include gadgets-to(tablet){
        // position:fixed;
        // top:3%;
        // height:95%;
        width:70%;
        max-width:unset;
        border-radius:8px;
        z-index:103;
  
      }
    }
    @include gadgets-to(laptop){
      &__card {
        width: 90%;
        max-width:800px;
        height:100vh;
        overflow: hidden;
        background: linear-gradient(to bottom right, #fff3e0, #fff8e1);
      //  border-radius: 8px;
        box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.1);
        z-index:103;
      }
    }
    @include gadgets-to(desktop){
      &__card {
        width: 70%;
        max-width: 400px;
        height:100vh;
        overflow: hidden;
        background: linear-gradient(to bottom right, #fff3e0, #fff8e1);
      //  border-radius: 8px;
        box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.1);
        z-index:103;
      }
    }
    @include gadgets-to(largeDesktop){
      &__card {
        width: 70%;
        max-width: 1000px;
        height:100vh;
        overflow: hidden;
        background: linear-gradient(to bottom right, #fff3e0, #fff8e1);
      //  border-radius: 8px;
        box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.1);
        z-index:103;
      }
    }

  
    &__header {
      background: linear-gradient(to right, #ffa726, #ffcc80);
      padding:26px;
      z-index:1003;
    }
  
    &__title {
      color: white;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin: 0;
    }
  
    &__content {
      //padding: 24px;
      padding-left:24px;
      padding-right:24px;
      padding-top:1px;
      height:63vh;
      max-height:63vh;
      overflow-y:scroll;
      //scrollbar
      &::-webkit-scrollbar {
        display:none;
      }

      @include gadgets-to(tablet){
        height:78vh;
        max-height:78vh;
        overflow-y:scroll;
   
        // webkit scrollbar
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
          background: #888;
        }
      }


    }
  
    &__text {
      color: #333;
      margin-bottom: 16px;
      font-family:Verdana, Geneva, Tahoma, sans-serif;
      font-weight:300;
      font-size:18px;
      &--bold {
        font-weight: bold;
      }
  
      b, strong {
        font-weight: bold;
      }

      @include gadgets-to(mobile){
        
        @media (max-width:400px) {
          font-size:16px;
        }
      }
      @include gadgets-to(tablet){
        font-size:16px;
        @media (max-width:1024px) {
          font-size:18px;
        }
        @media (min-width:1024px) and (max-width:1181px) {
          font-size:16px;
        }
        @media (min-width:1181px) and (max-width:1200px) {
          font-size:20px;
        }
        @media (min-width:1201px) and (max-width:1400px) {
          font-size:18px;
        }
      }

    }
    @include gadgets-to(laptop){
      &__text {
        color: #333;
        margin-bottom: 16px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        font-weight:300;
        font-size:20px;
        &--bold {
          font-weight: bold;
        }
    
        b, strong {
          font-weight: bold;
        }
      }
    }
  
    &__highlight {
      background-color: #e8f5e9;
      border-left: 4px solid #4caf50;
      padding: 16px;
      margin-bottom: 16px;
      border-radius: 4px;
    }
  
    &__highlight-text {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #1b5e20;
      font-weight: 600;
      margin: 0;
    }
  
    &__footer {
      background-color:transparent;
      padding:8px;
      height:60vh;
    }
  
    &__button {
      width:40%;
      background-color: #ff9800;
      color: white;
      border: none;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      box-shadow:8px 8px 4px 6px rgba(0, 0, 0, 0.1);
      margin-top:2vh;
      &:hover {
        background-color: #f57c00;
      }
    }
  
    &__note {
      text-align: center;
      font-size: 14px;
      color: #757575;
      padding: 16px;
    }

  }

  .bakesoft-join__setupInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    background-color: white;
    height:15vh;
    width:100%;
   // border:1px solid grey;
    border-radius: 4px;
    margin-top: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    // animate
    transition: all 0.3s ease;
  }

  .bakesoft-join__setupInfo {
    display: flex;
    flex-direction:column;
  //  justify-content: center;
    align-items: center;
   // margin-left:10px;
    font-family:map-get($generalStyles,fontFamilyAnders);
    font-size:14px;
    font-weight:bold;
    color:black;
    width: 100%;
    height:3vh;
    border-bottom: 1px solid #f5f5f5;
 //   margin:10px;
  }
 .bakesoftJoinCloseIconWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width:100%;
    height:3vh;
    background-color: #f5f5f5;
   // border-radius: 4px;
  //  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    // animate
 //   transition: all 0.3s ease;
    background: linear-gradient(to right, #ffa726, #ffcc80);
   
  }
  .bakesoftJoinCloseIcon{
    font-size:22px;
    color:black;
    margin-right:5px;
    margin-top:5px;
    cursor: pointer;
    
 }
 
.bakesoft-join__ButtonWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:5vh;
}
