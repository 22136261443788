:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.bakesoft-join {
  position: absolute;
  top: 9.8%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bakesoft-join {
    top: 0.1%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bakesoft-join {
    top: 0.1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bakesoft-join {
    top: 0.1%;
  }
}
@media only screen and (min-width:2303px) {
  .bakesoft-join {
    top: 0.1%;
  }
}
.bakesoft-join__card {
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(to bottom right, #fff3e0, #fff8e1);
  box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bakesoft-join__card {
    width: 70%;
    max-width: unset;
    border-radius: 8px;
    z-index: 103;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bakesoft-join__card {
    width: 90%;
    max-width: 800px;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(to bottom right, #fff3e0, #fff8e1);
    box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.1);
    z-index: 103;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bakesoft-join__card {
    width: 70%;
    max-width: 400px;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(to bottom right, #fff3e0, #fff8e1);
    box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.1);
    z-index: 103;
  }
}
@media only screen and (min-width:2303px) {
  .bakesoft-join__card {
    width: 70%;
    max-width: 1000px;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(to bottom right, #fff3e0, #fff8e1);
    box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.1);
    z-index: 103;
  }
}
.bakesoft-join__header {
  background: linear-gradient(to right, #ffa726, #ffcc80);
  padding: 26px;
  z-index: 1003;
}
.bakesoft-join__title {
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 0;
}
.bakesoft-join__content {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 1px;
  height: 63vh;
  max-height: 63vh;
  overflow-y: scroll;
}
.bakesoft-join__content::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bakesoft-join__content {
    height: 78vh;
    max-height: 78vh;
    overflow-y: scroll;
  }
  .bakesoft-join__content::-webkit-scrollbar {
    width: 5px;
  }
  .bakesoft-join__content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .bakesoft-join__content::-webkit-scrollbar-thumb {
    background: #888;
  }
}
.bakesoft-join__text {
  color: #333;
  margin-bottom: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 300;
  font-size: 18px;
}
.bakesoft-join__text--bold {
  font-weight: bold;
}
.bakesoft-join__text b, .bakesoft-join__text strong {
  font-weight: bold;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .bakesoft-join__text {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bakesoft-join__text {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .bakesoft-join__text {
    font-size: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1024px) and (max-width: 1181px) {
  .bakesoft-join__text {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1200px) {
  .bakesoft-join__text {
    font-size: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1201px) and (max-width: 1400px) {
  .bakesoft-join__text {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bakesoft-join__text {
    color: #333;
    margin-bottom: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 300;
    font-size: 20px;
  }
  .bakesoft-join__text--bold {
    font-weight: bold;
  }
  .bakesoft-join__text b, .bakesoft-join__text strong {
    font-weight: bold;
  }
}
.bakesoft-join__highlight {
  background-color: #e8f5e9;
  border-left: 4px solid #4caf50;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
}
.bakesoft-join__highlight-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #1b5e20;
  font-weight: 600;
  margin: 0;
}
.bakesoft-join__footer {
  background-color: transparent;
  padding: 8px;
  height: 60vh;
}
.bakesoft-join__button {
  width: 40%;
  background-color: #ff9800;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 8px 8px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 2vh;
}
.bakesoft-join__button:hover {
  background-color: #f57c00;
}
.bakesoft-join__note {
  text-align: center;
  font-size: 14px;
  color: #757575;
  padding: 16px;
}

.bakesoft-join__setupInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 15vh;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.bakesoft-join__setupInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
  font-size: 14px;
  font-weight: bold;
  color: black;
  width: 100%;
  height: 3vh;
  border-bottom: 1px solid #f5f5f5;
}

.bakesoftJoinCloseIconWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 3vh;
  background-color: #f5f5f5;
  background: linear-gradient(to right, #ffa726, #ffcc80);
}

.bakesoftJoinCloseIcon {
  font-size: 22px;
  color: black;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.bakesoft-join__ButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
}/*# sourceMappingURL=joinbakesoft1.css.map */