@import '../style/MainStyles.scss';

.cookie-warning {
  position: fixed; /* or absolute */
  top: 50%;
  left:40%;
  transform: translate(-50%, -50%);

   // transform: translateX(-50%);
    width: 90%;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index:3100;
    @include gadgets-to(mobile){
      left:2%;
    }
    @include gadgets-to(tablet){
      @media (min-width:768px) {
       left:30%;
      }
    }
    @include gadgets-to(laptop){
      left:36%;
    }
    @include gadgets-to(desktop){
      left:40%;
    }
  
    &__content {
      padding:14px;
      text-align: center;
    }
  
    &__title {
      font-size:24px;
      font-family:map-get($generalStyles,fontFamilyAnders);
      font-weight:300;
      margin-bottom: 12px;
      color: #333;
    }
  
    &__description {
      font-size: 14px;
      font-family: map-get($generalStyles,fontFamilyAnders);
      font-weight:200;
      color: #666;
      margin-bottom: 16px;
    }
  
    &__icon {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
  
      svg {
        color: #4a5568;
      }
    }
  
    &__actions {
      display: flex;
      justify-content: center;
      gap: 12px;
    }
  
    &__button {
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 0.9rem;
      font-weight: 500;
      text-decoration: none;
      transition: background-color 0.3s ease;
  
      &--primary {
        background-color: #4a5568;
        color: #ffffff;
  
        &:hover {
          background-color: #2d3748;
        }
      }
  
      &--secondary {
        background-color: #e2e8f0;
        color: #4a5568;
  
        &:hover {
          background-color: #cbd5e0;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .cookie-warning {
      width: 95%;
      
      &__actions {
        flex-direction: column;
      }
  
      &__button {
        width: 100%;
      }
    }
  }
  
  .linkstyle {
    text-decoration: none;
    color: #4a5568;
    white-space: normal;
    overflow-wrap: break-word;
    font-size:14px;
    inline-size: min-content;
    
}
  

  .cookie-consent {
  max-width: 40rem;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__list {
    list-style-type: disc;
    padding-left: 1.5rem;
  }

  &__link {
    color: #3366cc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__button {
    margin-top: 1.5rem;
    padding: 0.5rem 1rem;
    background-color: #3366cc;
    color: #ffffff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #254e9c;
    }
  }
  
}