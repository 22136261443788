@import '../style/MainStyles.scss';

.scroll-limit-message {
    position: fixed;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 50;
    max-width: 400px;
    margin: 0 auto;
    @include gadgets-to(largeDesktop){
     width: 700px;
     height:40vh;
     max-width:unset;
     bottom:20%;
     left: 16px;
    }
  
    .card {
      background: white;
      color: #ecf0f1;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border:2px solid map-get($generalStyles,datalistBorderColor);
      @include gadgets-to(largeDesktop){
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height:40vh;
      }
      &__header {
        padding: 16px 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
     

  
        h2 {
          margin: 0;
          font-family: map-get($generalStyles,fontFamily);
          font-size:18px;
          font-weight: 600;
          @include gadgets-to(largeDesktop){
            font-size:34px;
          }
        }
      }
  
      &__content {
        padding: 16px 20px;
        font-family: map-get($generalStyles,fontFamilyAnders);
        font-size:14px;
        font-weight:200;
        color:black;
        @include gadgets-to(largeDesktop){
          font-size:18px;
        }

  
        p {
          margin: 0;
          color: black;
        }
      }
  
      &__footer {
        display: flex;
        justify-content: space-between;
        padding: 16px 20px;
      }
    }
  
    .button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s, color 0.2s;
      margin-left:3px;
      @include gadgets-to(largeDesktop){
        padding: 6px 84px;
        font-size: 1.5rem;
      }
  
      &--primary {
        background-color: #3498db;
        color: #ffffff;
        @include gadgets-to(largeDesktop){
         padding: 6px 84px;
          font-size: 1.5rem;
        }
  
        &:hover {
          background-color: #2980b9;
        }
      }
  
      &--secondary {
        background-color:black;
        color:white;
        border: 1px solid #ecf0f1;
        @include gadgets-to(largeDesktop){
          padding: 6px 84px;
           font-size: 1.5rem;
         }
  
        &:hover {
          background-color: rgba(236, 240, 241, 0.1);
        }
      }
  
      svg {
        margin-right: 8px;
        @include gadgets-to(largeDesktop){
          font-size: 1.5rem;
        }
      }
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .slide-up {
    animation: slideUp 0.3s ease-out forwards;
  }

  .scrollLimitCloseIcon{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color:black;
  }
  
  