@import '../style/MainStyles.scss';

.bslivechristmas-tree-icon {
    position: relative;
    display: inline-block;
    font-size:16px; /* Base size, can be overridden */

}

    .bslivetree {
      color: #15803d; // text-green-700
      width: 2.8rem;
      height: 2.9rem;
      @include gadgets-to(mobile){
        width: 2.8rem;
        height: 2.9rem;
        @media (min-width:400px) {
          width: 3.5rem;
          height: 3.6rem;
        }
      }
      @include gadgets-to(tablet){
        width: 3rem;
        height: 3rem;
        @media (max-width:1024px) {
          width: 3.5rem;
          height: 3.5rem;
        }
        @media (min-width:1024px) and (max-width:1181px) {
          width: 3rem;
          height: 3rem
        }
        @media (min-width:1181px) and (max-width:1200px) {
          width: 4.4rem;
          height: 4.4rem;
        }
        @media (min-width:1201px) and (max-width:1400px) {
          width: 3.6rem;
          height:3.6rem;
        }
        
      }@include gadgets-to(laptop){
        width: 3.8rem;
        height: 3.8rem;
    
      }
      @include gadgets-to(desktop){
        width: 4.5rem;
        height: 4.5rem;
      }
      @include gadgets-to(largeDesktop){
        width: 4.5rem;
        height: 4.5rem;
      }

    }

  
    .bslivelight {
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      animation: twinkle 1.5s ease-in-out infinite;
  
      &.red { background-color: #ef4444; top: 0.25rem; left: 50%; }
      &.yellow { background-color: #facc15; top: 0.75rem; left: 25%; }
      &.blue { background-color: #3b82f6; top: 1.25rem; right: 25%; }
      &.purple { background-color: #a855f7; top: 1.75rem; left: 33%; }
      &.orange { background-color: #f97316; bottom: 0.75rem; right: 33%; }
    }
  
    .bslivestar {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #facc15; // text-yellow-400
      font-size: 1rem;
    }
  
  
  @keyframes twinkle {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }