@import '../style/MainStyles.scss';

.smart-search {
    width:100%;
    max-width:50rem;
    margin: 0 auto;
    height:4vh;
    z-index:9;

   @include gadgets-to(tablet){
    @media (min-width:768px) {
      width:100%;
      max-width: 32rem;
      margin: 0 auto;
      height:4vh;
    }
    @media (min-width:1180px) and (max-width:1438px) {
      width:90%;
     display:flex;
     flex-direction: row;
      justify-content: center;
      align-items: center;
    }
}
}

    .search-container {
      position: relative;
      //margin-bottom: 1rem;
      height:4vh;
      z-index:9;
      @include gadgets-to(tablet){
     width:100%;
      }
      @include gadgets-to(laptop){
        width:100%;
      }
      @include gadgets-to(desktop){
        width:100%;
      }
      @include gadgets-to(largeDesktop){
        width:100%;
    }
    }
  
    .search-input {
      width:87%;
      height:30px;
      padding-left:0.5rem;
      //padding: 0.5rem 0.5rem 0.5rem 11.5rem;
      border: 1px solid #e2e8f0;
     // border-radius: 0.5rem;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      transition: all 0.2s;
      @include gadgets-to(mobile) {
        @media (max-width:400px) {
          height:20px;
          width:94%;
          margin-left: 2px;
        }
        @media (min-width:400px) {
          height:30px;
          width:95%;
          margin-left: 2px;
        }
      }
        @include gadgets-to(tablet){
          @media (min-width:768px) {
            height:30px;
            width:94%;
           // margin-left: 2px;
           padding-left:2px;
          }
          @media (min-width:1180px) and (max-width:1439px) {
            height:35px;
            width:94%;
           // margin-left: 2px;
           padding-left:2px;
          }
      &:focus {
        outline: none;
        border-color: #3b82f6;
        box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
      }
    }
@include gadgets-to(laptop){
  width:97%;
  height:36px;
  padding-left: 1rem;
  //padding: 0.5rem 0.5rem 0.5rem 11.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}

@include gadgets-to(desktop){
  height:45px;
  width:97%;
  padding-left: 1rem;
}
@include gadgets-to(largeDesktop){
  height:45px;
  width:97%;
  padding-left: 1rem;
}


 
    .search-icon {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.25rem;
      height: 1.25rem;
      color: #9ca3af;
      @include gadgets-to(mobile) {
        @media (max-width:400px) {
            left: 1rem;
            top: 10%;
        }
      }
  
      &.loading {
        animation: spin 1s linear infinite;
      }
    }
  
    .suggestions-container {
      position: absolute;
      width: 100%;
      margin-top: 0.5rem;
      background: white;
      background-color: white;
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      border: 1px solid #e5e7eb;
      z-index: 1000;
    }
  
    .loading-message {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      color: #6b7280;
  
      .loading-icon {
        margin-right: 0.5rem;
        animation: spin 1s linear infinite;
      }
    }
  
    .no-results {
      padding: 1rem;
      text-align: center;
      color: #6b7280;
    }
  
    .suggestion-item {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      cursor: pointer;
      transition: background-color 0.2s;
     
  
      &:hover {
        background-color: #f9fafb;
      }
  
      .icon {
        width: 1rem;
        height: 1rem;
        color: #6b7280;
      }
    }
  
    .suggestion-content {
        width:100%;
      margin-left: 0.75rem;
   
    }
  
    .suggestion-text {
      font-size: 0.875rem;
      font-weight: 500;
      white-space: nowrap;
    }
  
    .suggestion-subtext {
      font-size: 0.75rem;
      color: #6b7280;
    }
  
    .search-params {
      padding: 1rem;
      background-color: #f9fafb;
      border-radius: 0.5rem;
  
      .params-title {
        font-weight: 500;
        margin-bottom: 0.5rem;
      }
  
      .params-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
  
      .timestamp {
        font-size: 0.875rem;
        color: #6b7280;
      }
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .suggestions-container {
    position: absolute;
    width: 100%;
    margin-top: 0.5rem;
    background: white;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid #e5e7eb;
    z-index: 1;
  }

  .suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
   

    &:hover {
      background-color: #f9fafb;
    }

    .icon {
      width: 1rem;
      height: 1rem;
      color: #6b7280;
    }
  }
  .bslogoinLiveSearch{
    width:10%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
  }