:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.bsTotalArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 99vh;
  border-radius: 8px;
}

.bsTopArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8vh;
  box-shadow: inset 0 0 30px rgb(225, 176, 68);
  border-radius: 8px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsTopArea {
    height: 8vh;
  }
}

.bsNameArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  font-size: 30px;
  font-family: "Lobster";
}

.bsMiddleArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .bsMiddleArea {
    height: 5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsMiddleArea {
    height: 5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsMiddleArea {
    height: 9vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsMiddleArea {
    height: 6vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsMiddleArea {
    height: 9vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsMiddleArea {
    height: 9vh;
  }
}

.bsGridArea {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 0.1rem;
  width: 99%;
  justify-content: center;
  height: auto;
  max-height: calc(96vh - 1rem);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 1rem;
  margin-top: 0.5vh;
  z-index: 1;
}
.bsGridArea::-webkit-scrollbar {
  width: 5px;
}
.bsGridArea::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.bsGridArea::-webkit-scrollbar-thumb {
  background: rgb(225, 176, 68);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsGridArea {
    width: 100%;
    gap: 0.1rem;
  }
  .bsGridArea::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsGridArea {
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsGridArea {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.2rem;
    margin-top: 0.1vh;
    width: 100%;
  }
  .bsGridArea::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsGridArea {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.2rem;
    margin-top: 0.1vh;
    width: 100%;
  }
  .bsGridArea::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsGridArea {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.2rem;
    margin-top: 0.1vh;
    width: 100%;
  }
  .bsGridArea::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .bsGridArea {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.2rem;
    margin-top: 0.1vh;
    width: 100%;
  }
  .bsGridArea::-webkit-scrollbar {
    display: none;
  }
}

.bsGridItem {
  aspect-ratio: 1;
  width: 100%;
}
@media (min-width: 1740px) and (max-width: 1800px) {
  .bsGridItem {
    height: 34vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsGridItem {
    max-width: 50vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsGridItem {
    max-width: 20vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsGridItem {
    max-width: 20vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsGridItem {
    max-width: 20vw;
  }
}
@media only screen and (min-width:2303px) {
  .bsGridItem {
    max-width: 20vw;
  }
}

.bsGridImage {
  width: 99%;
  height: 20vh;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsGridImage {
    width: 99%;
    height: 22vh;
    -o-object-fit: cover;
       object-fit: cover;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsGridImage {
    width: 99%;
    width: 99%;
    height: 23vh;
    -o-object-fit: cover;
       object-fit: cover;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsGridImage {
    width: 99%;
    height: 24vh;
    -o-object-fit: cover;
       object-fit: cover;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .bsGridImage {
    width: 99%;
    height: 29vh;
    -o-object-fit: cover;
       object-fit: cover;
    cursor: pointer;
  }
}

.bsGridFrame {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 28vh;
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.5s;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .bsGridFrame {
    height: 31vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsGridFrame {
    height: 29vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsGridFrame {
    height: 30vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 800px) and (max-width: 1024px) {
  .bsGridFrame {
    height: 34vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .bsGridFrame {
    height: 30.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsGridFrame {
    height: 34vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsGridFrame {
    height: 36vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsGridFrame {
    height: 42vh;
  }
}

.bsRecipeHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3vh;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 0.5vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsRecipeHeader {
    font-size: 12px;
    height: 4vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1180px) {
  .bsRecipeHeader {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) {
  .bsRecipeHeader {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsRecipeHeader {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsRecipeHeader {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsRecipeHeader {
    font-size: 24px;
  }
}

.bsRecipeHeaderChefName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsRecipeHeaderChefName {
    font-size: 8px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsRecipeHeaderChefName {
    font-size: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsRecipeHeaderChefName {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1070px) {
  .bsRecipeHeaderChefName {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) {
  .bsRecipeHeaderChefName {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsRecipeHeaderChefName {
    font-size: 14px;
    margin-top: 0.2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsRecipeHeaderChefName {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsRecipeHeaderChefName {
    font-size: 20px;
  }
}

.bsCategorySelectArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 3.5vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsCategorySelectArea {
    width: 50%;
    height: 6.2vh;
  }
}

.bsCategorySelect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 99%;
  height: 30px;
  border-radius: 4px;
  border-color: rgb(223, 212, 212);
  outline: none;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  margin: 1px;
  background-color: white;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsCategorySelect {
    font-size: 16px;
  }
}

.bsLiveSpinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%) scale(1);
  z-index: 2005;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(1);
    z-index: 2005;
  }
}

.bsLiveSearchArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 10;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveSearchArea {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveSearchArea {
    margin-top: 0.5vh;
    margin-bottom: 0.2vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveSearchArea {
    margin-top: 0.5vh;
    margin-bottom: 1.2vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

.bsLiveSearch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 25px;
  border-radius: 4px;
  border-color: rgb(223, 212, 212);
  outline: none;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  outline: none;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveSearch {
    border: 1px solid rgb(223, 212, 212);
    width: 97%;
    height: 30px;
  }
}

.bsNameText {
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  margin-top: 1vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsNameText {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsNameText {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsNameText {
    font-size: 30px;
  }
}
@media only screen and (min-width:2303px) {
  .bsNameText {
    font-size: 40px;
  }
}

.bsLiveLogo {
  margin-top: 1vh;
  width: 30%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveLogo {
    width: 10%;
  }
}

.bsLiveLogoImage {
  position: absolute;
  top: 0.6%;
  left: 2%;
  width: 16%;
  border-radius: 50%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveLogoImage {
    width: 16%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 360px) and (max-width: 370px) {
  .bsLiveLogoImage {
    top: 1.3%;
    width: 14%;
    border-radius: 50%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) and (max-width: 411px) {
  .bsLiveLogoImage {
    top: 1%;
    width: 16%;
    border-radius: 50%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 412px) {
  .bsLiveLogoImage {
    top: 1.2%;
    width: 15.5%;
    left: 2.4%;
    border-radius: 50%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveLogoImage {
    top: 0.6%;
    width: 5%;
    left: 0.7%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1070px) {
  .bsLiveLogoImage {
    top: 1.1%;
    width: 5.8%;
    left: 0.7%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1070px) and (max-width: 1181px) {
  .bsLiveLogoImage {
    top: 1.1%;
    width: 4.5%;
    left: 0.7%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1182px) and (max-width: 1441px) {
  .bsLiveLogoImage {
    top: 0.9%;
    width: 5.5%;
    left: 0.7%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveLogoImage {
    top: 1%;
    width: 5.5%;
    left: 0.7%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) and (min-width: 1740px) and (max-width: 1800px) {
  .bsLiveLogoImage {
    position: absolute;
    width: 4%;
    top: 0.4%;
    left: 1%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveLogoImage {
    top: 1.1%;
    width: 3.8%;
    left: 0.7%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveLogoImage {
    top: 0.8%;
    width: 3.6%;
    left: 0.7%;
  }
}

.bsLiveName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-family: Lobster;
  color: rgb(225, 176, 68);
  width: 100%;
  height: 7vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveName {
    font-size: 32px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveName {
    font-size: 40px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveName {
    width: 20%;
    font-size: 30px;
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveName {
    width: 20%;
    font-size: 45px;
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveName {
    width: 20%;
    font-size: 45px;
    align-items: flex-end;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveName {
    width: 20%;
    font-size: 45px;
    align-items: flex-end;
  }
}

.bsHideImage {
  position: absolute;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  transform: translate(100vw, -100vh) scale(0);
  opacity: 0;
  pointer-events: none;
}

.bsRecipeSocialArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 5vh;
  font-size: 12px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsRecipeSocialArea {
    font-size: 18px;
  }
}

.bsRecipeSocialButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 3vh;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  white-space: nowrap;
  background-color: rgb(66, 153, 66);
  cursor: pointer;
}
.bsRecipeSocialButton:hover {
  background-color: rgb(53, 204, 53);
  color: white;
  font-size: 14px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsRecipeSocialButton {
    font-size: 18px;
  }
}

@keyframes expand {
  from {
    transform: scale(0.1);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.bsLiveModalTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  height: 400px;
  position: fixed;
  top: 20%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 12px 12px 8px rgba(0, 0, 0, 0.5);
  opacity: 1;
  animation: expand 0.5s ease-out forwards;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveModalTop {
    width: 100%;
    height: 95vh;
    top: 9.6%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveModalTop {
    width: 99%;
    height: 95vh;
    top: 9.5%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveModalTop {
    position: fixed;
    top: 0%;
    width: 100vw;
    height: 100vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveModalTop {
    position: fixed;
    top: 4%;
    width: 90vw;
    height: 95vh;
    border: 1px solid grey;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveModalTop {
    position: fixed;
    top: 4%;
    width: 90vw;
    height: 95vh;
    border: 1px solid grey;
    border-radius: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveModalTop {
    position: fixed;
    top: 4%;
    width: 80vw;
    height: 95vh;
    border: 1px solid grey;
    border-radius: 8px;
  }
}

.bsLiveModalCloseIconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 3vh;
}

.bsLiveModalTopClose {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 24px;
  color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
}
@media only screen and (min-width:2303px) {
  .bsLiveModalTopClose {
    width: 40px;
    height: 40px;
    font-size: 30px;
  }
}

.bslivexvspinner-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 20%;
  height: 5vh;
  font-size: 16px;
  font-family: var(--font-family);
  background-color: white;
  border-radius: 12px;
  border: 3px solid rgb(225, 176, 68);
  padding: 3px;
  font-size: 18px;
  font-family: Lobster;
  box-shadow: 12px 12px 8px rgba(0, 0, 0, 0.5);
  z-index: 2006;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bslivexvspinner-text {
    width: 80%;
    height: 4vh;
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bslivexvspinner-text {
    width: 40%;
    height: 6vh;
    font-size: 14px;
  }
}

.bsLiveloading-dots {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 3px;
  width: 40%;
}
.bsLiveloading-dots span {
  width: 16px;
  height: 16px;
  margin: 0 4px;
  background-color: #f8b400;
  display: inline-block;
  animation: loading2 1s infinite ease-in-out;
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  transform: rotate(90deg);
}
.bsLiveloading-dots span:nth-child(1) {
  animation-delay: 0s;
}
.bsLiveloading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}
.bsLiveloading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading2 {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.bsLiveRecipeListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  height: 80vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveRecipeListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 55%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 834px) and (max-width: 1180px) {
  .bsLiveRecipeListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 55%;
    margin-top: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveRecipeListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 55%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveRecipeListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 55%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveRecipeListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
  }
}

.bsLiveRecipeHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveRecipeHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 92vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .bsLiveRecipeHeaderWrapper {
    height: 84vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveRecipeHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 92vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) and (min-width: 1100px) {
  .bsLiveRecipeHeaderWrapper {
    height: 84vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveRecipeHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 92vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) and (min-width: 1100px) {
  .bsLiveRecipeHeaderWrapper {
    height: 84vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveRecipeHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 92vh;
  }
}
@media only screen and (min-width:2303px) and (min-width: 1100px) {
  .bsLiveRecipeHeaderWrapper {
    height: 84vh;
  }
}

.bsLiveRecipeHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
  font-size: 16px;
  margin-bottom: 0.1vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveRecipeHeader {
    height: 10vh;
    width: 40vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) {
  .bsLiveRecipeHeader {
    height: 4vh;
    width: 35vw;
    margin-bottom: 1vh;
  }
}

.bsLiveRecipeListHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
  font-size: 16px;
  margin-bottom: 0.1vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveRecipeListHeader {
    height: 2.3vh;
    margin-top: 5px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 399px) {
  .bsLiveRecipeListHeader {
    height: 3.5vh;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveRecipeListHeader {
    height: 3vh;
    width: 50.1vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .bsLiveRecipeListHeader {
    width: 52vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveRecipeListHeader {
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveRecipeListHeader {
    margin-top: 0.5vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveRecipeListHeader {
    margin-top: 0.5vh;
  }
}

.bsLiveIngredHeaderName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40vw;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  padding-left: 3px;
  color: black;
  height: 18px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveIngredHeaderName {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveIngredHeaderName {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveIngredHeaderName {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveIngredHeaderName {
    font-size: 18px;
  }
}

.bsLiveHeaderKgPrice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(15vw - 2px);
  margin-left: 2px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  color: black;
  height: 18px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveHeaderKgPrice {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveHeaderKgPrice {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveHeaderKgPrice {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveHeaderKgPrice {
    font-size: 18px;
  }
}

.bsLiveHeaderPris {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(12vw - 2px);
  margin-left: 2px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  color: black;
  height: 18px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveHeaderPris {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveHeaderPris {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveHeaderPris {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveHeaderPris {
    font-size: 18px;
  }
}

.bsLiveHeaderMangd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(15vw - 2px);
  margin-left: 2px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  color: black;
  height: 18px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveHeaderMangd {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveHeaderMangd {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveHeaderMangd {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveHeaderMangd {
    font-size: 18px;
  }
}

.bsLiveHeaderKalories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(13vw - 2px);
  margin-left: 2px;
  font-size: 11px;
  border-radius: 4px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  color: black;
  height: 18px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveHeaderKalories {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveHeaderKalories {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveHeaderKalories {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveHeaderKalories {
    font-size: 18px;
  }
}

.bsLiveIngredName, .bsLiveIngredName1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 40vw;
  font-size: 12px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  padding-left: 3px;
  color: black;
  height: 21px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveIngredName, .bsLiveIngredName1 {
    font-size: 14px;
    height: 26px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveIngredName, .bsLiveIngredName1 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveIngredName, .bsLiveIngredName1 {
    font-size: 20px;
    height: 32px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveIngredName, .bsLiveIngredName1 {
    font-size: 20px;
    height: 32px;
  }
}

.bsLiveIngredName1 {
  background-color: rgb(199, 202, 106);
}

.bsLiveKgPrice, .bsLiveKgPrice1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(15vw - 2px);
  margin-left: 2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  color: black;
  height: 21px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveKgPrice, .bsLiveKgPrice1 {
    font-size: 14px;
    height: 26px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveKgPrice, .bsLiveKgPrice1 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveKgPrice, .bsLiveKgPrice1 {
    font-size: 16px;
    height: 32px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveKgPrice, .bsLiveKgPrice1 {
    font-size: 18px;
    height: 32px;
  }
}

.bsLiveKgPrice1 {
  background-color: rgb(199, 202, 106);
}

.bsLiveMangd, .bsLiveMangd1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(15vw - 2px);
  margin-left: 2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  color: black;
  height: 21px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveMangd, .bsLiveMangd1 {
    font-size: 14px;
    height: 26px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveMangd, .bsLiveMangd1 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveMangd, .bsLiveMangd1 {
    font-size: 16px;
    height: 32px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveMangd, .bsLiveMangd1 {
    font-size: 18px;
    height: 32px;
  }
}

.bsLiveMangd1 {
  background-color: rgb(199, 202, 106);
}

.bsLivePris, .bsLivePris1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(12vw - 2px);
  margin-left: 2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  color: black;
  height: 21px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePris, .bsLivePris1 {
    font-size: 14px;
    height: 26px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePris, .bsLivePris1 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePris, .bsLivePris1 {
    font-size: 16px;
    height: 32px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePris, .bsLivePris1 {
    font-size: 18px;
    height: 32px;
  }
}

.bsLivePris1 {
  background-color: rgb(199, 202, 106);
}

.bsLiveKalories, .bsLiveKalories1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(13vw - 2px);
  margin-left: 2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
  background-color: rgb(223, 225, 170);
  color: black;
  height: 21px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveKalories, .bsLiveKalories1 {
    font-size: 14px;
    height: 26px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveKalories, .bsLiveKalories1 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveKalories, .bsLiveKalories1 {
    font-size: 16px;
    height: 32px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveKalories, .bsLiveKalories1 {
    font-size: 18px;
    height: 32px;
  }
}

.bsLiveKalories1 {
  background-color: rgb(199, 202, 106);
}

.bsLiveOrder {
  width: 48px;
  margin-left: 2px;
  font-size: 12px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: rgb(225, 176, 68);
  border: 1px solid rgb(223, 212, 212);
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveOrder {
    font-size: 14px;
  }
}

.bsLiveIngredWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  overflow-y: auto;
}
.bsLiveIngredWrapper::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveIngredWrapper {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
  }
}

.bsLiveIngredRowWrapper {
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveIngredRowWrapper {
    width: 91%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .bsLiveIngredRowWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveIngredRowWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveIngredRowWrapper {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveIngredRowWrapper {
    width: 100%;
  }
}

.bsLiveIngredRowWrapper1 {
  display: none;
}

.bsLiveIngredRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3vh;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveIngredRow {
    height: 3.5vh;
    margin-top: 2px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveIngredRow {
    height: 3vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveIngredRow {
    height: 3vh;
    width: 100%;
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 834px) and (max-width: 1180px) {
  .bsLiveIngredRow {
    height: 3.6vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveIngredRow {
    height: 3vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveIngredRow {
    height: 4vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveIngredRow {
    height: 3.6vh;
  }
}

.bsLiveRecipeText {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  height: 60vh;
}

.bsLiveRecipeTextInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
  max-height: 40vh;
  overflow-y: auto;
  border: none;
  outline: none;
  margin-bottom: 5vh;
}
.bsLiveRecipeTextInner::-webkit-scrollbar {
  display: none;
}

.bsLiveRecipeTextInner1 {
  width: 100%;
  height: 40vh;
  max-height: 40vh;
  overflow-y: auto;
  z-index: 10;
  border: 1px solid rgb(223, 212, 212);
  outline: none;
  border-radius: 4px;
}
.bsLiveRecipeTextInner1::-webkit-scrollbar {
  display: none;
}

.bsLiveRecipeTextHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.5vh;
  font-size: 14px;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveRecipeTextHeader {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveRecipeTextHeader {
    font-size: 20px;
  }
}

.bsLiveblurred {
  filter: blur(5px);
  opacity: 0.5;
}

.bsLiveActionAreaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bsLiveActionArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 12px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveActionArea {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveActionArea {
    font-size: 24px;
    height: 2vh;
  }
}

.bsLiveActionAreaButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 5vh;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  cursor: pointer;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-left: 1vw;
  box-shadow: 7px 7px 6px 1px #b4ab98;
  margin-bottom: 1vh;
  background-color: lightgray;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveActionAreaButton {
    font-size: 18px;
  }
}

.bsLiveButtonIcon {
  width: 20px;
  height: 30px;
  color: green;
}

.bsLivePaymentTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96%;
  height: 30vh;
  background-color: white;
  border-radius: 4px;
  z-index: 20;
  animation: expand 1s ease-out forwards;
}

.bsLivePaymentTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10vh;
  margin-bottom: 10vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLivePaymentTop {
    height: 20vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .bsLivePaymentTop {
    height: 10vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 24vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentTop {
    width: 80%;
    height: 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentTop {
    width: 60%;
    height: 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentTop {
    width: 55%;
    height: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5vh;
  }
}

.bsLivePaymentTop1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: absolute;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLivePaymentTop1 {
    top: 45%;
    left: 0.9%;
    width: 98%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .bsLivePaymentTop1 {
    top: 52%;
    left: 0.9%;
    width: 98%;
    height: 10vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentTop1 {
    top: 62%;
    left: 25%;
    height: 15vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentTop1 {
    top: 65%;
    left: 25%;
    height: 15vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentTop1 {
    top: 60%;
    left: 25%;
    height: 20vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentTop1 {
    top: 54%;
    left: 25%;
    height: 20vh;
  }
}

.bsLivePaymentMessage {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 3.5vh;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 8px;
  margin-left: 3vw;
  border-radius: 4px;
  color: black;
  border: 2px dotted rgb(225, 176, 68);
  padding-bottom: 8px;
  padding-left: 1vw;
}
.bsLivePaymentMessage:hover {
  background-color: lightgrey;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLivePaymentMessage {
    width: 85%;
    margin-left: unset;
    margin-top: 8px;
    font-size: 10px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentMessage {
    font-size: 14px;
    margin-left: unset;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentMessage {
    width: 70%;
    margin-left: unset;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentMessage {
    width: 70%;
    margin-left: unset;
  }
}

.bsLivePaymentRadio, .bsLivePaymentRadio1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentRadio, .bsLivePaymentRadio1 {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentRadio, .bsLivePaymentRadio1 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentRadio, .bsLivePaymentRadio1 {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentRadio, .bsLivePaymentRadio1 {
    font-size: 22px;
  }
}

.bsLivePaymentRadio1 {
  font-weight: bold;
}

.bsLivePaymentContactArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20vh;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLivePaymentContactArea {
    margin-top: 10vh;
    width: 95%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLivePaymentContactArea {
    margin-top: 7vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentContactArea {
    height: 40vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentContactArea {
    height: 40vh;
    width: 80%;
  }
}

.bsLivePaymentContact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 94%;
  height: 22px;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLivePaymentContact {
    height: 27px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentContact {
    width: 100%;
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentContact {
    width: 100%;
    height: 30px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentContact {
    width: 100%;
    height: 35px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentContact {
    width: 100%;
    height: 30px;
  }
}

.bsLivePaymentButton, .bsLivePaymentButton1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  margin-top: 1vh;
  color: white;
  background-color: #2c79df;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLivePaymentButton, .bsLivePaymentButton1 {
    width: 80%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentButton, .bsLivePaymentButton1 {
    font-size: 18px;
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentButton, .bsLivePaymentButton1 {
    font-size: 18px;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentButton, .bsLivePaymentButton1 {
    font-size: 18px;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentButton, .bsLivePaymentButton1 {
    font-size: 18px;
    width: 100%;
  }
}

.bsLivePaymentButton1 {
  background-color: #2c79df;
  width: 100%;
}

.bsLivePaymentTopWrapper {
  position: absolute;
  top: 0%;
  left: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 95vh;
  max-height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 3vh;
  background-color: white;
  border-radius: 4px;
  z-index: 20;
  animation: expand 1s ease-out forwards;
}
.bsLivePaymentTopWrapper::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentTopWrapper {
    height: 95vh;
    position: absolute;
    top: 0%;
    left: 0%;
  }
}

.bsLivePaymentCloseIconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 1vh;
  margin-top: 1vh;
  margin-right: 1vh;
}

.bsLivePaymentCloseIcon2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 24px;
  color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  cursor: pointer;
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentCloseIcon2 {
    width: 30px;
    height: 30px;
    font-size: 32px;
  }
}

.bsLiveExtraText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  font-weight: bold;
  white-space: nowrap;
  width: 100%;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveExtraText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveExtraText {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveExtraText {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveExtraText {
    font-size: 24px;
  }
}

.bsLiveHr {
  width: 25%;
  height: 1px;
  background-color: rgb(223, 212, 212);
  outline: none;
  margin-top: 1vh;
  border: none;
}

.bsLivePaymentSelectCurrencyArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10vh;
  margin-top: 1vh;
}

.bsLivePaymentSelectCurrency {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 30px;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentSelectCurrency {
    width: 50%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentSelectCurrency {
    width: 60%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentSelectCurrency {
    width: 60%;
  }
}

.bsLiveCreatorProfileInformation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 24vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveCreatorProfileInformation {
    height: 24vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveCreatorProfileInformation {
    height: 20vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveCreatorProfileInformation {
    width: 70%;
    height: 20vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCreatorProfileInformation {
    width: 70%;
    height: 25vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveCreatorProfileInformation {
    width: 60%;
    height: 30vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveCreatorProfileInformation {
    width: 60%;
    height: 30vh;
  }
}

.bsLiveUserPresentationTopWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserPresentationTopWrapper {
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.bsLiveUserPresentationBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveUserPresentationBox {
    width: 90%;
    height: 10vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationBox {
    width: 85%;
    height: 17vh;
  }
}

.bsLiveUserPresentationInnerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10vh;
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationInnerBox {
    height: 15vh;
  }
}

.bsLiveUserPresentationProChefArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: lightgrey;
  width: 5vw;
  height: 2vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 7px;
  padding: 1px;
  margin-left: 1vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveUserPresentationProChefArea {
    width: 25%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationProChefArea {
    width: 35%;
    height: 3vh;
    font-size: 16px;
    margin-top: 5px;
  }
}

.bsLiveUserPresentationStarArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 21vw;
  height: 3vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 10px;
  padding: 1px;
  margin-left: 1vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveUserPresentationStarArea {
    width: 95%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveUserPresentationStarArea {
    font-size: 11px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserPresentationStarArea {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserPresentationStarArea {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserPresentationStarArea {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationStarArea {
    font-size: 25px;
    height: 6vh;
    width: 31vw;
    margin-top: 5px;
  }
}

.bsLiveUserPresentationStarIcon {
  width: 13px;
  height: 13px;
  color: rgb(225, 176, 68);
  fill: rgb(225, 176, 68);
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveUserPresentationStarIcon {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserPresentationStarIcon {
    width: 12px;
    height: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserPresentationStarIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationStarIcon {
    width: 30px;
    height: 30px;
  }
}

.bsLiveUserPresentationGlobalArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 21vw;
  height: 2vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 10px;
  padding: 1px;
  margin-left: 1vw;
  color: grey;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveUserPresentationGlobalArea {
    width: 95%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveUserPresentationGlobalArea {
    font-size: 11px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserPresentationGlobalArea {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserPresentationGlobalArea {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserPresentationGlobalArea {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationGlobalArea {
    font-size: 22px;
    height: 3vh;
    width: 31vw;
    margin-top: 1px;
  }
}

.bsLiveUserPresentationGlobalIcon {
  width: 13px;
  height: 13px;
  color: grey;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveUserPresentationGlobalIcon {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserPresentationGlobalIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserPresentationGlobalIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationGlobalIcon {
    width: 30px;
    height: 30px;
  }
}

.bsLiveUserPresentationLanguages {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 65vw;
  height: 2vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 10px;
  padding: 1px;
  margin-left: 1vw;
  margin-top: 3px;
  color: grey;
  white-space: nowrap;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveUserPresentationLanguages {
    width: 63vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserPresentationLanguages {
    width: 21vw;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserPresentationLanguages {
    width: 21vw;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserPresentationLanguages {
    width: 21vw;
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationLanguages {
    width: 31vw;
    font-size: 20px;
    margin-top: 10px;
  }
}

.bsLiveUserPresentationLangIcon {
  width: 13px;
  height: 13px;
  color: grey;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveUserPresentationLangIcon {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserPresentationLangIcon {
    width: 17px;
    height: 17px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserPresentationLangIcon {
    width: 21px;
    height: 21px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserPresentationLangIcon {
    width: 32px;
    height: 32px;
    margin-top: 15px;
  }
}

.proChefIcon {
  width: 10px;
  height: 10px;
  color: black;
}
@media only screen and (min-width:2303px) {
  .proChefIcon {
    width: 25px;
    height: 25px;
  }
}

.bsLiveCreatorProfileName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 3vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveCreatorProfileName {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveCreatorProfileName {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 1vh;
    font-weight: bold;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCreatorProfileName {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveCreatorProfileName {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveCreatorProfileName {
    font-size: 40px;
  }
}

.bsLiveCreatorProfileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 1vw;
  padding: 10px;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .bsLiveCreatorProfileImage {
    width: 60px;
    height: 80px;
    margin: 0;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveCreatorProfileImage {
    width: 100px;
    height: 100px;
    margin: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveCreatorProfileImage {
    width: 70px;
    height: 60px;
    border: 1px solid;
    padding: unset;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCreatorProfileImage {
    width: 110px;
    height: 110px;
    margin: 0;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveCreatorProfileImage {
    width: 110px;
    height: 110px;
    margin: 0;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveCreatorProfileImage {
    width: 150px;
    height: 140px;
    margin-left: unset;
  }
}

.bsLiveCreatorInfoBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 18vh;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveCreatorInfoBox {
    height: 12vh;
    margin-top: unset;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .bsLiveCreatorInfoBox {
    height: 14vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveCreatorInfoBox {
    height: 32vh;
    border-radius: 8px;
    margin: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 834px) and (max-width: 1025px) {
  .bsLiveCreatorInfoBox {
    height: 22vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1026px) and (max-width: 1179px) {
  .bsLiveCreatorInfoBox {
    height: 32vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1180px) {
  .bsLiveCreatorInfoBox {
    height: 32vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCreatorInfoBox {
    width: 88%;
    height: 22vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveCreatorInfoBox {
    width: 55%;
    height: 20vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveCreatorInfoBox {
    width: 90%;
    height: 20vh;
  }
}

.bsLiveCreatorProfileText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  height: 14vh;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: grey;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 0.5vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveCreatorProfileText {
    font-size: 14px;
    height: 8vh;
  }
}
@media (min-width: 834px) and (max-width: 1180px) {
  .bsLiveCreatorProfileText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCreatorProfileText {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveCreatorProfileText {
    font-size: 22px;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveCreatorProfileText {
    font-size: 24px;
  }
}

.bsLiveSeparator {
  width: 100%;
  height: 1px;
}

.bsLiveCheckoutButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 3vh;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
}
.bsLiveCheckoutButton:hover {
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLiveStripeCompleteBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 99%;
  height: 80vh;
  background-color: white;
  z-index: 20;
}

.bsLiveStripeCompleteText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

.bsLiveStripeCompleteIcon {
  width: 30px;
  height: 30px;
  color: green;
}

.bsLiveStripeConfirmBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  background-color: white;
  border-radius: 4px;
}

.bsLiveCustomerInfoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 93%;
  height: 14vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 6px;
  margin-top: 1vh;
  padding-bottom: 6px;
  padding-top: 3px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveCustomerInfoBox {
    height: 12vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveCustomerInfoBox {
    height: 12vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveCustomerInfoBox {
    width: 38%;
    margin-top: 1vh;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1025px) {
  .bsLiveCustomerInfoBox {
    width: 41%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1430px) {
  .bsLiveCustomerInfoBox {
    width: 31%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCustomerInfoBox {
    margin-top: unset;
    width: 35%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveCustomerInfoBox {
    margin-top: unset;
    width: 38.5%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveCustomerInfoBox {
    margin-top: 10px;
    width: 31%;
  }
}

.bsLiveCustomerInfoHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.bsLiveInfoTextWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.bsLiveCustomerInfoTextHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  color: black;
  margin-left: 2vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveCustomerInfoTextHeader {
    font-size: 20px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .bsLiveCustomerInfoTextHeader {
    font-size: 16px;
  }
}

.bsLiveCustomerInfoText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 3.5vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveCustomerInfoText {
    height: 3.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .bsLiveCustomerInfoText {
    height: 3vh;
  }
}

.bsLiveCustomerInfoText2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  height: 3.5vh;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveCustomerInfoText2 {
    height: 3.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .bsLiveCustomerInfoText2 {
    height: 3vh;
  }
}

.bsLiveCustomerInfoText3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 3vh;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  color: black;
  border-radius: 12px;
  background-color: rgb(223, 212, 212);
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveCustomerInfoText3 {
    height: 3.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 400px) {
  .bsLiveCustomerInfoText3 {
    height: 3vh;
  }
}

.bsLivePaymentHr {
  width: 90%;
  height: 1px;
  background-color: rgb(223, 212, 212);
  outline: none;
  margin-top: 1vh;
  border: none;
}

.bsLivePaymentHr1 {
  width: 60%;
  height: 1px;
  background-color: rgb(223, 212, 212);
  outline: none;
  margin-top: 1vh;
  border: none;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLivePaymentHr1 {
    width: 85%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentHr1 {
    width: 55%;
  }
}

.bsLiveLoginButtonChoiceArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 5vh;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .bsLiveLoginButtonChoiceArea {
    height: 5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveLoginButtonChoiceArea {
    height: 8vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveLoginButtonChoiceArea {
    height: 8vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveLoginButtonChoiceArea {
    width: 100%;
    height: 8vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveLoginButtonChoiceArea {
    width: 82%;
    height: 8vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveLoginButtonChoiceArea {
    width: 82%;
    height: 8vh;
  }
}

.bsLiveLoginButtonChoice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 3vh;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  background-color: white;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
}

.bsLiveTabContainer {
  width: 100%;
  height: 72vh;
  margin: auto;
  font-family: Arial, sans-serif;
  background-color: white;
  margin-top: 0.5vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTabContainer {
    width: 90%;
    height: 20vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTabContainer {
    width: 90%;
    height: 18vh;
  }
}

.bsliveTabs {
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: -1px; /* For overlapping effect */
}

.bsliveTab {
  flex: 1;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-bottom: none;
  transition: background-color 0.3s;
  position: relative;
  top: 1px;
}

.bsliveTab.bsliveActive {
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom: none;
  font-weight: bold;
  color: #333;
  z-index: 2; /* Bring active tab to the front */
}
@media only screen and (min-width:2303px) {
  .bsliveTab.bsliveActive {
    font-size: 20px;
  }
}

.bsLiveTabContent {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  min-height: 100px;
  height: 58vh;
  box-sizing: border-box;
  position: relative;
  top: -5px; /* Moves content up to overlap with the tabs */
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTabContent {
    height: 42vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTabContent {
    height: 39vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTabContent {
    height: 39vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTabContent {
    height: 39vh;
  }
}

.bsLiveTabContentSmall {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  min-height: 100px;
  height: 20vh;
  box-sizing: border-box;
  position: relative;
  top: -5px; /* Moves content up to overlap with the tabs */
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTabContentSmall {
    height: 20vh;
  }
}

.bsLiveSelectAreaWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-top: 1vh;
}

.bsLiveUserLoginWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bsLiveUserLoginEmail, .bsLiveUserLoginPass {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 96%;
  height: 3vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveUserLoginEmail, .bsLiveUserLoginPass {
    height: 3.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveUserLoginEmail, .bsLiveUserLoginPass {
    height: 2.7vh;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserLoginEmail, .bsLiveUserLoginPass {
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserLoginEmail, .bsLiveUserLoginPass {
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserLoginEmail, .bsLiveUserLoginPass {
    width: 80%;
  }
}

.bsLiveLoginButtonUser {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98%;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: #2c79df;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin: unset;
  padding: 3px;
  margin-top: 5px;
}
.bsLiveLoginButtonUser:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveLoginButtonUser {
    width: 98%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveLoginButtonUser {
    width: 92%;
    height: 35px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveLoginButtonUser {
    width: 98%;
    height: 30px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveLoginButtonUser {
    width: 81%;
    height: 38px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveLoginButtonUser {
    width: 81%;
    height: 40px;
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveLoginButtonUser {
    width: 81%;
    height: 40px;
    font-size: 20px;
  }
}

.bsLiveUnlockButtonAreaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bsLiveUnlockButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: #2c79df;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  cursor: pointer;
}
.bsLiveUnlockButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveUnlockButton {
    width: 98%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveUnlockButton {
    width: 92%;
    height: 38px;
    background-color: black;
    color: white;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .bsLiveUnlockButton {
    width: 92%;
    height: 38px;
    background-color: black;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUnlockButton {
    width: 60%;
    height: 35px;
    background-color: black;
    color: white;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUnlockButton {
    width: 80%;
    height: 38px;
    background-color: black;
    color: white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUnlockButton {
    width: 60%;
    height: 40px;
    background-color: black;
    color: white;
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUnlockButton {
    width: 60%;
    height: 40px;
    font-size: 20px;
    background-color: black;
    color: white;
  }
}

.bsLiveCreditInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 22vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 200;
  margin-top: 1vh;
  color: black;
}

.bsLiveWalletIconContainer {
  position: relative;
  display: inline-block;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveWalletIconContainer {
    margin-top: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveWalletIconContainer {
    margin-top: 1vh;
  }
}

.bsLivePaymentHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentHeader {
    font-size: 12px;
    height: 5vh;
    margin-left: 2vw;
    margin-top: unset;
    border: 2px dotted lightgrey;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentHeader {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentHeader {
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentHeader {
    font-size: 24px;
  }
}

.bsLiveLogoutButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: #2c79df;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
}
.bsLiveLogoutButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLiveIngredExpanded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 10vh;
  max-height: 10vh;
  overflow-y: auto;
  animation: expand 2s ease-out forwards;
}

.bsLiveextraInfo {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease, opacity 1.5s ease;
  padding: 0 1px;
  opacity: 0;
}

.bsLiveextraInfo.expanded {
  max-height: 380px; /* Largest height (tablet) to allow smooth transition */
  opacity: 1;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveextraInfo.expanded {
    max-height: 180px; /* Adjust for mobile */
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveextraInfo.expanded {
    max-height: 130px; /* Special case for small tablets/large phones */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveextraInfo.expanded {
    max-height: 380px; /* Adjust for tablet */
  }
}

.bsLiveextraInfo.collapsed {
  max-height: 0;
  opacity: 0;
}

.bsLiveTabLoginArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  z-index: 20;
  animation: expand 1s ease-out forwards;
  margin-top: 1vh;
}

.bsLiveTabLogoutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  z-index: 20;
  animation: expand 1s ease-out forwards;
}

.bsLiveTabLogoutButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: #2c79df;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
}
.bsLiveTabLogoutButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLiveTabLoginOnlyArea {
  position: absolute;
  top: 20%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 15vh;
  background-color: white;
  border-radius: 4px;
  z-index: 200;
  animation: expand 1s ease-out forwards;
  margin-top: 1vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveTabLoginOnlyArea {
    width: 98%;
    top: 9.3%;
    left: 0;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTabLoginOnlyArea {
    width: 100%;
    top: 8.7%;
    left: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTabLoginOnlyArea {
    width: 35%;
    top: 9%;
    left: 32%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTabLoginOnlyArea {
    width: 35%;
    top: 9%;
    left: 32%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTabLoginOnlyArea {
    width: 35%;
    top: 9%;
    left: 32%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTabLoginOnlyArea {
    width: 35%;
    top: 9%;
    left: 32%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}

.bsLiveLoginOnlyTabContent, .bsLiveLoginOnlyTabContent1 {
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  min-height: 100px;
  height: 24vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1002;
  top: -5px; /* Moves content up to overlap with the tabs */
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveLoginOnlyTabContent, .bsLiveLoginOnlyTabContent1 {
    height: 24vh;
    padding: 3px;
    width: 100%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveLoginOnlyTabContent, .bsLiveLoginOnlyTabContent1 {
    height: 16vh;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveLoginOnlyTabContent, .bsLiveLoginOnlyTabContent1 {
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveLoginOnlyTabContent, .bsLiveLoginOnlyTabContent1 {
    box-shadow: 4px 4px 12px 1px #b1afab;
    height: 19vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveLoginOnlyTabContent, .bsLiveLoginOnlyTabContent1 {
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveLoginOnlyTabContent, .bsLiveLoginOnlyTabContent1 {
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}

.bsLiveLoginOnlyTabContent1 {
  height: 15vh;
}

.bsLiveTabOnlyContainer {
  width: 100%;
  height: 12vh;
  margin: auto;
  font-family: Arial, sans-serif;
  background-color: white;
  border-radius: 4px 4px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveTabOnlyContainer {
    width: 100%;
    height: 20vh;
    padding: 2px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTabOnlyContainer {
    width: 100%;
    height: 20vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1437px) {
  .bsLiveTabOnlyContainer {
    width: 100%;
    height: 20vh;
  }
}

.bsLiveLoginFormOnly {
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveLoginFormOnly {
    width: 90%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveLoginFormOnly {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveLoginFormOnly {
    width: 90%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveLoginFormOnly {
    width: 90%;
  }
}

.bsliveOnlyTabs {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 4px;
  z-index: 1;
  margin-bottom: -1px; /* For overlapping effect */
}

.bsLiveLoginOnlyActive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-bottom: none;
  font-weight: bold;
  color: #333;
  z-index: 2; /* Bring active tab to the front */
  padding-top: 1vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveLoginOnlyActive {
    font-size: 18px;
    font-weight: bold;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveLoginOnlyActive {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveLoginOnlyActive {
    font-size: 22px;
    font-weight: bold;
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveLoginOnlyActive {
    font-size: 24px;
    font-weight: bold;
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveLoginOnlyActive {
    font-size: 26px;
    font-weight: bold;
    width: 100%;
  }
}

.bsLiveCloseLoginOnlyIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  font-size: 24px;
  color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  cursor: pointer;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCloseLoginOnlyIcon {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveCloseLoginOnlyIcon {
    width: 26px;
    height: 26px;
    font-size: 24px;
  }
}

.bsLiveTabLogoutOnlyArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 99%;
  height: 15vh;
  background-color: white;
  border-radius: 4px;
  z-index: 20;
  animation: expand 1s ease-out forwards;
  margin-top: 1vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTabLogoutOnlyArea {
    width: 50%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTabLogoutOnlyArea {
    width: 50%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTabLogoutOnlyArea {
    width: 50%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTabLogoutOnlyArea {
    width: 30%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}

.bsLiveLogouUserOnlyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  border: 1px solid rgb(223, 212, 212);
}

.bsLiveLogoutUserOnlyButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 5vh;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  background-color: #2c79df;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
}
.bsLiveLogoutUserOnlyButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLivePaymentCloseIcon3 {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  font-size: 24px;
  color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  margin-left: 3vw;
  cursor: pointer;
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentCloseIcon3 {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}

.bsLiveLogoutOnlyButtonText {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  color: white;
}

.bsLiveUserAccountArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 55vh;
  background-color: white;
  border-radius: 4px;
  z-index: 20;
  animation: expand 1s ease-out forwards;
  margin-top: 1vh;
  border: 1px solid rgb(223, 212, 212);
  z-index: 100;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserAccountArea {
    width: 50%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserAccountArea {
    width: 60%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserAccountArea {
    width: 50%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserAccountArea {
    width: 50%;
    box-shadow: 4px 4px 12px 1px #b1afab;
  }
}

.bsLIveUserAccountHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3vh;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  z-index: 100;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLIveUserAccountHeader {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLIveUserAccountHeader {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLIveUserAccountHeader {
    font-size: 22px;
  }
}

.bsLiveTabUserContainer {
  width: 100%;
  height: 22vh;
  margin: auto;
  font-family: Arial, sans-serif;
  background-color: white;
  z-index: 101;
}

.bsliveUserTabs {
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: -1px; /* For overlapping effect */
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  z-index: 100;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsliveUserTabs {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsliveUserTabs {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsliveUserTabs {
    font-size: 20px;
  }
}

.bsliveUserTab {
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-bottom: none;
  transition: background-color 0.3s;
  position: relative;
  top: 1px;
}

.bsliveUserTab:first-child {
  border-top-left-radius: 8px;
}

.bsliveUserTab:last-child {
  border-top-right-radius: 8px;
}

.bsliveUserTab.bsliveUserActive {
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom: none;
  font-weight: bold;
  color: #333;
  z-index: 2; /* Bring active tab to the front */
}

.bsLiveUserTabContent {
  border: 1px solid #ccc;
  padding: 6px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  min-height: 100px;
  height: 85vh;
  box-sizing: border-box;
  position: relative;
  top: 2px; /* Moves content up to overlap with the tabs */
  width: 100%;
  z-index: 100;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserTabContent {
    box-shadow: 4px 4px 12px 1px #b1afab;
    height: 75vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserTabContent {
    box-shadow: 4px 4px 12px 1px #b1afab;
    height: 75vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserTabContent {
    box-shadow: 4px 4px 12px 1px #b1afab;
    height: 75vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserTabContent {
    box-shadow: 4px 4px 12px 1px #b1afab;
    height: 75vh;
  }
}

.bsLiveUserAccountWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 100;
}

.bsLiveUserAccountInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 99%;
  height: 60vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  box-shadow: 1px 1px 4px 1px #e6dfcf;
}

.bsLiveUserAccountInfoText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 3vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  margin-left: 3vw;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserAccountInfoText {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserAccountInfoText {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserAccountInfoText {
    font-size: 18px;
  }
}

.bsLiveUserAccountUnlockedRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 3vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveUserAccountUnlockedRow {
    height: 4vh;
    margin-bottom: 3px;
  }
}

.bsLiveUserAccountUnlocked {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  height: 80vh;
}

.bsLiveUserAccountUnlockedText {
  margin-left: 2px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 4vh;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  background-color: #ffffff;
}

.bsLiveUserAccountUnlockedBenaemn {
  margin-left: 2px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  background-color: #ffffff;
  padding-left: 1vw;
  white-space: nowrap;
  border: 1px solid #e5e7eb;
}
.bsLiveUserAccountUnlockedBenaemn:hover {
  background-color: #f3f4f6;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserAccountUnlockedBenaemn {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserAccountUnlockedBenaemn {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserAccountUnlockedBenaemn {
    font-size: 18px;
  }
}

.bsLiveAccountUnlockedText {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  margin-right: 3vw;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveAccountUnlockedText {
    font-size: 17px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveAccountUnlockedText {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveAccountUnlockedText {
    font-size: 18px;
  }
}

.bsLiveCalendarIcon {
  width: 12px;
  height: 12px;
  color: #808080;
  margin-right: 1vw;
  margin-bottom: 1px;
  cursor: pointer;
}
.bsLiveCalendarIcon:hover {
  color: #5e97e0;
  animation: bounceRotate 3s ease-in-out infinite;
  animation-iteration-count: 2;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveCalendarIcon {
    width: 14px;
    height: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCalendarIcon {
    width: 16px;
    height: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveCalendarIcon {
    width: 16px;
    height: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveCalendarIcon {
    width: 18px;
    height: 18px;
  }
}

.bsLiveUserAccountHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bsLiveUserAccountUnlockedList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
}
.bsLiveUserAccountUnlockedList::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserAccountUnlockedList {
    height: 70vh;
    max-height: unset;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserAccountUnlockedList {
    height: 70vh;
    max-height: unset;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserAccountUnlockedList {
    height: 70vh;
    max-height: unset;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserAccountUnlockedList {
    height: 70vh;
    max-height: unset;
  }
}

.bsLiveUnlockedEnded {
  margin-left: 2px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 3vh;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: red;
  background-color: white;
}

.bsLiveImageAreaWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 4px;
  z-index: 10;
  animation: expand 1s ease-out forwards;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveImageAreaWrapper {
    height: 48vh;
    width: 35vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .bsLiveImageAreaWrapper {
    width: 35vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveImageAreaWrapper {
    height: 48vh;
    width: 35vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) and (min-width: 1100px) {
  .bsLiveImageAreaWrapper {
    width: 35vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveImageAreaWrapper {
    height: 48vh;
    width: 31vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) and (min-width: 1100px) {
  .bsLiveImageAreaWrapper {
    width: 31vw;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveImageAreaWrapper {
    height: 48vh;
    width: 35vw;
  }
}
@media only screen and (min-width:2303px) and (min-width: 1100px) {
  .bsLiveImageAreaWrapper {
    width: 35vw;
  }
}

.bsLiveImageArea {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 40vh;
  background-color: white;
  border-radius: 4px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveImageArea {
    height: 50vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveImageArea {
    height: 50vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveImageArea {
    height: 50vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveImageArea {
    height: 50vh;
  }
}

.bsLiveShowChosenImage {
  width: 100%;
  height: 40vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveShowChosenImage {
    height: 45vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) and (max-width: 1180px) {
  .bsLiveShowChosenImage {
    height: 48vh;
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveShowChosenImage {
    height: 45vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveShowChosenImage {
    height: 45vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveShowChosenImage {
    height: 45vh;
    width: 85%;
  }
}

.bsLiveRecipeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  z-index: 10;
}
.bsLiveRecipeWrapper::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveRecipeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    margin-top: 1vh;
  }
  .bsLiveRecipeWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .bsLiveRecipeWrapper {
    width: 95%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveRecipeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    margin-top: 1vh;
  }
  .bsLiveRecipeWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveRecipeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    margin-top: 1vh;
  }
  .bsLiveRecipeWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveRecipeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    margin-top: 1vh;
  }
  .bsLiveRecipeWrapper::-webkit-scrollbar {
    display: none;
  }
}

.bsLiveSocialAreaIconsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 96%; /* or a fixed width if needed */
  max-width: 100%; /* Ensure it doesn't exceed its container */
  overflow: hidden; /* Prevent content from spilling outside */
  box-sizing: border-box; /* Include padding and border in the width */
}

.bsLiveSocialHeartIcon {
  width: 13px;
  height: 13px;
  stroke: grey;
  fill: none;
  margin-left: 1vw;
  cursor: pointer;
}
.bsLiveSocialHeartIcon:hover {
  animation: heartBeat 3s infinite;
  animation-iteration-count: 2;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveSocialHeartIcon {
    width: 13px;
    height: 13px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveSocialHeartIcon {
    width: 17px;
    height: 17px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveSocialHeartIcon {
    width: 18px;
    height: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveSocialHeartIcon {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveSocialHeartIcon {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveSocialHeartIcon {
    width: 26px;
    height: 26px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveSocialHeartIcon {
    width: 28px;
    height: 28px;
  }
}

.bsLiveSocialShareContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveSocialShareContainer {
    width: 60%;
  }
}

.bsLiveSocialShareIcon {
  color: #4b5563;
  width: 13px;
  height: 13px;
  margin-left: 1vw;
  cursor: pointer;
}
.bsLiveSocialShareIcon:hover {
  color: #5e97e0;
  animation: bounceRotate 3s ease-in-out infinite;
  animation-iteration-count: 2;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveSocialShareIcon {
    width: 15px;
    height: 15px;
    margin: 0;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveSocialShareIcon {
    width: 17px;
    height: 17px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveSocialShareIcon {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveSocialShareIcon {
    width: 22px;
    height: 22px;
  }
}

.bsLiveShareOnMedia {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: rgb(255, 238, 83);
  z-index: 50;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
@keyframes heartBeat {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: scale(1);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: scale(1.2);
  }
}
@keyframes bounceRotate {
  0%, 20%, 50%, 80%, 100% {
    transform: rotate(0deg) translateY(0);
  }
  40% {
    transform: rotate(180deg) translateY(-10px);
  }
  60% {
    transform: rotate(270deg) translateY(-5px);
  }
  100% {
    transform: rotate(360deg) translateY(0);
  }
}
.socialArea {
  display: flex;
  gap: 10px;
  z-index: 200;
  cursor: pointer;
}
.socialArea .likeIcon, .socialArea .shareIcon {
  cursor: pointer;
  font-size: 1.5rem;
}
.socialArea .likeIcon {
  color: red;
  font-size: 1.5rem;
  cursor: pointer;
}
.socialArea .likeIcon:hover {
  color: darkred;
}
.socialArea .socialAreaShareLink {
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
  font-size: 10px;
}
.socialArea .shareIcon:hover {
  color: #007bff;
}
.socialArea .shareOptions {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  top: 20%;
  right: 0;
  z-index: 60;
}
.socialArea .shareOptions a {
  color: #333;
  font-size: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}
.socialArea .shareOptions a:hover {
  color: #007bff;
}
.socialArea .shareOptions .closeIcon {
  align-self: flex-end;
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
}
.socialArea .shareOptions .closeIcon:hover {
  color: #ff0000;
}

.bsLiveLikeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.bsLiveLikeCount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 300;
  height: 3vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #4b5563;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveLikeCount {
    font-size: 11px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveLikeCount {
    font-size: 13px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveLikeCount {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveLikeCount {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveLikeCount {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveLikeCount {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveLikeCount {
    font-size: 24px;
  }
}

.bsLiveSeenContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bsLiveSeenCount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  height: 3vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #4b5563;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveSeenCount {
    font-size: 11px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveSeenCount {
    font-size: 13px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveSeenCount {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveSeenCount {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveSeenCount {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveSeenCount {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveSeenCount {
    font-size: 24px;
  }
}

.bsLiveSocialSeenIcon {
  width: 15px;
  height: 15px;
  color: grey;
  margin-left: 1vw;
  cursor: pointer;
}
.bsLiveSocialSeenIcon:hover {
  animation: heartBeat 3s infinite;
  animation-iteration-count: 2;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveSocialSeenIcon {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveSocialSeenIcon {
    width: 18px;
    height: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveSocialSeenIcon {
    width: 18px;
    height: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveSocialSeenIcon {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveSocialSeenIcon {
    width: 26px;
    height: 26px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveSocialSeenIcon {
    width: 28px;
    height: 28px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveSocialSeenIcon {
    width: 32px;
    height: 32px;
  }
}

.bsLiveDiffContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.bsLiveDiffCount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3vh;
  font-size: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #4b5563;
}

.bsLiveDiffIcon {
  width: 15px;
  height: 15px;
  color: grey;
  margin-left: 1vw;
  cursor: pointer;
}
.bsLiveDiffIcon:hover {
  animation: heartBeat 3s infinite;
  animation-iteration-count: 2;
}

.bsLiveTimeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}

.bsLiveTimeCount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3vh;
  font-size: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #4b5563;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveTimeCount {
    font-size: 10px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTimeCount {
    font-size: 13px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveTimeCount {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTimeCount {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTimeCount {
    font-size: 22px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTimeCount {
    font-size: 22px;
  }
}

.bsLiveTimeIcon {
  width: 13px;
  height: 13px;
  color: grey;
  margin-left: 1vw;
  cursor: pointer;
}
.bsLiveTimeIcon:hover {
  animation: heartBeat 3s infinite;
  animation-iteration-count: 2;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveTimeIcon {
    width: 13px;
    height: 13px;
  }
}
@media (min-width: 400px) {
  .bsLiveTimeIcon {
    width: 18px;
    height: 18px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveTimeIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTimeIcon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTimeIcon {
    width: 24px;
    height: 24px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTimeIcon {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsChefHat {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 14px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 1px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsChefHat {
    top: 7px;
    right: 15px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsChefHat {
    position: absolute;
    top: 25px;
    right: 12px;
    font-size: 12px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 1px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 400px) {
  .bsChefHat {
    top: 7px;
    right: 15px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsChefHat {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 20px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 1px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsChefHat {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 24px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 1px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width:2303px) {
  .bsChefHat {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 29px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 4px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}

/* Background color variations */
.chef-bg-green {
  background-color: rgba(139, 204, 79, 0.8); /* Green for easy difficulty */
}

.chef-bg-orange {
  background-color: rgba(255, 165, 0, 0.8); /* Orange for medium difficulty */
}

.chef-bg-red {
  background-color: rgba(255, 0, 0, 0.8); /* Red for hard difficulty */
}

.chef-default {
  background-color: rgba(128, 128, 128, 0.5); /* Gray for unknown difficulty */
}

.bsLiveBookmark {
  position: absolute;
  top: 8px;
  left: 25px;
  font-size: 14px; /* Emoji size */
  border: 2px solid white; /* White border for contrast */
  border-radius: 50%; /* Circular shape */
  padding: 8px; /* Add space around the emoji */
  display: flex; /* Center the emoji */
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
  cursor: pointer; /* Change cursor to pointer */
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveBookmark {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 14px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 4px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveBookmark {
    top: 7px;
    left: 15px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveBookmark {
    position: absolute;
    top: 5px;
    left: 20px;
    font-size: 14px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 4px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 400px) {
  .bsLiveBookmark {
    top: 7px;
    left: 15px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveBookmark {
    position: absolute;
    top: 5px;
    left: 10px;
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 4px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) and (min-width: 400px) {
  .bsLiveBookmark {
    top: 7px;
    left: 15px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveBookmark {
    position: absolute;
    top: 5px;
    left: 10px;
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 4px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) and (min-width: 400px) {
  .bsLiveBookmark {
    top: 7px;
    left: 15px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveBookmark {
    position: absolute;
    top: 15px;
    left: 15px;
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    padding: 10px; /* Add space around the emoji */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}
@media only screen and (min-width:2303px) and (min-width: 400px) {
  .bsLiveBookmark {
    top: 7px;
    left: 15px;
  }
}

/* Background color variations */
.mark-green {
  background-color: rgba(139, 204, 79, 0.8); /* Green for easy difficulty */
}

.bsLiveBookmarkIcon {
  width: 14px;
  height: 14px;
  fill: rgb(225, 176, 68);
  stroke: white;
  cursor: pointer;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveBookmarkIcon {
    width: 20px;
    height: 20px;
    fill: rgb(225, 176, 68);
    stroke: white;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveBookmarkIcon {
    width: 24px;
    height: 24px;
    fill: rgb(225, 176, 68);
    stroke: white;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveBookmarkIcon {
    width: 26px;
    height: 26px;
    fill: rgb(225, 176, 68);
    stroke: white;
  }
}

.bsLiveChefImageWrapper {
  position: absolute;
  top: 1%;
  left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 2vw;
  width: 92%;
  height: 14vh;
  margin-top: 5vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveChefImageWrapper {
    top: 1%;
    left: 6%;
    width: 90%;
    height: 19vh;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 800px) {
  .bsLiveChefImageWrapper {
    width: 90%;
    height: 16vh;
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveChefImageWrapper {
    position: absolute;
    top: 9%;
    left: 6%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 2vw;
    width: 90%;
    height: 19vh;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveChefImageWrapper {
    position: absolute;
    top: 12%;
    left: 6%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 2vw;
    width: 90%;
    height: 19vh;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveChefImageWrapper {
    position: absolute;
    top: 23%;
    left: 6%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 2vw;
    width: 90%;
    height: 19vh;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveChefImageLink {
    position: absolute;
    font-size: 14px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    display: flex; /* Center the emoji */
    margin-left: 1vw;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
    z-index: 10;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveChefImageLink {
    position: absolute;
    font-size: 14px; /* Emoji size */
    border: 2px solid white; /* White border for contrast */
    border-radius: 50%; /* Circular shape */
    display: flex; /* Center the emoji */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5); /* Default semi-transparent white */
    cursor: pointer; /* Change cursor to pointer */
  }
}

.bsLiveChefImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 10;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveChefImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveChefImage {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid white;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveChefImage {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid white;
  }
}

/* Background color variations */
.mark-green {
  background-color: rgba(139, 204, 79, 0.8); /* Green for easy difficulty */
}

.bsLiveAvailableLangText {
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  color: black;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveAvailableLangText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1400px) {
  .bsLiveAvailableLangText {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveAvailableLangText {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveAvailableLangText {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveAvailableLangText {
    font-size: 22px;
  }
}

.bsLiveAvailableLangIcon {
  width: 14px;
  height: 14px;
  fill: rgb(225, 176, 68);
  stroke: white;
  margin-right: 1vw;
  cursor: pointer;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveAvailableLangIcon {
    width: 14px;
    height: 14px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveAvailableLangIcon {
    width: 16px;
    height: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveAvailableLangIcon {
    width: 14px;
    height: 14px;
    fill: rgb(225, 176, 68);
    stroke: white;
    margin-right: 1vw;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveAvailableLangIcon {
    width: 24px;
    height: 24px;
    fill: rgb(225, 176, 68);
    stroke: white;
    margin-right: 1vw;
    cursor: pointer;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveAvailableLangIcon {
    width: 30px;
    height: 30px;
    fill: rgb(225, 176, 68);
    stroke: white;
    margin-right: 1vw;
    cursor: pointer;
  }
}

.bsLiveTranslateLangAreaWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 94%;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveTranslateLangAreaWrapper {
    width: 94%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTranslateLangAreaWrapper {
    width: 93%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTranslateLangAreaWrapper {
    width: 94%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 834px) and (max-width: 1180px) {
  .bsLiveTranslateLangAreaWrapper {
    width: 90%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTranslateLangAreaWrapper {
    width: 94%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTranslateLangAreaWrapper {
    width: 94%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTranslateLangAreaWrapper {
    width: 94%;
  }
}

.bsRecipeLang {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.bsLiveTranslateLang {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 7vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveTranslateLang {
    margin-left: 7vw;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTranslateLang {
    margin-left: 10vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTranslateLang {
    margin: 0;
    justify-content: space-evenly;
  }
}

.bsLiveTimeWrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveTimeWrapper {
    width: 70%;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTimeWrapper {
    width: 70%;
  }
}

.bsLiveOtherContainerWrapper {
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bsLiveNutrientWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 15vh;
}

.bsLiveNutrientHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3vh;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}

.bsLiveNutrientHeaderNutrient {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 2.5vh;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  border-radius: 4px;
  background-color: rgb(211, 214, 109);
}

.bsLiveNutrientHeaderAmount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 2.5vh;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  border-radius: 4px;
  background-color: rgb(211, 214, 109);
}

.bsLiveNutrientHeaderUnit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(20% - 2px);
  margin-left: 2px;
  height: 2.5vh;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  border-radius: 4px;
  background-color: rgb(211, 214, 109);
}

.bsLiveNutrientName {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  height: 3vh;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  white-space: nowrap;
  background-color: rgb(238, 208, 37);
  border-radius: 4px;
  padding-left: 5px;
}

.bsLiveNutrientAmount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 3vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  border-radius: 4px;
}

.bsLiveNutrientUnit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 3vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  border-radius: 4px;
}

.bsLiveNutrientRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 0.4vh;
  color: black;
}

.bsLiveNutrientPieChart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
}
.bsLiveNutrientPieChart::-webkit-scrollbar {
  display: none;
}

.bsLivePieChartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
}
.bsLivePieChartWrapper::-webkit-scrollbar {
  width: 5px;
}

.bsLiveNutrientChangeValWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 99%;
  height: 6vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

.bsLiveNutrientChangeVal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 3vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  margin-bottom: 5px;
}

.bsLiveNutrientChangeValPlus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  box-shadow: 4px 4px 4px 1px #e6dfcf;
  background-color: rgb(211, 214, 109);
  outline: none;
}

.bsLiveNutrientChangeValMinus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
  margin-left: 5px;
  background-color: rgb(211, 214, 109);
  box-shadow: 4px 4px 4px 1px #e6dfcf;
  outline: none;
}

.bsLiveChangeIcon {
  width: 25px;
  height: 25px;
  fill: rgb(225, 176, 68);
  stroke: darkgrey;
  cursor: pointer;
}

.bsLiveUpdateButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
}

.bsLiveUpdateButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  background-color: rgb(229, 64, 68);
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-left: 1px;
}
.bsLiveUpdateButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLiveLockedRecipeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3vh;
}

.bsLiveRecipeSavedInfoText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2vh;
  font-size: 10px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}

.bsLiveRecipeSavedInfoText1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 5vh;
  font-size: 18px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveRecipeSavedInfoText1 {
    height: 1vh;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveRecipeSavedInfoText1 {
    height: 1vh;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveRecipeSavedInfoText1 {
    height: 1vh;
    align-items: flex-start;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveRecipeSavedInfoText1 {
    font-size: 24px;
    height: 1vh;
    align-items: flex-start;
  }
}

.bsLiveLockedRecipeInfoText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bsLiveLockedRecipe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bsLiveAddIngredientHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 4vh;
  font-size: 13px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  background-color: rgb(211, 214, 109);
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  border-radius: 4px;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveAddIngredientHeader {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveAddIngredientHeader {
    width: 100%;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveAddIngredientHeader {
    width: 100%;
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveAddIngredientHeader {
    width: 100%;
    font-size: 20px;
  }
}

.bsLiveAddIngredientSaveButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
}

.bsLiveAddIngredientSaveButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 86%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: rgb(229, 64, 68);
  box-shadow: 2px 2px 4px 1px #e6dfcf;
}
.bsLiveAddIngredientSaveButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLiveAddIngredientSaveButton1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: green;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
}
.bsLiveAddIngredientSaveButton1:active {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLiveCheckBoxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
}

.bsLiveCheckBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 2vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

.bsLiveRadioText {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveRadioText {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveRadioText {
    font-size: 20px;
  }
}

.bsLiveTopRightMessage {
  position: absolute;
  top: 9.5%;
  right: 3%;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  color: #fff; /* White text for contrast */
  padding: 5px 10px; /* Padding for readability */
  border-radius: 5px; /* Optional rounded corners */
  font-size: 14px; /* Adjust font size as needed */
  width: 100px; /* Adjust width as needed */
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
  z-index: 10;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTopRightMessage {
    top: 5.5%;
    right: 2.6%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTopRightMessage {
    top: 5.5%;
    right: 2.6%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTopRightMessage {
    top: 5.5%;
    right: 2.6%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTopRightMessage {
    top: 5.5%;
    right: 2.6%;
  }
}

.bsLiveRecipeSavedUnlockText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2vh;
  font-size: 10px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}

.bsLiveGotoPaymentButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: #5e97e0;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
}
.bsLiveGotoPaymentButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLiveNoCreditsText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2vh;
  font-size: 12px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: red;
}

.bsLiveCompletePageIcon {
  width: 35px;
  height: 35px;
  fill: rgb(225, 176, 68);
  stroke: green;
}

.bsLiveAddMoreCreditsButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 92%;
  height: 5vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: black;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  animation: expand 1s ease-out forwards;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveAddMoreCreditsButton {
    height: 38px;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveAddMoreCreditsButton {
    width: 60%;
    height: 35px;
    margin-top: unset;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveAddMoreCreditsButton {
    width: 80%;
    height: 38px;
    margin-top: unset;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveAddMoreCreditsButton {
    width: 60%;
    height: 40px;
    margin-top: unset;
    font-size: 16px;
    margin-top: 5px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveAddMoreCreditsButton {
    width: 60%;
    height: 38px;
    margin-top: unset;
    margin-top: 5px;
  }
}

.bsLiveStripeAccountSetupWrappper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80vh;
  background-color: white;
  z-index: 20;
  animation: expandToFull 0.8s ease-in-out forwards; /* Trigger animation */
}

@keyframes expandToFull {
  0% {
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(0); /* Start small and centered */
    width: 50px;
    height: 50px;
  }
  100% {
    top: 0;
    left: 0;
    transform: translateX(0) scale(1); /* Full size */
    width: 100%;
    height: 100%;
  }
}
.bsLiveAccountSetupText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 4vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

.bsLiveAccountSetupIcon {
  width: 35px;
  height: 35px;
  fill: rgb(225, 176, 68);
  stroke: green;
}

.bsLiveAccountSetupIcon {
  width: 30px;
  height: 30px;
  color: green;
}

.bsLiveJoinBakesoftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 99%;
  height: 80vh;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 8px;
  z-index: 20;
  animation: expandToFull 0.8s ease-in-out forwards; /* Trigger animation */
}

.bsLiveJoinBakesoftHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 12px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

.bsLiveJoinBakesoftText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 4vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

.bsliveJoinDiscountText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 4vh;
  font-size: 12px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

.bsLiveRecipeTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bsLiveRecipeTextInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 100vh;
  padding: 5px 10px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveRecipeTextInner {
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
  }
}

.bsLiveRecipeTextRow {
  display: flex;
  align-items: center; /* Align items horizontally */
  margin-bottom: 15px; /* Spacing between rows */
  width: 100%;
  position: relative; /* Needed for absolute positioning of the line */
  /* Dotted vertical line pseudo-element */
}
.bsLiveRecipeTextRow::before {
  content: "";
  position: absolute;
  top: -17px; /* Start slightly above the row */
  left: 4px; /* Center align the dots */
  width: 2px; /* Adjust thickness of the line */
  height: calc(25% + 15px); /* Extend the line below the row */
  background: repeating-linear-gradient(to bottom, lightgrey 0, lightgrey 2px, transparent 4px); /* Creates the dotted effect with round dots */
  border-radius: 50%; /* Make the dots round */
  z-index: -1; /* Place behind the content */
}
.bsLiveRecipeTextRow:first-of-type::before {
  content: none; /* Remove dots above the first row */
}
.bsLiveRecipeTextRow .big-dot {
  width: 10px;
  height: 10px;
  background: rgb(223, 212, 212); /* Dot color */
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  margin-right: 12px;
  position: relative; /* Keep relative for hover effect */
  z-index: 1; /* Ensure dot appears above the dotted line */
}
.bsLiveRecipeTextRow .big-dot:hover {
  transform: scale(1.2); /* Dot grows on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
}
.bsLiveRecipeTextRow .content {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.6; /* Improves readability */
  word-wrap: break-word; /* Ensure long words wrap */
  flex: 1; /* Allow text to take the remaining width */
  /* Blur effect for specific rows */
}
.bsLiveRecipeTextRow .content.blurred {
  filter: blur(4px); /* Apply blur effect */
  color: rgba(0, 0, 0, 0.5); /* Slightly dim the color */
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveRecipeTextRow .content {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveRecipeTextRow .content {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveRecipeTextRow .content {
    font-size: 20px;
  }
}

/* Snowfall container */
.bsLivesnowfall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  overflow: hidden;
  pointer-events: none; /* Allow clicks through the snowfall */
  z-index: 10; /* Ensure snowfall appears on top */
}

/* Snowflake styling */
.bsLivesnowflake {
  position: absolute;
  top: -10px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  animation: bsLivesnowfall 5s linear infinite;
  opacity: 0.8;
}

/* Snowflake falling animation */
@keyframes bsLivesnowfall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}
.bsLiveCallbackArea {
  position: absolute;
  top: 20%;
  left: 1px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35vh;
  background-color: white;
  border-radius: 40px;
  z-index: 20;
  animation: expand 1s ease-out forwards;
  margin-top: 1vh;
}

.bsLiveSearchButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 27px;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  margin-left: 3px;
  background-color: rgb(225, 176, 68);
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  cursor: pointer;
}
.bsLiveSearchButton:hover {
  background-color: rgb(225, 176, 68);
}
.bsLiveSearchButton:active {
  background-color: #5e97e0;
}

.bsLiveSearchInput {
  width: 100%;
  padding: 6px 40px 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
}
.bsLiveSearchInput:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.bsLiveSearchIcon {
  color: white;
  width: 20px;
  height: 20px;
}

.bsLiveUserReceiptRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 4vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  margin-top: 3px;
}

.bsLiveUserReceipeText {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 14px;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserReceipeText {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserReceipeText {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserReceipeText {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserReceipeText {
    font-size: 20px;
  }
}

.bsLiveReceiptIcon {
  width: 18px;
  height: 18px;
  fill: rgb(225, 176, 68);
  stroke: green;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveReceiptIcon {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveReceiptIcon {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveReceiptIcon {
    width: 28px;
    height: 28px;
  }
}

.bsLiveUserReceipt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 65vh;
  max-height: 65vh;
  overflow-y: scroll;
}
.bsLiveUserReceipt::-webkit-scrollbar {
  display: none;
}

.bsLiveRecipeLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 4vh;
}

.bsLiveUserNoData {
  text-align: center;
  color: #888;
  margin-top: 20px;
  font-style: italic;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.bsLivecloseShareIcon {
  width: 30px;
  height: 30px;
  fill: rgb(225, 176, 68);
  stroke: white;
  cursor: pointer;
}
.bsLivecloseShareIcon:hover {
  color: #ff0000;
}

.bsLiveTotalAmountFooterWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 5vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTotalAmountFooterWrapper {
    width: 91%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .bsLiveTotalAmountFooterWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTotalAmountFooterWrapper {
    width: 100%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTotalAmountFooterWrapper {
    width: 100%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTotalAmountFooterWrapper {
    width: 100%;
  }
}

.bsLiveTotalAmountFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.5vh;
  margin-top: 0.5vh;
  margin-bottom: 0.1vh;
  background-color: rgb(199, 202, 106);
  border-radius: 4px;
}

.bsLiveTotalAmountFooterText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 41vw;
  height: 3vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTotalAmountFooterText {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTotalAmountFooterText {
    font-size: 18px;
    width: 30vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTotalAmountFooterText {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTotalAmountFooterText {
    font-size: 18px;
  }
}

.bsLiveTotalAmountFooterTotalPrice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(15vw - 2px);
  margin-left: 2px;
  height: 3vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTotalAmountFooterTotalPrice {
    font-size: 18px;
    width: 25vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTotalAmountFooterTotalPrice {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTotalAmountFooterTotalPrice {
    font-size: 18px;
  }
}

.bsLiveTotalAmountFooterPrice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(12vw - 2px);
  margin-left: 2px;
  height: 3vh;
  font-size: 10px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  border-radius: 4px;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTotalAmountFooterPrice {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTotalAmountFooterPrice {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTotalAmountFooterPrice {
    font-size: 16px;
    width: 10vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTotalAmountFooterPrice {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTotalAmountFooterPrice {
    font-size: 18px;
  }
}

.bsLiveTotalAmountFooterQuantity {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(15vw - 2px);
  margin-left: 2px;
  height: 3vh;
  font-size: 10px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  border-radius: 4px;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTotalAmountFooterQuantity {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTotalAmountFooterQuantity {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTotalAmountFooterQuantity {
    font-size: 16px;
    width: 15vw;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTotalAmountFooterQuantity {
    font-size: 18px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTotalAmountFooterQuantity {
    font-size: 18px;
  }
}

.bsLiveTotalAmountFooterKalories {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(13vw - 2px);
  margin-left: 2px;
  height: 3vh;
  font-size: 10px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTotalAmountFooterKalories {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTotalAmountFooterKalories {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTotalAmountFooterKalories {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTotalAmountFooterKalories {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTotalAmountFooterKalories {
    font-size: 18px;
  }
}

.bsLiveNutrientChartArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 350px;
  right: 10px;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  color: #fff; /* White text for contrast */
  border-radius: 5px; /* Optional rounded corners */
  font-size: 14px; /* Adjust font size as needed */
  width: 70vw; /* Adjust width as needed */
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
  height: 15vh;
  zoom: 0.7;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveNutrientChartArea {
    top: 350px;
    right: 10px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveNutrientChartArea {
    top: 468px;
    right: 15px;
  }
}

.bsLiveNutrientChartTopNutrients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 15vh;
  white-space: nowrap;
  background-color: white;
}

.bsLiveNutrientTopNutrient {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.bsLiveNutrientChartTopNutrientName {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 2vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  margin-left: 2vw;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveNutrientChartTopNutrientName {
    font-size: 12px;
    margin-left: 1vw;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveNutrientChartTopNutrientName {
    font-size: 16px;
    margin-left: 2vw;
  }
}

.bsLiveCloseNutrientChart {
  width: 20px;
  height: 20px;
  stroke: black;
  color: black;
}

.bsLiveIngredientChangeNameWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 99%;
  margin-top: 3vh;
  height: 4vh;
}

.bsLiveIngredientChangeName {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  white-space: nowrap;
  cursor: pointer;
}

.bsLiveIngredientChangeInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 3.7vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
}

.bsLiveUserSavedRecipesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bsLiveUserSavedRecipeRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: auto;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  margin-top: 3px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserSavedRecipeRow {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserSavedRecipeRow {
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserSavedRecipeRow {
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserSavedRecipeRow {
    width: 60%;
  }
}

.bsLiveUserLocallySavedRecipeText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 3vh;
  font-size: 12px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}

.bsLiveUserLocallySavedRecipeTextId {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 3vh;
  font-size: 12px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bsLiveDeleteIngredientButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  margin-left: 1px;
  background-color: rgb(229, 64, 68);
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  cursor: pointer;
}
.bsLiveDeleteIngredientButton:hover {
  background-color: red;
}
.bsLiveDeleteIngredientButton:active {
  background-color: #5e97e0;
}

.bsLiveUserLocallySavedDeleteButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 27px;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  margin-left: 1px;
  background-color: red;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  cursor: pointer;
}
.bsLiveUserLocallySavedDeleteButton:hover {
  background-color: red;
}
.bsLiveUserLocallySavedDeleteButton:active {
  background-color: #5e97e0;
}

.bsLiveIngredInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease, opacity 1.5s ease;
  padding: 0 1px;
  opacity: 0;
  width: 100%;
}

.bsLiveIngredInfo.expanded {
  max-height: 180px; /* Adjust based on your content height */
  opacity: 1;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveIngredInfo.expanded {
    max-height: 180px;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveIngredInfo.expanded {
    max-height: 120px;
  }
}

.bsLiveIngredInfo.collapsed {
  max-height: 0;
  opacity: 0;
}

.bsLiveAddIngredientWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 20vh;
  white-space: nowrap;
  background-color: white;
  margin-top: 0.5vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveAddIngredientWrapper {
    height: 20vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveAddIngredientWrapper {
    height: auto;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveAddIngredientWrapper {
    width: 91%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1100px) {
  .bsLiveAddIngredientWrapper {
    width: 100%;
  }
}

.bsLiveAddIngredientBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 94%;
  height: 15vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-top: 0.5vh;
}

.bsLiveAddIngredientInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: 3.7vh;
  font-size: 12;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-top: 1vh;
}

.bsLiveAddIngredientButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 66%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: rgb(229, 64, 68);
  box-shadow: 4px 4px 4px 1px #e6dfcf;
  cursor: pointer;
}
.bsLiveAddIngredientButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 18px;
}

.bsLiveSignupCloseIconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 4vh;
  margin-top: 0.1vh;
  margin-bottom: 1vh;
  z-index: 1;
}

.bsLiveCloseSignupIcon {
  width: 20px;
  height: 20px;
  fill: white;
  cursor: pointer;
  z-index: 1;
  padding: 10px;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveCloseSignupIcon {
    width: 30px;
    height: 30px;
    margin: 1vw;
  }
}

.bsLiveSignupDefaultWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
}

.bsLiveSignupDefaultRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}

.bsLiveTabContainerSignWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTabContainerSignWrapper {
    background: unset;
    background: transparent;
    position: fixed;
    top: 12%;
    left: 0%;
    z-index: 9999;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTabContainerSignWrapper {
    background: unset;
    background: transparent;
    position: fixed;
    top: 12%;
    left: 0%;
    z-index: 9999;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTabContainerSignWrapper {
    background: unset;
    background: transparent;
    position: fixed;
    top: 12%;
    left: 0%;
    z-index: 9999;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTabContainerSignWrapper {
    background: unset;
    background: transparent;
    position: fixed;
    top: 12%;
    left: 0%;
    z-index: 9999;
  }
}

.bsLiveTabContainerSign {
  background-image: url("../assets/images/busychefsmall.jpg");
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  position: absolute;
  top: 9%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 92vh;
  margin: auto;
  font-family: Arial, sans-serif;
  background-color: white;
  margin-top: 0.5vh;
  z-index: 20;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTabContainerSign {
    width: 45%;
    box-shadow: 2px 2px 4px 1px #e6dfcf;
    background-color: white;
    position: unset;
    height: 75vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTabContainerSign {
    width: 40%;
    box-shadow: 4px 4px 8px 2px #918e89;
    background-color: white;
    position: unset;
    height: 70vh;
    border-radius: 8px;
    border: 1px solid rgb(223, 212, 212);
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTabContainerSign {
    width: 35%;
    box-shadow: 4px 4px 8px 2px #918e89;
    background-color: white;
    position: unset;
    height: 70vh;
    border-radius: 8px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTabContainerSign {
    width: 30%;
    box-shadow: 4px 4px 8px 2px #918e89;
    background-color: white;
    position: unset;
    height: 70vh;
    border-radius: 8px;
  }
}

.bsLiveTabContentSign {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  width: 100%;
}

.bsliveTabSign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 7vh;
  cursor: pointer;
  text-align: center;
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .bsliveTabSign {
    height: 10.5vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 371px) {
  .bsliveTabSign {
    height: 7.5vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsliveTabSign {
    height: 10vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1025px) {
  .bsliveTabSign {
    height: 11.5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsliveTabSign {
    height: 10vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsliveTabSign {
    height: 10vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsliveTabSign {
    height: 10vh;
  }
}

.bsliveSignHeader {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: white;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsliveSignHeader {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsliveSignHeader {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsliveSignHeader {
    font-size: 17px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsliveSignHeader {
    font-size: 20px;
  }
}
@media only screen and (min-width:2303px) {
  .bsliveSignHeader {
    font-size: 20px;
  }
}

.bsliveTabSign .bsliveActiveSign {
  border-bottom: none;
  font-weight: 400;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  z-index: 2; /* Bring active tab to the front */
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsliveTabSign .bsliveActiveSign {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsliveTabSign .bsliveActiveSign {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 24px;
    font-weight: 500;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsliveTabSign .bsliveActiveSign {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 26px;
    font-weight: 400;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsliveTabSign .bsliveActiveSign {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 28px;
    font-weight: 400;
  }
}
@media only screen and (min-width:2303px) {
  .bsliveTabSign .bsliveActiveSign {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 30px;
    font-weight: 400;
  }
}

.bsLiveSignupDefaultWrapper2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1vh;
}

.bsLiveSignupDefaultRowSign {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6vh;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: white;
  font-weight: bold;
  margin-bottom: 1vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveSignupDefaultRowSign {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveSignupDefaultRowSign {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveSignupDefaultRowSign {
    font-size: 16px;
  }
}

.bsLivePaymentMessageSign {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

@keyframes flipIn {
  0% {
    transform: rotateY(90deg);
    opacity: 0;
  }
  50% {
    transform: rotateY(-10deg);
    opacity: 0.5;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
.bsLivePaymentContactSign {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 32px;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLivePaymentContactSign {
    height: 40px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLivePaymentContactSign {
    height: 40px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLivePaymentContactSign {
    height: 45px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLivePaymentContactSign {
    height: 40px;
  }
}

.bsLivePaymentSelectCurrencySign {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 30px;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  background-color: white;
  border: 1px solid rgb(223, 212, 212);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: black;
}

.bsLiveTranslateMenuWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTranslateMenuWrapper {
    height: 5vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTranslateMenuWrapper {
    height: 5vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTranslateMenuWrapper {
    height: 5vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTranslateMenuWrapper {
    height: 5vh;
  }
}

.bsLiveTranslateMenuText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6vh;
  font-size: 14px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  margin-top: 0.5vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTranslateMenuText {
    font-size: 16px;
    height: 2vh;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTranslateMenuText {
    font-size: 18px;
    height: 2vh;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTranslateMenuText {
    font-size: 16px;
    height: 2vh;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTranslateMenuText {
    font-size: 20px;
    height: 2vh;
  }
}

.bsLiveTranslationInfoText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 11px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  margin-top: 0.5vh;
}

.bsLiveTranslateSelect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 4vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  background-color: white;
  margin-top: 0.5vh;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTranslateSelect {
    height: 3vh;
    width: 20%;
    margin-top: unset;
    margin-right: 3px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTranslateSelect {
    height: 3vh;
    width: 20%;
    margin-top: unset;
    margin-right: 3px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTranslateSelect {
    height: 3vh;
    width: 20%;
    margin-top: unset;
    margin-right: 3px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTranslateSelect {
    height: 3vh;
    width: 20%;
    margin-top: unset;
    margin-right: 3px;
  }
}

.bsLiveTranslateButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 4vh;
  border-radius: 4px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 1vh;
  color: white;
  background-color: #2c79df;
  cursor: pointer;
}
.bsLiveTranslateButton:hover {
  background-color: #5e97e0;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTranslateButton {
    height: 3vh;
    width: 20%;
    margin-top: unset;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTranslateButton {
    height: 3vh;
    width: 20%;
    margin-top: unset;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTranslateButton {
    height: 3vh;
    width: 20%;
    margin-top: unset;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTranslateButton {
    height: 3vh;
    width: 20%;
    margin-top: unset;
  }
}

.bsLiveTranslateArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: white;
  border-radius: 8px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveTranslateArea {
    height: 5vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveTranslateArea {
    height: 5vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveTranslateArea {
    height: 5vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveTranslateArea {
    height: 5vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.bsLiveTranslateIcon {
  width: 30px;
  height: 30px;
  fill: rgb(225, 176, 68);
  stroke: white;
}

.bsLiveLanguageSelectorWrapper {
  position: absolute;
  top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1900;
}

.bsLiveTermsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3vh;
  background: transparent;
}

.bsLiveUserAccountChangeLangWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 15vh;
  margin-top: 3vh;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserAccountChangeLangWrapper {
    width: 80%;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserAccountChangeLangWrapper {
    width: 80%;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserAccountChangeLangWrapper {
    width: 80%;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserAccountChangeLangWrapper {
    width: 60%;
  }
}

.bsLiveUserAccountChangeLangHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4vh;
  font-size: 14px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
}

.bsLiveUserAccountChangeLangSelect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 4vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  border: 1px solid rgb(223, 212, 212);
  border-radius: 4px;
  background-color: white;
  margin-top: 0.5vh;
}
@media only screen and (min-width:2303px) {
  .bsLiveUserAccountChangeLangSelect {
    height: 3vh;
  }
}

.bsLiveUserAccountChangeLangSubmitButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 69.5%;
  height: 3vh;
  border-radius: 4px;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-top: 0.5vh;
  color: white;
  background-color: #2c79df;
}
.bsLiveUserAccountChangeLangSubmitButton:hover {
  background-color: #5e97e0;
  color: white;
  border: 2px solid #5e97e0;
  font-size: 14px;
}
.bsLiveUserAccountChangeLangSubmitButton.disabled {
  background-color: #839ec0;
  color: white;
  border: 2px solid #839ec0;
  font-size: 12px;
  pointer-events: none; /* Optional: Disable interactions */
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveUserAccountChangeLangSubmitButton.disabled {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveUserAccountChangeLangSubmitButton.disabled {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveUserAccountChangeLangSubmitButton.disabled {
    font-size: 16px;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveUserAccountChangeLangSubmitButton.disabled {
    font-size: 18px;
  }
}

.bsLiveMenuWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.bsLiveMenuItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 60vh;
  font-size: 14px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  margin-top: 0.5vh;
}

.bsLiveMenuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 6vh;
  font-size: 14px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  margin-top: 0.5vh;
}

.bsLIveMenuIcon {
  width: 34px;
  height: 34px;
  fill: rgb(225, 176, 68);
  stroke: black;
  cursor: pointer;
  margin-right: 1vw;
}
@media only screen and (min-width:2303px) {
  .bsLIveMenuIcon {
    width: 40px;
    height: 40px;
  }
}

.bsLiveChristmasTree {
  position: absolute;
  top: 2%;
  right: 2%;
  align-items: center;
  justify-content: center;
  min-height: 7vh;
  background-color: transparent;
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveChristmasTree {
    top: 2%;
    right: 2%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) {
  .bsLiveChristmasTree {
    top: 1.5%;
    right: 2%;
  }
}

.bsLiveCoockingTimerWrapper {
  position: fixed; /* Or absolute if relative to a specific parent */
  top: 50%; /* Vertically center */
  left: 50%; /* Horizontally center */
  transform: translate(-50%, -50%); /* Adjust to truly center the component */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other elements */
  background-color: rgba(255, 255, 255, 0.9); /* Optional: Add a semi-transparent background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow for emphasis */
  border-radius: 8px; /* Optional: Add rounded corners */
}

.bsLiveUserAccountBSignupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
}

.bsLiveSignupButton {
  width: 70%;
  background-color: #ff9800;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 8px 8px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 2vh;
}
.bsLiveSignupButton:hover {
  background-color: #f57c00;
}

.bsLiveChartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 35vh;
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveChartWrapper {
    width: 100%;
    height: 40vh;
  }
}

.bsLiveToggleListButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 3vh;
  margin: 0;
  padding: 0;
}

.bsLiveToggleIngredListIcon {
  font-size: 30px;
  fill: white;
  stroke: rgb(199, 202, 106);
  cursor: pointer;
}

.bsLiveCoockieWarningWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 2px 4px 1px #e6dfcf;
  margin-top: 0.5vh;
}

.bsLiveTabInputContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 39vh;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveTabInputContentWrapper {
    height: 30vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (max-width: 370px) {
  .bsLiveTabInputContentWrapper {
    height: 50vh;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveTabInputContentWrapper {
    height: 45vh;
  }
}

.bsLiveLoginForgotPasswordWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.bsLiveLoginForgotPasswordText {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 2vh;
  font-size: 12px;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: black;
  cursor: pointer;
  margin-right: 1vw;
  margin-top: 5px;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bsLiveLoginForgotPasswordText {
    margin-right: 1.7vw;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bsLiveLoginForgotPasswordText {
    margin-right: 4.5vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bsLiveLoginForgotPasswordText {
    margin-right: 0.3vw;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bsLiveLoginForgotPasswordText {
    margin-right: 3.4vw;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bsLiveLoginForgotPasswordText {
    margin-right: 3.4vw;
  }
}
@media only screen and (min-width:2303px) {
  .bsLiveLoginForgotPasswordText {
    margin-right: 3.1vw;
  }
}/*# sourceMappingURL=default.css.map */