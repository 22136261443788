:root {
  --rgbaVal:white;
  --rgbaVal2:white;
  --getColorVal:white;
  --val:white;
  --windowHeight: 92vh;
}

.backgroundImageRecepyNew {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  transform: scale(1);
}

.bslivechristmas-tree-icon {
  position: relative;
  display: inline-block;
  font-size: 16px; /* Base size, can be overridden */
}

.bslivetree {
  color: #15803d;
  width: 2.8rem;
  height: 2.9rem;
}
@media only screen and (min-width:359px) and (max-width: 599px) {
  .bslivetree {
    width: 2.8rem;
    height: 2.9rem;
  }
}
@media only screen and (min-width:359px) and (max-width: 599px) and (min-width: 400px) {
  .bslivetree {
    width: 3.5rem;
    height: 3.6rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) {
  .bslivetree {
    width: 3rem;
    height: 3rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (max-width: 1024px) {
  .bslivetree {
    width: 3.5rem;
    height: 3.5rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1024px) and (max-width: 1181px) {
  .bslivetree {
    width: 3rem;
    height: 3rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1181px) and (max-width: 1200px) {
  .bslivetree {
    width: 4.4rem;
    height: 4.4rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1438px) and (min-width: 1201px) and (max-width: 1400px) {
  .bslivetree {
    width: 3.6rem;
    height: 3.6rem;
  }
}
@media only screen and (min-width: 1439px) and (max-width: 1911px) {
  .bslivetree {
    width: 3.8rem;
    height: 3.8rem;
  }
}
@media only screen and (min-width: 1912px) and (max-width:2302px) {
  .bslivetree {
    width: 4.5rem;
    height: 4.5rem;
  }
}
@media only screen and (min-width:2303px) {
  .bslivetree {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.bslivelight {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  animation: twinkle 1.5s ease-in-out infinite;
}
.bslivelight.red {
  background-color: #ef4444;
  top: 0.25rem;
  left: 50%;
}
.bslivelight.yellow {
  background-color: #facc15;
  top: 0.75rem;
  left: 25%;
}
.bslivelight.blue {
  background-color: #3b82f6;
  top: 1.25rem;
  right: 25%;
}
.bslivelight.purple {
  background-color: #a855f7;
  top: 1.75rem;
  left: 33%;
}
.bslivelight.orange {
  background-color: #f97316;
  bottom: 0.75rem;
  right: 33%;
}

.bslivestar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #facc15;
  font-size: 1rem;
}

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}/*# sourceMappingURL=ChristmasTree.css.map */