@import '../style/MainStyles.scss';

.language-selector-wrapper {

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // min-height: 100vh;
    // background-color: transparent;
    display: flex;
    justify-content: center;  // Center horizontally
    align-items: center;      // Center vertically
    min-height: 100vh;        // Full viewport height
    background-color: transparent;
    width: 100%;   


  }
  
  .language-selector {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width:80%;
    max-width: 400px;
    border:1px solid map-get($generalStyles,datalistBorderColor);
    z-index:1001;
    &__title {
        font-family: map-get($generalStyles,fontFamilyLobster);
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1rem;
    }
  
    &__description {
      text-align: center;
      color: #6b7280;
      margin-bottom: 1.5rem;
    }
  
    &__select {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #d1d5db;
      border-radius: 4px;
      font-size: 1rem;
      margin-bottom: 1rem;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 0.5rem center;
      background-size: 1.5em 1.5em;
  
      &:focus {
        outline: none;
        border-color: #3b82f6;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
      }
    }
  
    &__button {
      width: 100%;
      padding: 0.5rem 1rem;
      background-color: #3b82f6;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease;
  
      &:hover:not(:disabled) {
        background-color: #2563eb;
      }
  
      &:disabled {
        background-color: #9ca3af;
        cursor: not-allowed;
      }
    }
  }
  
  
  